export enum SearchTypes {
  REGEX = 'REGEX',
  VALUE_EQUAL = 'VALUE_EQUAL',
  VALUE_NOT_EQUAL_TO = 'VALUE_NOT_EQUAL_TO',
  VALUE_GREATER_THAN = 'VALUE_GREATER_THAN',
  VALUE_GREATER_THAN_EQUAL_TO = 'VALUE_GREATER_THAN_EQUAL_TO',
  VALUE_LESS_THAN = 'VALUE_LESS_THAN',
  VALUE_LESS_THAN_EQUAL_TO = 'VALUE_LESS_THAN_EQUAL_TO',
  VALUE_IN_ARRAY = 'VALUE_IN_ARRAY',
  VALUE_NOT_IN_ARRAY = 'VALUE_NOT_IN_ARRAY',
  FIELD_EXISTS = 'FIELD_EXISTS',
}
