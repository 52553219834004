import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import moment from "moment/moment";
import {Flex, Heading} from "@chakra-ui/react";
import {ColumnDataType, Table} from "../../GenericCharts/Table";
import * as React from "react";
import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {getIconFunctionForTable} from "../../utilities/IconFunction";
import {computeFinalScore} from "../../utilities/ProcessData";
import {getLastPhaseForIndicatorEntry} from "../../../../helpers/ApplicationHelper";
import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import {getPerformanceColour} from "../../ReportsHelper";

export const TableAllIndicatorsYearlyAchievements = (
    props: {
        chartName : string,
        indicatorDataEntries : Map<IndicatorResponse, IndicatorDataEntryResponse[]>,
        reportDuration : moment.Moment,
        onChartImageURI: any,
    }
): JSX.Element => {

    const {
        chartName,
        indicatorDataEntries,
        reportDuration,
        onChartImageURI
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const chooseIndicatorEntryInYearEntries = (indicatorDataEntryResponsesForYear : IndicatorDataEntryResponse[]) : IndicatorDataEntryResponse => {
        // get only the latest indicator entry
        return indicatorDataEntryResponsesForYear
            .filter(indicatorDataEntry => getLastPhaseForIndicatorEntry(indicatorDataEntry) !== undefined)
            .sort(
                (entry1, entry2) =>
                    moment(entry1.recordingDate).isBefore(moment(entry2.recordingDate)) ? 1 : -1
            )[0];
    }

    const allYears : Set<number> = new Set<number>();

    const getAllLatestIndicatorEntriesForEachYear =
        (indicatorDataEntryResponses : IndicatorDataEntryResponse[]) : Map<number, IndicatorDataEntryResponse> => {
        const indicatorResponsesYearWise : Map<number, IndicatorDataEntryResponse[]> =
            indicatorDataEntryResponses
                .reduce((prev, curr) => {
                const indicatorEntryYear : number = moment(curr.recordingDate).year();
                allYears.add(indicatorEntryYear);
                if(prev.has(indicatorEntryYear)) {
                    prev.get(indicatorEntryYear).push(curr)
                }
                else {
                    prev.set(indicatorEntryYear, [curr])
                }
                return prev;
            }, new Map())

        return Array.from(indicatorResponsesYearWise.entries()).reduce(
            (prev, [indicatorEntryYear, indicatorResponses]) =>
                prev.set(indicatorEntryYear, chooseIndicatorEntryInYearEntries(indicatorResponses)),
            new Map<number, IndicatorDataEntryResponse>()
        )
    }

    const indicatorEntryDataWithOnlyLatestValuesInYear : Map<IndicatorResponse, Map<number, IndicatorDataEntryResponse>> =
        Array.from(indicatorDataEntries.entries()).reduce(
            (prev, [indicatorResponse, indicatorDataEntries]) =>
                prev.set(indicatorResponse, getAllLatestIndicatorEntriesForEachYear(indicatorDataEntries))
            , new Map<IndicatorResponse, Map<number, IndicatorDataEntryResponse>>());

    const tableData : ColumnDataType[] = Array.from(allYears).sort()
        .map(year => ({
            header: `${t('Achievement')} - ${year}`,
            singleValues: undefined,
            stars: Array.from(indicatorEntryDataWithOnlyLatestValuesInYear.entries()).map(
                ([indicatorResponse, latestIndicatorEntriesForEachYear]) => {
                    const latestIndicatorEntryForYear : IndicatorDataEntryResponse | undefined =
                        latestIndicatorEntriesForEachYear.get(year);
                    const lastPhaseData = latestIndicatorEntryForYear ? getLastPhaseForIndicatorEntry(latestIndicatorEntryForYear) : null;
                    return lastPhaseData ? parseInt(lastPhaseData['reliability'].value || '0') : 0
                }
            ),
            values: Array.from(indicatorEntryDataWithOnlyLatestValuesInYear.entries()).map(
                ([indicatorResponse, latestIndicatorEntriesForEachYear]) => {
                    const latestIndicatorEntryForYear : IndicatorDataEntryResponse | undefined =
                        latestIndicatorEntriesForEachYear.get(year);
                    const lastPhaseData = latestIndicatorEntryForYear ? getLastPhaseForIndicatorEntry(latestIndicatorEntryForYear) : null;

                    return lastPhaseData
                        ? computeFinalScore(lastPhaseData, indicatorResponse, IndicatorDataType.AURA_REPORTING).score
                        : null;
                }
            ),
            notApplicable : Array.from(indicatorEntryDataWithOnlyLatestValuesInYear.entries()).map(
                ([indicatorResponse, latestIndicatorEntriesForEachYear]) => null
            ),
            notReported : Array.from(indicatorEntryDataWithOnlyLatestValuesInYear.entries()).map(
                ([indicatorResponse, latestIndicatorEntriesForEachYear]) => null
            ),
            targets: Array.from(indicatorEntryDataWithOnlyLatestValuesInYear.entries()).map(
                ([indicatorResponse, latestIndicatorEntriesForEachYear]) => {
                    const latestIndicatorEntryForYear : IndicatorDataEntryResponse | undefined =
                        latestIndicatorEntriesForEachYear.get(year);

                    const lastPhaseData = latestIndicatorEntryForYear ? getLastPhaseForIndicatorEntry(latestIndicatorEntryForYear) : null;

                    return lastPhaseData
                        ? parseInt(lastPhaseData['target'].value || '100')
                        : 100;
                }
            ),
            coloringFunctions: Array.from(indicatorEntryDataWithOnlyLatestValuesInYear.keys()).map(
                (indicatorResponse) =>
                    (performanceScore : number) =>
                        getPerformanceColour(performanceScore, indicatorResponse.indicatorNumber)
            ),
            iconFunction: getIconFunctionForTable()
        }))

    const getTableColumnsFromSeriesData = (): ColumnDataType[] => {
        return [
            {
                header: t('Indicators'),
                singleValues: Array.from(indicatorDataEntries.keys()).map(indicator => indicator.name[selectedLanguage]),
                stars: [],
                values: [],
                notApplicable : [],
                notReported : [],
                targets: [],
                coloringFunctions: [],
                iconFunction: null
            },
            ...tableData
        ];
    }

    return (
        <>
            <Flex flexDirection={"column"}>
                <Heading size={'md'} mb={5}>{chartName}</Heading>
                <Table
                    name={chartName}
                    tableId={chartName}
                    columns={getTableColumnsFromSeriesData()}
                    noOfRows={indicatorDataEntries.size}
                />
            </Flex>
        </>
    )
}
