import { Box, Heading, Stack, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../i18n/SelectedLanguage";
import {IndicatorResponse} from "../entities/response/indicator/IndicatorResponse";

export const IndicatorCard = (
    props: {
        indicator: IndicatorResponse
        onClick: (indicator: IndicatorResponse) => void
    }
) => {

    const {i18n,t} = useTranslation();
    const selectedLanguage = i18n.language as SelectedLanguage;

    const { indicator, onClick, ...boxProps } = props

    return (
        <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg="bg-surface"
            cursor={"pointer"}
            borderRadius="lg"
            backgroundColor={'white'}
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            onClick={() => onClick(indicator)}
            {...boxProps}
        >
            <Stack>
                <Text fontSize="sm" color="muted">
                    {
                        (
                            indicator?.description && indicator?.description[selectedLanguage]
                        )
                            ? indicator?.description[selectedLanguage]
                                : ''
                    }
                </Text>
                <Heading size={useBreakpointValue({ base: 'sm', md: 'md' })}>
                    {
                        indicator.name[selectedLanguage]
                    }
                </Heading>
            </Stack>
        </Box>
    )
}
