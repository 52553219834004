import {baseApiUrl, executeRequest, RequestMethod} from "./BaseService";
import {GlobalUserGroupResponse} from "../entities/response/globalUserGroup/GlobalUserGroupResponse";
import {GlobalUserGroupCreateRequest} from "../entities/request/globalUserGroup/GlobalUserGroupCreateRequest";

const baseGlobalUserAuthUrl = baseApiUrl + '/globalUserGroups';

export const getGlobalUserGroupById = async (globalUserGroupId : string) : Promise<GlobalUserGroupResponse> => {
    try {
        return await executeRequest(`${baseGlobalUserAuthUrl}/${globalUserGroupId}`, RequestMethod.GET, undefined, null) as GlobalUserGroupResponse;
    }
    catch (err) {
        throw err;
    }

}

export const getAllGlobalUserGroups = async () : Promise<GlobalUserGroupResponse[]> => {
    try {
        return await executeRequest(baseGlobalUserAuthUrl, RequestMethod.GET, undefined, null) as GlobalUserGroupResponse[];
    }
    catch (err) {
        throw err;
    }
}

export const checkGlobalUserGroupMembership = async (globalUserGroupName: string) : Promise<boolean> => {
    try {
        return await executeRequest(`${baseGlobalUserAuthUrl}/checkPartOfGlobalUserGroup`, RequestMethod.POST, { globalUserGroupName }, null) as boolean;
    }
    catch (err) {
        throw err;
    }
}

export const createGlobalUserGroup = async (globalUserGroupCreateRequest : GlobalUserGroupCreateRequest) : Promise<GlobalUserGroupResponse> => {
    try {
        const res = await executeRequest(baseGlobalUserAuthUrl, RequestMethod.POST, globalUserGroupCreateRequest, null);

        return Promise.resolve(res as GlobalUserGroupResponse);
    }
    catch (err) {
        throw err;
    }
}

export const addGlobalUserToGlobalUserGroup = async (globalUserId : string, globalUserGroupId : string) : Promise<GlobalUserGroupResponse> => {
    try {
        return await executeRequest(`${baseGlobalUserAuthUrl}/addGlobalUser`, RequestMethod.POST, {
            globalUserId: globalUserId,
            globalUserGroupId : globalUserGroupId
        }, null) as GlobalUserGroupResponse;
    }
    catch (err) {
        throw err;
    }
}

export const removeGlobalUserFromGlobalUserGroup = async (globalUserId : string, globalUserGroupId : string) : Promise<GlobalUserGroupResponse> => {
    try {
        return await executeRequest(`${baseGlobalUserAuthUrl}/removeGlobalUser`, RequestMethod.POST, {
            globalUserId: globalUserId,
            globalUserGroupId : globalUserGroupId
        }, null) as GlobalUserGroupResponse;
    }
    catch (err) {
        throw err;
    }
}

