export enum IndicatorLevelActions {

    VIEW_DATA_AURA_COMPLEMENTARY = 'VIEW_DATA_AURA_COMPLEMENTARY',
    VIEW_REPORTS_AURA_COMPLEMENTARY = 'VIEW_REPORTS_AURA_COMPLEMENTARY',

    RECORD_PHASE1_AURA_COMPLEMENTARY = 'RECORD_PHASE1_AURA_COMPLEMENTARY',
    RECORD_PHASE2_AURA_COMPLEMENTARY = 'RECORD_PHASE2_AURA_COMPLEMENTARY',
    RECORD_PHASE3_AURA_COMPLEMENTARY = 'RECORD_PHASE3_AURA_COMPLEMENTARY',
    RECORD_PHASE4_AURA_COMPLEMENTARY = 'RECORD_PHASE4_AURA_COMPLEMENTARY',
    RECORD_PHASE5_AURA_COMPLEMENTARY = 'RECORD_PHASE5_AURA_COMPLEMENTARY',
    RECORD_PHASE6_AURA_COMPLEMENTARY = 'RECORD_PHASE6_AURA_COMPLEMENTARY',

    SEND_PHASE1_AURA_COMPLEMENTARY = 'SEND_PHASE1_AURA_COMPLEMENTARY',
    SEND_PHASE2_AURA_COMPLEMENTARY = 'SEND_PHASE2_AURA_COMPLEMENTARY',
    SEND_PHASE3_AURA_COMPLEMENTARY = 'SEND_PHASE3_AURA_COMPLEMENTARY',
    SEND_PHASE4_AURA_COMPLEMENTARY = 'SEND_PHASE4_AURA_COMPLEMENTARY',
    SEND_PHASE5_AURA_COMPLEMENTARY = 'SEND_PHASE5_AURA_COMPLEMENTARY',
    SEND_PHASE6_AURA_COMPLEMENTARY = 'SEND_PHASE6_AURA_COMPLEMENTARY',

    EDIT_PHASE1_AURA_COMPLEMENTARY = 'EDIT_PHASE1_AURA_COMPLEMENTARY',
    EDIT_PHASE2_AURA_COMPLEMENTARY = 'EDIT_PHASE2_AURA_COMPLEMENTARY',
    EDIT_PHASE3_AURA_COMPLEMENTARY = 'EDIT_PHASE3_AURA_COMPLEMENTARY',
    EDIT_PHASE4_AURA_COMPLEMENTARY = 'EDIT_PHASE4_AURA_COMPLEMENTARY',
    EDIT_PHASE5_AURA_COMPLEMENTARY = 'EDIT_PHASE5_AURA_COMPLEMENTARY',
    EDIT_PHASE6_AURA_COMPLEMENTARY = 'EDIT_PHASE6_AURA_COMPLEMENTARY',

    VIEW_DATA_AURA_REPORTING = 'VIEW_DATA_AURA_REPORTING',
    VIEW_REPORTS_AURA_REPORTING = 'VIEW_REPORTS_AURA_REPORTING',

    RECORD_PHASE1_AURA_REPORTING = 'RECORD_PHASE1_AURA_REPORTING',
    RECORD_PHASE2_AURA_REPORTING = 'RECORD_PHASE2_AURA_REPORTING',
    RECORD_PHASE3_AURA_REPORTING = 'RECORD_PHASE3_AURA_REPORTING',
    RECORD_PHASE4_AURA_REPORTING = 'RECORD_PHASE4_AURA_REPORTING',
    RECORD_PHASE5_AURA_REPORTING = 'RECORD_PHASE5_AURA_REPORTING',
    RECORD_PHASE6_AURA_REPORTING = 'RECORD_PHASE6_AURA_REPORTING',

    SEND_PHASE1_AURA_REPORTING = 'SEND_PHASE1_AURA_REPORTING',
    SEND_PHASE2_AURA_REPORTING = 'SEND_PHASE2_AURA_REPORTING',
    SEND_PHASE3_AURA_REPORTING = 'SEND_PHASE3_AURA_REPORTING',
    SEND_PHASE4_AURA_REPORTING = 'SEND_PHASE4_AURA_REPORTING',
    SEND_PHASE5_AURA_REPORTING = 'SEND_PHASE5_AURA_REPORTING',
    SEND_PHASE6_AURA_REPORTING = 'SEND_PHASE6_AURA_REPORTING',

    EDIT_PHASE1_AURA_REPORTING = 'EDIT_PHASE1_AURA_REPORTING',
    EDIT_PHASE2_AURA_REPORTING = 'EDIT_PHASE2_AURA_REPORTING',
    EDIT_PHASE3_AURA_REPORTING = 'EDIT_PHASE3_AURA_REPORTING',
    EDIT_PHASE4_AURA_REPORTING = 'EDIT_PHASE4_AURA_REPORTING',
    EDIT_PHASE5_AURA_REPORTING = 'EDIT_PHASE5_AURA_REPORTING',
    EDIT_PHASE6_AURA_REPORTING = 'EDIT_PHASE6_AURA_REPORTING',

    VIEW_DATA_DNAAS_USP = 'VIEW_DATA_DNAAS_USP',
    VIEW_REPORTS_DNAAS_USP = 'VIEW_REPORTS_DNAAS_USP',

    RECORD_PHASE1_DNAAS_USP = 'RECORD_PHASE1_DNAAS_USP',
    RECORD_PHASE2_DNAAS_USP = 'RECORD_PHASE2_DNAAS_USP',
    RECORD_PHASE3_DNAAS_USP = 'RECORD_PHASE3_DNAAS_USP',
    RECORD_PHASE4_DNAAS_USP = 'RECORD_PHASE4_DNAAS_USP',
    RECORD_PHASE5_DNAAS_USP = 'RECORD_PHASE5_DNAAS_USP',
    RECORD_PHASE6_DNAAS_USP = 'RECORD_PHASE6_DNAAS_USP',

    SEND_PHASE1_DNAAS_USP = 'SEND_PHASE1_DNAAS_USP',
    SEND_PHASE2_DNAAS_USP = 'SEND_PHASE2_DNAAS_USP',
    SEND_PHASE3_DNAAS_USP = 'SEND_PHASE3_DNAAS_USP',
    SEND_PHASE4_DNAAS_USP = 'SEND_PHASE4_DNAAS_USP',
    SEND_PHASE5_DNAAS_USP = 'SEND_PHASE5_DNAAS_USP',
    SEND_PHASE6_DNAAS_USP = 'SEND_PHASE6_DNAAS_USP',

    EDIT_PHASE1_DNAAS_USP = 'EDIT_PHASE1_DNAAS_USP',
    EDIT_PHASE2_DNAAS_USP = 'EDIT_PHASE2_DNAAS_USP',
    EDIT_PHASE3_DNAAS_USP = 'EDIT_PHASE3_DNAAS_USP',
    EDIT_PHASE4_DNAAS_USP = 'EDIT_PHASE4_DNAAS_USP',
    EDIT_PHASE5_DNAAS_USP = 'EDIT_PHASE5_DNAAS_USP',
    EDIT_PHASE6_DNAAS_USP = 'EDIT_PHASE6_DNAAS_USP',
}
