import {
    Box,
    Button,
    Flex,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    OrderedList,
    SimpleGrid, Spinner, Text,
    useDisclosure,
    useToast, VStack
} from "@chakra-ui/react";
import React from "react";
import {PopoverForm} from "./ManageUserGroupPopover";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {checkUserLoggedIn} from "../utilities/AuthUtil";
import {getAllLocalUsersInCompany} from "../services/LocalUserService";
import {LocalUserResponse} from "../entities/response/user/LocalUserResponse";
import {GlobalUserResponse} from "../entities/response/user/GlobalUserResponse";
import {getGlobalUserById} from "../services/GlobalUserService";
import {addLocalUserToUserGroup, getAllUserGroups} from "../services/UserGroupService";
import {UserGroupResponse} from "../entities/response/userGroup/UserGroupResponse";
import {UserGroupType} from "../enums/UserGroupType";
import {toastDuration} from "../constants/ApplicationConstants";
import {navigateToLoginPage} from "../helpers/NavigationHelper";

export interface ILocalGlobalUser {
    localUser: LocalUserResponse;
    globalUser: GlobalUserResponse;
}

const CustomOverlay = () => (
    <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px)'
    />
);

export const ManageUserGroups = (props: { isActive: boolean }) => {
    const { isActive } = props;
    const { t } = useTranslation();
    const [refresh, setRefresh] = React.useState<boolean>(true);
    const [userGroups, setUserGroups] = React.useState<UserGroupResponse[] | null>();
    const [userGroupSelected, selectUserGroup] = React.useState<UserGroupResponse>();
    const toast = useToast();
    const [users, setUsers] = React.useState<ILocalGlobalUser[]>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [userIdToBeAdded, setUserIdToBeAdded] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const navigate = useNavigate();
    React.useEffect(() => {
        if (!checkUserLoggedIn()) {
            navigateToLoginPage(navigate)
        }
    }, []);

    React.useEffect(() => {
        if (isActive && refresh) {

            getAllLocalUsersInCompany()
                .then(async (localUsers : LocalUserResponse[]) => {

                    const globalUserDetails : GlobalUserResponse[] =
                        await Promise.all(localUsers.map(localUser => getGlobalUserById(localUser.globalUserId)))

                    const userDetailsWithLocalUserId : ILocalGlobalUser[] = globalUserDetails.map((globalUser: GlobalUserResponse, idx: number) => {
                        return {
                            localUser: localUsers[idx],
                            globalUser: globalUser
                        }
                    });

                    setUsers(userDetailsWithLocalUserId);

                    setRefresh(false);

                })
                .catch(err => {
                    toast({
                        title: t('Failed to load users for the municipality'),
                        description: t(err?.message?.toString()),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    return;
                })
        }
    }, [refresh, isActive]);

    React.useEffect(() => {
        if (users) {
            setUserIdToBeAdded(users[0].localUser._id);

            getAllUserGroups()
                .then((userGroupsResponse : UserGroupResponse[]) => {
                    const userGroups = userGroupsResponse.filter(userGroup => userGroup.userGroupType === UserGroupType.USER_DEFINED);
                    setUserGroups(userGroups);
                    selectUserGroup(userGroups[0]);
                })
                .catch(err => {
                    toast({
                        title: t('Failed to load user groups'),
                        description: t(err?.message || ''),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                })
        }
    }, [users]);

    const addUser = () => {
        if(userIdToBeAdded && userGroupSelected?._id) {
            setIsLoading(true)
            addLocalUserToUserGroup(userIdToBeAdded, userGroupSelected?._id)
                .then(userGroupResponse => {
                    toast({
                        title: t('Added User'),
                        description: `${t('Success')}!`,
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    onClose();
                    setRefresh(true);
                    setUserGroups(
                        userGroups?.map(userGroup => userGroup._id === userGroupResponse._id ? userGroupResponse : userGroup)
                    )
                    setIsLoading(false);
                })
                .catch(err => {
                    if((err?.message || '').includes('Local User already part of user group')) {
                        toast({
                            title: `${t('Failed to add user')} to ${t(userGroupSelected.name)}`,
                            description: t('User already part of user group'),
                            status: 'error',
                            duration: toastDuration,
                            isClosable: true,
                        });
                    }
                    else {
                        toast({
                            title: `${t('Failed to add user')} to ${t(userGroupSelected.name)}`,
                            description: t(err?.message || ''),
                            status: 'error',
                            duration: toastDuration,
                            isClosable: true,
                        });
                    }
                    onClose();
                    setRefresh(true);
                    setIsLoading(false);
                })
        }
    };

    const handleOptionChange = (event: { target: HTMLSelectElement }) => {
        setUserIdToBeAdded(event.target.value);
    };

    return (
        <>
            {
                userGroups ? (
                    <Box w={"100%"}>
                        <Flex w={"100%"} direction={'column'}>
                            <SimpleGrid w={"100%"}h={"90%"} columns={1} spacing={10}>
                                <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"} isCentered>
                                    <CustomOverlay />
                                    <ModalContent>
                                        <ModalHeader>{t("Add Company User")}</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody m={5}>
                                            <Flex flexDirection={'column'}>
                                                <select
                                                    onChange={handleOptionChange} name="emailAddresses" id="emailAddress">
                                                    {users?.map((user: ILocalGlobalUser, idx) => {
                                                        return (
                                                            <option value={user.localUser._id} key={'users-'+idx}>{user.globalUser.name} ({user.globalUser.email})</option>
                                                        );
                                                    })}
                                                </select>
                                                <Button
                                                    mt={8}
                                                    colorScheme={"gray"}
                                                    onClick={addUser}
                                                    isLoading={isLoading}
                                                    loadingText={t('Adding user')}
                                                >
                                                    {t("Add")}
                                                </Button>
                                            </Flex>
                                        </ModalBody>
                                    </ModalContent>
                                </Modal>
                                <Box m={4} h='100%'  width='100%'>
                                    <OrderedList width='100%'>
                                        {
                                            userGroups?.map((userGroup: UserGroupResponse, idx) => {
                                                return (
                                                    <ListItem mt={5} onClick={() => selectUserGroup(userGroup)} key={`user-group-${idx}`} style={{cursor: "pointer"}}>
                                                        <PopoverForm
                                                            setRefresh={setRefresh}
                                                            isGlobalUserGroup={userGroup.userGroupType === UserGroupType.GLOBAL_USER_GROUP }
                                                            onUserAddModalOpen={onOpen}
                                                            users={users}
                                                            name={userGroup.name}
                                                            userGroupSelected={userGroup}
                                                        />
                                                    </ListItem>
                                                );
                                            })
                                        }
                                    </OrderedList>
                                </Box>
                            </SimpleGrid>
                        </Flex>
                    </Box>
                ) : (
                    <VStack h={"80vh"} justify={'center'}>
                        <Spinner size='xl' />
                        <Text>{t("Loading user groups, please wait")}!</Text>
                    </VStack>
                )
            }
        </>
    );
}
