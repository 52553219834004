import {baseApiUrl, executeRequest, RequestMethod} from "./BaseService";
import {setAuthInLocalStorage} from "../utilities/AuthUtil";
import {SignInResponse} from "../entities/response/SignInResponse";
import {GlobalUserResponse} from "../entities/response/user/GlobalUserResponse";
import {GlobalUserSignUpRequest} from "../entities/request/globalUser/GlobalUserSignUpRequest";
import {
    getRefreshTokenFromLocalStorage,
    getUserEmailAddressFromLocalStorage, setGlobalUserInLocalStorage,
} from "../utilities/LocalStorageUtil";

const baseGlobalUserAuthUrl = baseApiUrl + '/globalUsers';

export const signUpUser = async (globalUserSignUpRequest : GlobalUserSignUpRequest) : Promise<GlobalUserResponse> => {
    try {
        const globalUserResponse : GlobalUserResponse = await executeRequest(
            `${baseGlobalUserAuthUrl}/signup`,
            RequestMethod.POST,
            globalUserSignUpRequest
        );

        return Promise.resolve(globalUserResponse);
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export const getOTPForForgotPassword = async (email : string) : Promise<void> => {
    try {
        await executeRequest(
            `${baseGlobalUserAuthUrl}/forgotPasswordOTP`,
            RequestMethod.POST,
            {
                emailAddress : email
            }
        );

        return Promise.resolve();
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export const resetPasswordForUser = async (email : string, password: string, code: string) : Promise<void> => {
    try {
        await executeRequest(
            `${baseGlobalUserAuthUrl}/setNewPassword`,
            RequestMethod.POST,
            {
                emailAddress : email,
                code,
                password
            }
        );

        return Promise.resolve();
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export const confirmSignUpUser = async (email : string, passcode : string) : Promise<string> => {
    try {
        const res : string = await executeRequest(
            `${baseGlobalUserAuthUrl}/confirmSignup`,
            RequestMethod.POST,
            {
                emailAddress : email,
                passcode : passcode
            }
        );

        return Promise.resolve(res);
    }
    catch (e) {
        return Promise.reject(e)
    }
}


export const signInUser = async (emailAddress : string, password : string) : Promise<SignInResponse> => {
    try {
        const signInResponse : SignInResponse = await executeRequest(
            `${baseGlobalUserAuthUrl}/signIn`,
            RequestMethod.POST,
            {
                emailAddress: emailAddress,
                password: password
            }
        );

        return Promise.resolve(signInResponse);
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export const refreshToken = async () => {
    const refresh_token = getRefreshTokenFromLocalStorage();

    if(refresh_token && refresh_token !== "undefined") {
        try {
            const refreshResponse = await executeRequest(`${baseGlobalUserAuthUrl}/refreshToken`, RequestMethod.POST, {
                refreshToken: refresh_token
            })
            setAuthInLocalStorage(refreshResponse)
            return;
        }
        catch (e) {
            localStorage.clear();
            window.location.href = "/login";
        }
    }
    else {
        localStorage.clear();
        window.location.href = "/login";
    }

    return false;
}

export const resetGlobalUserMembershipDetails = async () => {
    try {
        const emailAddress = getUserEmailAddressFromLocalStorage();
        if(emailAddress) {
            const globalUserResponse : GlobalUserResponse = await executeRequest(
                `${baseGlobalUserAuthUrl}/email`,
                RequestMethod.POST,
                {
                    emailAddress: emailAddress,
                }
            );
            setGlobalUserInLocalStorage(globalUserResponse)
        }
    }
    catch (e) {
        throw e;
    }

}

export const getGlobalUserById = async (globalUserId : string) : Promise<GlobalUserResponse> => {
    try {
        return await executeRequest(`${baseGlobalUserAuthUrl}/${globalUserId}`, RequestMethod.GET, undefined, null) as GlobalUserResponse;
    }
    catch (err) {
        throw err;
    }

}

export const getGlobalUserByEmail = async (email : string) : Promise<GlobalUserResponse> => {
    try {
        return await executeRequest(
            `${baseGlobalUserAuthUrl}/email`,
            RequestMethod.POST,
            { emailAddress: email },
            null
        ) as GlobalUserResponse;
    }
    catch (err) {
        throw err;
    }
}

export const getAllGlobalUsers = async () : Promise<GlobalUserResponse[]> => {
    try {
        return await executeRequest(`${baseGlobalUserAuthUrl}`, RequestMethod.GET, undefined, null) as GlobalUserResponse[];
    }
    catch (err) {
        throw err;
    }

}

export const patchGlobalUserById = async (
    globalUserId : string, attr : any
) : Promise<GlobalUserResponse> => {
    try {
        return await executeRequest(`${baseGlobalUserAuthUrl}/${globalUserId}`, RequestMethod.PATCH, attr, null) as GlobalUserResponse;
    }
    catch (err) {
        throw err;
    }

}
