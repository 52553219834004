import {SecurityGroupFeatures} from "../enums/SecurityGroupFeatures";
import {CompanyLevelActions} from "../enums/featureActions/CompanyLevelActions";
import {IndicatorLevelActions} from "../enums/featureActions/IndicatorLevelActions";
import {
    getCompanyIdSelectedFromLocalStorage,
    getCompanyLevelPermissionsOfUserForCompanyInLocalStorage,
    getIndicatorLevelPermissionsOfUserForCompanyInLocalStorage
} from "../utilities/LocalStorageUtil";
import {IndicatorNumber} from "../enums/IndicatorNumber";

export const checkIfUserHasCompanyLevelPermissions = (companyLevelAction : CompanyLevelActions) => {
    return checkIfUserHasPermission(
        SecurityGroupFeatures.COMPANY_LEVEL,
        getCompanyIdSelectedFromLocalStorage() || '',
        companyLevelAction
    );
}

export const checkIfUserHasIndicatorLevelPermissions = (
    indicatorNumber : IndicatorNumber,
    indicatorLevelAction : IndicatorLevelActions
) => {
    return checkIfUserHasPermission(
        SecurityGroupFeatures.INDICATOR,
        indicatorNumber,
        indicatorLevelAction
    );
}

export const checkIfUserHasPermission = (
    feature: SecurityGroupFeatures,
    featureReferenceId : string,
    actionOnFeature : CompanyLevelActions | IndicatorLevelActions
) : boolean => {
    switch (feature) {
        case SecurityGroupFeatures.INDICATOR:
            return getIndicatorLevelPermissionsOfUserForCompanyInLocalStorage()
                .get(featureReferenceId as IndicatorNumber)
                ?.includes(actionOnFeature as string)
                || false
        case SecurityGroupFeatures.COMPANY_LEVEL:
            return getCompanyLevelPermissionsOfUserForCompanyInLocalStorage()
                ?.includes(actionOnFeature) || false
    }
}
