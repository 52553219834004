import {baseApiUrl, executeRequest, RequestMethod} from "./BaseService";
import {IndicatorDataEntryResponse} from "../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {IndicatorDataEntryCreateRequest} from "../entities/request/indicatorDataEntry/IndicatorDataEntryCreateRequest";
import {EntitySearch} from "../entities/request/EntitySearch";
import {IndicatorNumber} from "../enums/IndicatorNumber";

const baseIndicatorDataEntryUrl = baseApiUrl + '/indicatorDataEntries';

export const getAllIndicatorDataEntriesForIndicator = async (
    indicatorNumber : IndicatorNumber,
    localUserDetails? : {companyId : string, localUserId: string}
) : Promise<IndicatorDataEntryResponse[]> => {
    try {
        return await executeRequest(
            `${baseIndicatorDataEntryUrl}/${indicatorNumber}`,
            RequestMethod.GET,
            undefined,
            localUserDetails ?
                {
                    'Authorization': `companyId=${localUserDetails.companyId},localUserId=${localUserDetails.localUserId}`
                } : null
        ) as IndicatorDataEntryResponse[];
    }
    catch (err) {
        throw err;
    }
}

export const filterIndicatorDataEntriesForIndicator = async (
    indicatorNumber: IndicatorNumber,
    indicatorDataEntryFilters: EntitySearch,
    localUserDetails? : {companyId : string, localUserId: string}
) : Promise<IndicatorDataEntryResponse[]> => {
    try {
        return await executeRequest(
            `${baseIndicatorDataEntryUrl}/${indicatorNumber}/filter`,
            RequestMethod.POST,
            indicatorDataEntryFilters,
            localUserDetails ?
                {
                    'Authorization': `companyId=${localUserDetails.companyId},localUserId=${localUserDetails.localUserId}`
                } : null
        ) as IndicatorDataEntryResponse[];
    }
    catch (err) {
        throw err;
    }
}

export const patchIndicatorDataEntryForIndicator = async (indicatorDataEntryId : string, indicatorNumber : IndicatorNumber, attr : any) : Promise<IndicatorDataEntryResponse> => {
    try {
        return await executeRequest(`${baseIndicatorDataEntryUrl}/${indicatorNumber}/dataEntry/${indicatorDataEntryId}`, RequestMethod.PATCH, attr, null) as IndicatorDataEntryResponse;
    }
    catch (err) {
        throw err;
    }
}

export const createIndicatorDataEntry = async (indicatorNumber : IndicatorNumber, indicatorDataEntryCreateRequest: IndicatorDataEntryCreateRequest) : Promise<IndicatorDataEntryResponse> => {
    try {
        return await executeRequest(`${baseIndicatorDataEntryUrl}/${indicatorNumber}/`, RequestMethod.POST, indicatorDataEntryCreateRequest, null) as IndicatorDataEntryResponse;
    }
    catch (err) {
        throw err;
    }
}

export const uploadAttachmentsForIndicatorDataEntry = async (files : FormData) : Promise<IndicatorDataEntryResponse> => {
    try {
        return await executeRequest(`${baseIndicatorDataEntryUrl}/uploadAttachment`, RequestMethod.POST, files, {
            "Content-Type" : null
        }, false) as IndicatorDataEntryResponse;
    }
    catch (err) {
        throw err;
    }
}


