import React from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import {Alert, Box, Tab, TabList, TabPanel, TabPanels, Tabs, VStack} from "@chakra-ui/react";
import {ManageLocalUsers} from "../components/ManageLocalUsers";
import {ManageUserGroups} from "../components/ManageUserGroups";
import {SidebarWithHeader} from "../components/SidebarWithHeader";
import {useTranslation} from "react-i18next";
import {checkIfUserHasCompanyLevelPermissions} from "../helpers/PermissionsHelper";
import {CompanyLevelActions} from "../enums/featureActions/CompanyLevelActions";

export const ManageLocalUsersAndUserGroups = () => {

    const { t } = useTranslation();

    return (
        <SidebarWithHeader isNonCompanySpecificPage={false}>
            <Box m={10} w={"80%"}>
                {
                    (
                        checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.INVITE_USER) ||
                        checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.UPDATE_USER_GROUP)
                    ) ? (<>
                        <Tabs variant='soft-rounded' colorScheme='green'>
                            <TabList>
                                {
                                    checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.INVITE_USER) && (
                                        <Tab>{t("Company Users")}</Tab>
                                    )
                                }
                                {
                                    checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.UPDATE_USER_GROUP) && (
                                        <Tab>{t("User Groups")}</Tab>
                                    )
                                }
                            </TabList>
                            <TabPanels>
                                {
                                    checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.INVITE_USER) && (
                                        <TabPanel>
                                            <ManageLocalUsers isActive={true} />
                                        </TabPanel>
                                    )
                                }
                                {
                                    checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.UPDATE_USER_GROUP) && (
                                        <TabPanel>
                                            <ManageUserGroups isActive={true} />
                                        </TabPanel>
                                    )
                                }
                            </TabPanels>
                        </Tabs>
                    </>) : (<>
                        <VStack h={"80vh"} justify={'center'}>
                            <Alert w={"40%"}>{t("Insufficient permissions")}!</Alert>
                        </VStack>
                    </>)
                }
            </Box>
        </SidebarWithHeader>
    );
}
