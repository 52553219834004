import * as React from "react";
import {HalfPieChart} from "half-pie-chart";
import {useTranslation} from "react-i18next";

export const HalfPieChartCustomized = (props) => {
    const {
        achieved,
        target,
        chartColour,
        title,
    } = props;

    const {t} = useTranslation();

    return <HalfPieChart
        name="rentStatus"
        right={[
            {
                value: achieved,
                displayValue: achieved,
                text: t("Achieved"),
                color: chartColour,
            }
        ]}
        left={[
            {
                value: target - achieved,
                displayValue: target,
                text: t("Target"),
                color: "#ffffff",
            }
        ]}
        title={title}
    />
};
