export const PhaseNames = {
    "PHASE1": {
        "en": "Reporting",
        "pt": "Reporte"
    },
    "PHASE2": {
        "en": "Verification",
        "pt": "Verificação"
    },
    "PHASE3": {
        "en": "Post-Verification",
        "pt": "Pós-verificação"
    },
    "PHASE4": {
        "en": "Validation",
        "pt": "Validação"
    },
    "PHASE5": {
        "en": "Contradictory",
        "pt": "Contraditório"
    },
    "PHASE6": {
        "en": "Post-Contradictory",
        "pt": "Pós-Contraditório"
    }
}
