import {baseApiUrl, executeRequest, RequestMethod} from "./BaseService";
import {IndicatorResponse} from "../entities/response/indicator/IndicatorResponse";
import {IndicatorDataType} from "../enums/IndicatorDataType";
import {getIdTokenFromLocalStorage} from "../utilities/LocalStorageUtil";

const baseIndicatorUrl = baseApiUrl + '/indicators';

export const getAllIndicators = async (companyId?: string, localUserId?: string) : Promise<IndicatorResponse[]> => {
    try {
        if (companyId && localUserId) {
            const idToken = getIdTokenFromLocalStorage();
            if (idToken) {
                return await executeRequest(
                    `${baseIndicatorUrl}`,
                    RequestMethod.GET,
                    undefined,
                    {
                        Authorization: `companyId=${companyId},localUserId=${localUserId},idToken=${idToken}`
                    }
                ) as IndicatorResponse[];
            }
        }
        return await executeRequest(`${baseIndicatorUrl}`, RequestMethod.GET, undefined, null) as IndicatorResponse[];
    }
    catch (err) {
        throw err;
    }
}

export const getIndicatorsByIds = async (indicatorIds: string[], companyId?: string, localUserId?: string) : Promise<IndicatorResponse[]> => {
    try {
        const requestPromises = indicatorIds.map(
            indicatorId => {
                return executeRequest(`${baseIndicatorUrl}/${indicatorId}`, RequestMethod.GET, undefined, null) as Promise<IndicatorResponse>;
            }
        )

        return await Promise.all(requestPromises);
    }
    catch (err) {
        throw err;
    }
}

export const getIndicatorById = async (indicatorId : string) : Promise<IndicatorResponse> => {
    try {
        return await executeRequest(`${baseIndicatorUrl}/${indicatorId}`, RequestMethod.GET, undefined, null) as IndicatorResponse;
    }
    catch (err) {
        throw err;
    }
}

export const sendIndicatorToNextPhase = async (indicatorId : string, indicatorDataType: IndicatorDataType) : Promise<IndicatorResponse> => {
    try {
        return await executeRequest(`${baseIndicatorUrl}/${indicatorId}/sendToNextPhase`, RequestMethod.POST, {indicatorDataType}, null) as IndicatorResponse;
    }
    catch (err) {
        throw err;
    }
}

export const sendIndicatorToFirstPhase = async (indicatorId : string, indicatorDataType: IndicatorDataType) : Promise<IndicatorResponse> => {
    try {
        return await executeRequest(`${baseIndicatorUrl}/${indicatorId}`, RequestMethod.PATCH, { [`indicatorDataTypeStructureList.${indicatorDataType}.currentPhaseIndex`]: 0 }, null) as IndicatorResponse;
    }
    catch (err) {
        throw err;
    }
}

