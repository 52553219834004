export enum IndicatorNumber {
    INDICATOR_1 = 'INDICATOR_1',
    INDICATOR_2 = 'INDICATOR_2',
    INDICATOR_3 = 'INDICATOR_3',
    INDICATOR_4 = 'INDICATOR_4',
    INDICATOR_5 = 'INDICATOR_5',
    INDICATOR_6 = 'INDICATOR_6',
    INDICATOR_7 = 'INDICATOR_7',
    INDICATOR_8 = 'INDICATOR_8',
}
