import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import moment from "moment/moment";
import {Flex, Heading} from "@chakra-ui/react";
import {ColumnDataType, Table} from "../../GenericCharts/Table";
import * as React from "react";
import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {getIconFunctionForTable} from "../../utilities/IconFunction";
import {computeFinalScore} from "../../utilities/ProcessData";
import {getLastPhaseForIndicatorEntry} from "../../../../helpers/ApplicationHelper";
import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import {getPerformanceColour} from "../../ReportsHelper";

export const TableAllIndicatorQuarterAchievement = (
    props: {
        chartName : string,
        indicatorDataEntries : Map<IndicatorResponse, IndicatorDataEntryResponse | null>,
        reportDuration : moment.Moment,
        onChartImageURI: any,
    }
): JSX.Element => {

    const {
        chartName,
        indicatorDataEntries,
        reportDuration,
        onChartImageURI
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;


    const tableData : {
        indicator: IndicatorResponse,
        indicatorDataEntry: IndicatorDataEntryResponse | null,
        achieved : number |  null,
        target : number,
        reliability : number,
    }[] = Array.from(indicatorDataEntries.entries()).map(([indicatorResponse, indicatorDataEntry]) => {

        const lastPhaseData = indicatorDataEntry ? getLastPhaseForIndicatorEntry(indicatorDataEntry) : null;

        return {
            indicator: indicatorResponse,
            indicatorDataEntry: indicatorDataEntry,
            achieved : lastPhaseData ? computeFinalScore(lastPhaseData, indicatorResponse, IndicatorDataType.AURA_REPORTING).score : null,
            target : lastPhaseData ? parseInt(lastPhaseData['target'].value || '100') : 100,
            reliability : lastPhaseData ? parseInt(lastPhaseData['reliability'].value || '0') : 0,
        };
    })


    const getTableColumnsFromSeriesData = (): ColumnDataType[] => {
        return [
            {
                header: t('Indicators'),
                singleValues: Array.from(indicatorDataEntries.keys()).map(indicator => indicator.name[selectedLanguage]),
                stars: [],
                values: [],
                notApplicable : [],
                notReported : [],
                targets: [],
                coloringFunctions: [],
                iconFunction: null
            },
            {
                header: t('Achievement'),
                singleValues: undefined,
                stars: tableData.map(value => value.reliability),
                values: tableData.map(value => value.achieved),
                notApplicable : tableData.map(value => null),
                notReported : tableData.map(value => null),
                targets: tableData.map(value => value.target),
                coloringFunctions: tableData.map(value => (performanceScore : number) => getPerformanceColour(performanceScore, value.indicator.indicatorNumber)),
                iconFunction: getIconFunctionForTable()
            }
        ];
    }

    return (
        <>
            <Flex flexDirection={"column"}>
                <Heading size={'md'} mb={5}>{chartName}</Heading>
                <Table
                    name={chartName}
                    tableId={chartName}
                    columns={getTableColumnsFromSeriesData()}
                    noOfRows={indicatorDataEntries.size}
                />
            </Flex>
        </>
    )
}
