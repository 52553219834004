import React from 'react';
import {
    Badge,
    Box,
    Button,
    Center,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Heading, IconButton,
    Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay, Spinner,
    Stack,
    Text, Tooltip,
    useBreakpointValue,
    useDisclosure,
    useToast, VStack
} from "@chakra-ui/react";
import {Alert} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {createCompany, getCompanyDetails, patchCompany} from "../services/CompanyService";
import {checkUserLoggedIn} from "../utilities/AuthUtil";
import {UserInviteResponse} from "../entities/response/userInvite/UserInviteResponse";
import {acceptUserInvite, getAllUserInvitesForEmailAddress} from "../services/UserInviteService";
import {CompanyResponse} from "../entities/response/company/CompanyResponse";
import {resetGlobalUserMembershipDetails} from "../services/GlobalUserService";
import {
    checkIfGlobalUserHasManageGlobalUserGroupPermission,
    checkIfGlobalUserHasManageGlobalUsersPermissionPermission,
    checkIfGlobalUserHasViewCrossMunicipalityPermission, getGlobalUserFromLocalStorage,
    getUserCompanyMembershipsFromLocalStorage,
    getUserEmailAddressFromLocalStorage,
    setCompanyIdSelectedInLocalStorage,
    setCompanySelectedInLocalStorage,
    setLocalUserIdIdSelectedInLocalStorage,
    setUserPermissionsForCompanyInLocalStorage
} from "../utilities/LocalStorageUtil";
import {GlobalUserCompanyMembershipResponse} from "../entities/response/user/GlobalUserResponse";
import {CrossMunicipalityReportPath} from "../App";
import {getPermissions} from "../utilities/PermissionModel";
import {toastDuration} from "../constants/ApplicationConstants";
import {DetailsResponse} from "../entities/response/DetailsResponse";
import {MetaDataResponse} from "../entities/response/MetaDataEntity";
import {navigateToLoginPage} from "../helpers/NavigationHelper";
import {ChevronDownIcon, ViewIcon, ViewOffIcon} from "@chakra-ui/icons";

export interface ICompany {
    companyId: string;
    companyDetails: DetailsResponse;
    companyMetaData: MetaDataResponse;
    companyEnabled: boolean;
    companySampleDataAdded: boolean;
    companyCreationStatus: string;
    localUserId: string;
    companyName: string;
}

const CustomOverlay = () => (
    <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px)'
    />
);

export const CompaniesList = () => {
    const [companies, setCompanies] = React.useState<ICompany[]>();
    const [refresh, setRefresh] = React.useState<boolean>(true);
    const toast = useToast();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCreateCompanyBtnLoading, setIsCreateCompanyBtnLoading] = React.useState<boolean>(false);
    const [isCompaniesLoading, setIsCompaniesLoading] = React.useState<boolean>();
    const [isCompanyStatusUpdateLoading, setIsCompanyStatusUpdateLoading] = React.useState<boolean>();
    const [isCompanySelectedLoading, setIsCompanySelectedLoading] = React.useState<boolean>();
    const [companyIndexSelected, setCompanyIndexSelected] = React.useState<number | undefined>();
    const [invites, setInvites] = React.useState<UserInviteResponse[]>([]);
    const [companyName, setCompanyName] = React.useState<string>();
    const { t } = useTranslation();
    const [canCreateNewCompany, setCanCreateNewCompany] = React.useState<boolean | undefined>(undefined);
    const [canManageGlobalUserGroups, setCanManageGlobalUserGroups] = React.useState<boolean | undefined>(undefined);
    const [canManageGlobalUsers, setCanManageGlobalUsers] = React.useState<boolean | undefined>(undefined);
    const [canSeeCrossMunicipalityReport, setCanSeeCrossMunicipalityReport] = React.useState<boolean | undefined>(undefined);
    const [canUpdateCompanyStatus, setCanUpdateCompanyStatus] = React.useState<boolean | undefined>(undefined);

    React.useEffect(() => {
        if (!checkUserLoggedIn()) {
            navigateToLoginPage(navigate)
            return;
        }

        getPermissions().then(memberships => {
            const applicationAdminMembershipStatus = memberships.find(m => m.globalUserGroupName === 'Application Admins');

            setCanCreateNewCompany(
                (applicationAdminMembershipStatus?.status) ?? false
            );

            setCanUpdateCompanyStatus(
                (applicationAdminMembershipStatus?.status) ?? false
            );

            setCanManageGlobalUserGroups(checkIfGlobalUserHasManageGlobalUserGroupPermission());
            setCanManageGlobalUsers(checkIfGlobalUserHasManageGlobalUsersPermissionPermission());
            setCanSeeCrossMunicipalityReport(checkIfGlobalUserHasViewCrossMunicipalityPermission());
        });
    }, []);

    const onCompanySelect = async (indexSelected : number, companySelected: ICompany, companyCreationInProgress: boolean) => {
        if (companyCreationInProgress)
            return;

        setCompanyIndexSelected(indexSelected);
        setIsCompanySelectedLoading(true)

        try {

            setCompanyIdSelectedInLocalStorage(companySelected.companyId);
            setLocalUserIdIdSelectedInLocalStorage(companySelected.localUserId);
            setCompanySelectedInLocalStorage(companySelected);

            await setUserPermissionsForCompanyInLocalStorage();

            toast({
                title: `${companySelected.companyName} ${t('selected')}!`,
                description: '',
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });

            navigate('/indicators');
        }
        catch {
            toast({
                title: `${t('Company selection failed')}!`,
                description: `${t('Try again')}!`,
                status: 'error',
                duration: toastDuration,
                isClosable: true,
            });
        }
        finally {
            setCompanyIndexSelected(undefined);
            setIsCompanySelectedLoading(false)
        }

    }

    React.useEffect( () => {
        if (refresh) {
            setIsCompaniesLoading(true)
            const emailAddress = getUserEmailAddressFromLocalStorage()

            if(emailAddress !== null && emailAddress !== undefined) {
                getAllUserInvitesForEmailAddress(emailAddress)
                    .then((userInvites : UserInviteResponse[]) => {
                        setInvites(userInvites);
                    })
            }

            const companyMemberships = getUserCompanyMembershipsFromLocalStorage();
            Promise.all(companyMemberships.map(
                (companyMembership: GlobalUserCompanyMembershipResponse) =>
                    getCompanyDetails(companyMembership.companyId, companyMembership.localUserId)
            )).then((companiesDetails : ICompany[]) => {
                setCompanies(companiesDetails);
                setRefresh(false);
                setIsCompaniesLoading(false)
            })

        }
    }, [refresh]);

    const acceptInvite = (inviteToAccept : UserInviteResponse) => {
        if (inviteToAccept) {
            acceptUserInvite(inviteToAccept.id, {
                name: getGlobalUserFromLocalStorage()?.name || "name",
                email : getGlobalUserFromLocalStorage()?.email || "email",
                password : "password"
            }).then(
                async (userInvite : UserInviteResponse) => {
                    await resetGlobalUserMembershipDetails();
                    setRefresh(true);
                    toast({
                        title: `${t('Accepted invite successfully')}!`,
                        description: '',
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true
                    });
                }
            ).catch(err => {
                    toast({
                        title: `${t('Failed to accept invite')}!`,
                        description: `${t(err?.message ? err.message.toString() : 'Try again')}!`,
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true
                    });
                })
        }
    };

    const handleCompanyNameChange = ((event: { target: HTMLInputElement }) => {
        const value = event.target.value;
        if (value) {
            setCompanyName(value);
        }
    });

    const createCompanyHelper = (e : any) => {
        if(companyName !== undefined && companyName !== null) {
            setIsCreateCompanyBtnLoading(true);
            createCompany({
                "name":  companyName,
                "description" : companyName,
            }).then( async (createdCompany : CompanyResponse) => {

                await resetGlobalUserMembershipDetails();

                toast({
                    title: `${t('Created new company')} ${createdCompany?.name}`,
                    description: '',
                    status: 'success',
                    duration: toastDuration,
                    isClosable: true
                });

                setIsCreateCompanyBtnLoading(false);
                setRefresh(true);
                onClose();
            }).catch(err => {
                setIsCreateCompanyBtnLoading(false);
                toast({
                    title: `${t('Failed to create company')} - ${companyName}`,
                    description: `${t(err?.message ? err.message.toString() : 'Try again')}!`,
                    status: 'error',
                    duration: toastDuration,
                    isClosable: true
                });
            })
        }
    }

    const enableDisableCompany = (indexSelected : number, company : ICompany, newStatus : boolean) => {
        if(company !== null && newStatus !== null) {
            setCompanyIndexSelected(indexSelected);
            setIsCompanyStatusUpdateLoading(true);
            patchCompany(
                company.companyId,
                {enabled : newStatus},
                {companyId : company.companyId, localUserId : company.localUserId}
            ).then( async (updatedCompany : CompanyResponse) => {

                await resetGlobalUserMembershipDetails();

                toast({
                    title: `${updatedCompany?.name} ${newStatus ? t('Enabled') : t('Disabled')}`,
                    description: '',
                    status: 'success',
                    duration: toastDuration,
                    isClosable: true
                });

                setIsCompanyStatusUpdateLoading(false);
                setCompanyIndexSelected(undefined);
                setRefresh(true);

            }).catch(err => {
                setIsCompanyStatusUpdateLoading(false);
                setCompanyIndexSelected(undefined);
                toast({
                    title: `Failed to ${newStatus ? t('Enable') : t('Disable')} ` + company.companyName,
                    description: err.message ? err.message.toString() : "",
                    status: 'error',
                    duration: toastDuration,
                    isClosable: true
                });
            })
        }

    }
    return (
        <Flex bg='#f7fafc' w={"100%"} minH={"100vh"}>
            <Container width={"100%"} maxW={"60%"}>
                <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"} isCentered>
                    <CustomOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <Text>{t("Create new company")}</Text>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box w={"100%"}>
                                <Stack spacing="5">
                                    <FormControl>
                                        <FormLabel htmlFor="companyName">{t("Company name")}</FormLabel>
                                        <Input id="companyName" type="name" onChange={handleCompanyNameChange}/>
                                    </FormControl>
                                </Stack>
                            </Box>
                            <Center mb={5}>
                                <Button isLoading={isCreateCompanyBtnLoading} loadingText={`${t("Creating Company")}...`} mt={8} colorScheme={"blue"} onClick={createCompanyHelper}>{t("Create")}</Button>
                            </Center>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                {
                    <Flex justifyContent={"end"} mt={5} mb={5}>
                        {
                            (canCreateNewCompany || canManageGlobalUsers || canManageGlobalUserGroups) && (
                                <Box m={2}>
                                    <Menu colorScheme={'blue'}>
                                        <MenuButton as={Button} colorScheme='blue' rightIcon={<ChevronDownIcon />}>
                                            {t("Admin Actions")}
                                        </MenuButton>
                                        <MenuList>
                                            {
                                                canCreateNewCompany !== false ? (
                                                    <MenuItem onClick={onOpen}>
                                                        {
                                                            t("Create new company")
                                                        }
                                                    </MenuItem>
                                                ) : null
                                            }
                                            {
                                                canManageGlobalUsers !== false ? (
                                                    <MenuItem onClick={() => navigate('/global-users')}>
                                                        {
                                                            t("Manage All Users")
                                                        }
                                                    </MenuItem>
                                                ) : null
                                            }
                                            {

                                                canManageGlobalUserGroups !== false ? (
                                                    <MenuItem onClick={() => navigate('/manageGlobalUserGroups')}>
                                                        {
                                                            t("Manage Multi Company Accesses")
                                                        }
                                                    </MenuItem>
                                                ): null
                                            }
                                        </MenuList>
                                    </Menu>
                                </Box>
                            )
                        }
                        <Button
                            m={2}
                            colorScheme="blue"
                            onClick={() => navigateToLoginPage(navigate)}
                        >
                            {t("Sign out")}
                        </Button>
                    </Flex>
                }
                <Flex direction="column" mb={5}>
                    {invites && invites.length > 0 ? <Box mb={5}>{t("You have some new invites")}: </Box> : null}
                    {invites?.map((invite: UserInviteResponse, idx) => {
                        return (
                            <Alert key={idx} variant="warning">
                                <Flex justifyContent={"space-between"}>
                                    <Center>
                                        <Badge>{invite.inviteStatus}</Badge>
                                        <Box ml="10px">{t("Invite from")} <b>{invite.companyName}</b></Box>
                                    </Center>
                                    <Center>
                                        <Button onClick={e => acceptInvite(invite)} colorScheme={'blue'}>{t("Accept")}</Button>
                                    </Center>
                                </Flex>
                            </Alert>
                        );
                    })}
                </Flex>
                <Center color={"black"}>
                    <Center width={"100%"} flexDirection={"column"}>
                        <Stack width={"60vh"}>
                            <Center mb={5}>
                                <Heading size={useBreakpointValue({ base: 'l', md: 'xl' })}>
                                    {t("Select Municipality")}
                                </Heading>
                            </Center>
                            <Box
                                maxH={"60vh"}
                                overflowY={"auto"}
                                css={{
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        width: '6px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: 'black',
                                        borderRadius: '50px',
                                    },
                                }}
                            >
                                <Flex direction={"column"}>
                                    {
                                        !isCompaniesLoading ? (<>
                                            {
                                                companies && companies.length > 0 ? (<>
                                                    {
                                                        companies?.map((company: ICompany, idx: number) => {
                                                            const companyCreationInProgress = company.companyCreationStatus === 'IN_PROGRESS';
                                                            return (
                                                                <Center key={`company-choice-${idx}`} p={5}>
                                                                    <Flex w="80%" flexDirection={'row'}>
                                                                        <Flex
                                                                            w={"100%"}
                                                                            flexDirection={'column'}
                                                                            ml={5}
                                                                            mr={5}
                                                                        >
                                                                            {
                                                                                companyCreationInProgress
                                                                                    ? (
                                                                                        <Flex style={{position: 'relative', top: 30, right: 10, zIndex: 100}} justifyContent={'flex-end'}>
                                                                                            <Badge colorScheme={'red'}>{t('In Progress')}</Badge>
                                                                                        </Flex>
                                                                                    ) : !company.companyEnabled
                                                                                        ? (
                                                                                            <Flex style={{position: 'relative', top: 30, right: 10, zIndex: 100}} justifyContent={'flex-end'}>
                                                                                                <Badge colorScheme={'red'}>{t('Disabled')}</Badge>
                                                                                            </Flex>
                                                                                        ) : null
                                                                            }
                                                                            <Button
                                                                                py={{ base: '0', sm: '8' }}
                                                                                px={{ base: '4', sm: '10' }}
                                                                                bg="white"
                                                                                borderRadius={{ base: 'none', sm: 'xl' }}
                                                                                w={"100%"}
                                                                                variant={"outline"}
                                                                                h={"80px"}
                                                                                whiteSpace="normal"
                                                                                height="auto"
                                                                                blockSize="auto"
                                                                                isLoading={
                                                                                    isCompanySelectedLoading
                                                                                    && companyIndexSelected === idx
                                                                                }
                                                                                loadingText={`${t('Loading')} ${company.companyName} ${t('permissions')}...`}
                                                                                isDisabled={
                                                                                    !company.companyEnabled
                                                                                    || isCompanyStatusUpdateLoading
                                                                                    || isCompanySelectedLoading
                                                                                }
                                                                                data-choice={idx}
                                                                                onClick={() => onCompanySelect(idx, company, companyCreationInProgress)}>
                                                                                <Text padding={1}>
                                                                                    {company.companyName}
                                                                                </Text>
                                                                            </Button>
                                                                        </Flex>

                                                                        {
                                                                            canUpdateCompanyStatus && (
                                                                                <Tooltip
                                                                                    label={
                                                                                        `${company.companyEnabled ? t("Disable") : t("Enable")} ${company.companyName}`
                                                                                    }
                                                                                    ml={5}
                                                                                    mr={5}
                                                                                >
                                                                                    <IconButton
                                                                                        mt={company.companyEnabled ? 0 : "20px"}
                                                                                        alignSelf={"center"}
                                                                                        aria-label={company.companyEnabled ? t("Disable") : t("Enable")}
                                                                                        isLoading={
                                                                                            isCompanyStatusUpdateLoading
                                                                                            && companyIndexSelected === idx
                                                                                        }
                                                                                        isDisabled={
                                                                                            companyCreationInProgress
                                                                                            || isCompanyStatusUpdateLoading
                                                                                            || isCompanySelectedLoading
                                                                                        }
                                                                                        colorScheme={company.companyEnabled ? 'red' : 'green'}
                                                                                        onClick={() => enableDisableCompany(idx, company, !company.companyEnabled)}
                                                                                    >
                                                                                        {
                                                                                            company.companyEnabled
                                                                                                ? <ViewOffIcon />
                                                                                                : <ViewIcon />
                                                                                        }
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            )
                                                                        }
                                                                    </Flex>
                                                                </Center>
                                                            );
                                                        })
                                                    }
                                                </>) : (<>
                                                    <VStack h={"80vh"} justify={'center'}>
                                                        <Text>{t("You're part of no companies, please ask admin to allow access")}!</Text>
                                                    </VStack>
                                                </>)
                                            }
                                        </>) : (<>
                                            <VStack h={"80vh"} justify={'center'}>
                                                <Spinner size='xl' />
                                                <Text>{t("Loading companies, please wait")}!</Text>
                                            </VStack>
                                        </>)
                                    }
                                </Flex>
                            </Box>
                        </Stack>
                        {
                            canSeeCrossMunicipalityReport !== false ? <Box w={'100%'}>
                                <Center>
                                    <Flex ml={15} mt={10} flexDirection={'row'} justifyContent={'flex-end'}>
                                        <Button
                                            colorScheme={'blackAlpha'}
                                            isLoading={canSeeCrossMunicipalityReport === undefined}
                                            loadingText={t('Checking access')}
                                            onClick={() => navigate(CrossMunicipalityReportPath)}>
                                            {t("View cross municipality reports")} 📊
                                        </Button>
                                    </Flex>
                                </Center>
                            </Box> : null
                        }
                    </Center>
                </Center>
            </Container>
        </Flex>
    );
};
