import {baseApiUrl, executeRequest, RequestMethod} from "./BaseService";
import {UserInviteResponse} from "../entities/response/userInvite/UserInviteResponse";
import {GlobalUserSignUpRequest} from "../entities/request/globalUser/GlobalUserSignUpRequest";
import {InviteUserRequest} from "../entities/request/userInvite/InviteUserRequest";

const baseUserInvitesUrl = baseApiUrl + '/userInvites';

export const getAllUserInvitesForCompany = async (companyId : string) : Promise<UserInviteResponse[]> => {
    try {
        const res = await executeRequest(`${baseUserInvitesUrl}/${companyId}`, RequestMethod.GET, undefined, null) as UserInviteResponse[];
        return Promise.resolve(res);
    }
    catch (err) {
        throw err;
    }
}

export const getAllUserInvitesForEmailAddress = async (emailAddress : string) : Promise<UserInviteResponse[]> => {
    try {
        const res = await executeRequest(`${baseUserInvitesUrl}/emailAddress`, RequestMethod.POST, {emailAddress}, null) as UserInviteResponse[];
        return Promise.resolve(res);
    }
    catch (err) {
        throw err;
    }
}

export const inviteUser = async (inviteUserRequest : InviteUserRequest) : Promise<UserInviteResponse> => {
    try {
        const res = await executeRequest(`${baseUserInvitesUrl}/inviteUser`, RequestMethod.POST, inviteUserRequest, null) as UserInviteResponse;
        return Promise.resolve(res);
    }
    catch (err) {
        throw err;
    }
}

export const rejectUserInvite = async (inviteId: string) : Promise<UserInviteResponse> => {
    try {
        const res = await executeRequest(`${baseUserInvitesUrl}/rejectUserInvite/${inviteId}`, RequestMethod.POST, undefined, null) as UserInviteResponse;
        return Promise.resolve(res);
    }
    catch (err) {
        throw err;
    }
}

export const acceptUserInvite = async (userInviteId: string, newUserSignupRequest : GlobalUserSignUpRequest) : Promise<UserInviteResponse> => {
    try {
        const res = await executeRequest(`${baseUserInvitesUrl}/acceptUserInvite/${userInviteId}`, RequestMethod.POST, newUserSignupRequest, null) as UserInviteResponse;
        return Promise.resolve(res);
    }
    catch (err) {
        throw err;
    }
}
