import {baseApiUrl, executeRequest, RequestMethod} from "./BaseService";
import {LocalUserResponse} from "../entities/response/user/LocalUserResponse";

const baseLocalUserAuthUrl = baseApiUrl + '/users';

export const getAllLocalUsersInCompany = async () : Promise<LocalUserResponse[]> => {
    try {
        return await executeRequest(`${baseLocalUserAuthUrl}`, RequestMethod.GET, undefined, null) as LocalUserResponse[];
    }
    catch (err) {
        throw err;
    }
}

export const getLocalUserById = async (localUserId : string) : Promise<LocalUserResponse> => {
    try {
        return await executeRequest(`${baseLocalUserAuthUrl}/${localUserId}`, RequestMethod.GET, undefined, null) as LocalUserResponse;
    }
    catch (err) {
        throw err;
    }
}
