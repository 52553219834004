import * as React from "react";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../i18n/SelectedLanguage";
import {QuarterPicker} from "./durationPicker/QuarterPicker";
import {Alert, Center, Flex, Select, VStack} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {Report} from "./enums/Report";
import {SidebarWithHeader} from "../SidebarWithHeader";
import {getStartRecordingDateForDate} from "../../helpers/ApplicationHelper";
import {IndicatorDataType} from "../../enums/IndicatorDataType";
import {getCurrentDateMoment, getStartOfQuarter, getStartOfYear} from "../../helpers/DateHelper";
import {YearPicker} from "./durationPicker/YearPicker";
import {Report1_Reporting} from "./reporting/Report1_Reporting";
import {Report2_Reporting} from "./reporting/Report2_Reporting";
import {Report1_Complementary} from "./complementary/Report1_Complementary";
import {Report1_USP} from "./usp/Report1_USP";
import {checkIfGlobalUserHasViewCrossMunicipalityPermission} from "../../utilities/LocalStorageUtil";
import {navigateToCompaniesPage} from "../../helpers/NavigationHelper";

export const CrossMunicipalityReports = (
    props: {

    }
): JSX.Element => {

    const [selectedReport, setSelectedReport] = React.useState<Report>();

    const [reportDuration, setReportDuration] = React.useState<moment.Moment>(moment(getStartRecordingDateForDate(getCurrentDateMoment().toDate(), IndicatorDataType.AURA_REPORTING)).subtract(1, 'quarter'));

    const navigate = useNavigate();

    React.useEffect(() => {
        if(!checkIfGlobalUserHasViewCrossMunicipalityPermission()) {
            navigateToCompaniesPage(navigate);
        }
    }, [])

    const { t, i18n } = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    return (
        <>
            <SidebarWithHeader
                isNonCompanySpecificPage={true}
            >
                <Center>
                    <Flex flexDirection={"column"} w={"100%"}>
                        <Flex>
                            <Select
                                value={selectedReport}
                                borderWidth={2}
                                borderColor='black'
                                onChange={(e : any) => {
                                    const reportSelected : Report = e.target.value;
                                    setSelectedReport(reportSelected)
                                    switch (reportSelected) {
                                        case Report.REPORT_1_REPORTING:
                                            setReportDuration(moment(getStartOfQuarter(getCurrentDateMoment().toDate())).subtract(1, 'quarter'))
                                            break;
                                        case Report.REPORT_2_REPORTING:
                                            setReportDuration(moment(getStartOfYear(getCurrentDateMoment().toDate())).subtract(1, "year"))
                                            break;
                                        case Report.REPORT_1_COMPLEMENTARY:
                                            setReportDuration(moment(getStartOfYear(getCurrentDateMoment().toDate())).subtract(1, "year"))
                                            break;
                                        case Report.REPORT_1_USP:
                                            setReportDuration(moment(getStartOfYear(getCurrentDateMoment().toDate())).subtract(1, "year"))
                                            break;
                                    }
                                }}
                                width={"-webkit-fill-available"}
                                placeholder={t('Select Cross Municipality Report')}
                                m={1}
                            >
                                <option value={Report.REPORT_1_REPORTING}>{t('AURA Reporting') } - {t('Quarterly Indicator Achievements of all municipalities')}</option>
                                <option value={Report.REPORT_2_REPORTING}>{t('AURA Reporting') } - {t('Annual Indicator Achievements of all municipalities')}</option>
                                <option value={Report.REPORT_1_COMPLEMENTARY}>{t('AURA Complementary') } - {t('Complimentary data of all municipalities')}</option>
                                <option value={Report.REPORT_1_USP}>{t('DNAAS USP') } - {t('USP data of all municipalities')}</option>
                            </Select>

                            {
                                selectedReport ? (
                                    selectedReport === Report.REPORT_1_REPORTING
                                        ? (
                                            <Center m={1}>
                                                <QuarterPicker
                                                    selectedDuration={reportDuration}
                                                    setSelectedDuration={setReportDuration}
                                                />
                                            </Center>
                                        )
                                        : selectedReport === Report.REPORT_2_REPORTING
                                            ? (
                                                <Center m={1}>
                                                    <YearPicker
                                                        selectedDuration={reportDuration}
                                                        setSelectedDuration={setReportDuration}
                                                    />
                                                </Center>
                                            ) : selectedReport === Report.REPORT_1_COMPLEMENTARY
                                                ? (
                                                    <Center m={1}>
                                                        <YearPicker
                                                            selectedDuration={reportDuration}
                                                            setSelectedDuration={setReportDuration}
                                                        />
                                                    </Center>
                                                ) : selectedReport === Report.REPORT_1_USP
                                                    ? (
                                                        <Center m={1}>
                                                            <YearPicker
                                                                selectedDuration={reportDuration}
                                                                setSelectedDuration={setReportDuration}
                                                            />
                                                        </Center>
                                                    ) : null
                                ) : null
                            }

                        </Flex>
                        {
                            selectedReport ? (
                                selectedReport === Report.REPORT_1_REPORTING
                                    ? (
                                        <Report1_Reporting reportDuration={reportDuration} />
                                    )
                                    : selectedReport === Report.REPORT_2_REPORTING
                                        ? (
                                            <Report2_Reporting reportDuration={reportDuration} />
                                        ) : selectedReport === Report.REPORT_1_COMPLEMENTARY
                                            ? (
                                                <Report1_Complementary reportDuration={reportDuration} />
                                            ) : selectedReport === Report.REPORT_1_USP
                                                ? (
                                                    <Report1_USP reportDuration={reportDuration} />
                                                ) : null
                            ) : (
                                <VStack h={"80vh"} justify={'center'}>
                                    <Alert w={"40%"}>{t("Please select a cross municipality report to view")}!</Alert>
                                </VStack>
                            )
                        }
                    </Flex>
                </Center>
            </SidebarWithHeader>
        </>
    );
}
