import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    useToast,
    FormLabel,
    Heading,
    Input, Stack, useBreakpointValue, HStack, Text, useColorModeValue, Checkbox
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import * as React from 'react'
import {useTranslation} from "react-i18next";
import {getCompanyDetails} from "../services/CompanyService";
import {setAuthInLocalStorage} from "../utilities/AuthUtil";
import {SignInResponse} from "../entities/response/SignInResponse";
import {signInUser} from "../services/GlobalUserService";
import {GlobalUserCompanyMembershipResponse} from "../entities/response/user/GlobalUserResponse";
import {
    setCompanyIdSelectedInLocalStorage,
    setCompanySelectedInLocalStorage,
    setGlobalUserInLocalStorage,
    setLocalUserIdIdSelectedInLocalStorage,
    setUserPermissionsForCompanyInLocalStorage,
} from "../utilities/LocalStorageUtil";
import {toastDuration} from "../constants/ApplicationConstants";
import {CompanyCreationStatus} from "../enums/CompanyCreationStatus";
import {navigateToCompaniesPage} from "../helpers/NavigationHelper";

export const Login = () => {
    const navigate = useNavigate();
    const [ password, setPassword ] = React.useState<string>('');
    const [ username, setUsername ] = React.useState<string>('');
    const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
    const toast = useToast();
    const { t } = useTranslation();

    const onLogin = async () => {
        setIsLoading(true);

        try {

            const signInRes : SignInResponse = await signInUser(username, password);
            setAuthInLocalStorage(signInRes);

            setGlobalUserInLocalStorage(signInRes.user)

            // single company membership
            if (signInRes?.user?.companyMemberships?.length === 1) {

                const companyMembership : GlobalUserCompanyMembershipResponse = signInRes?.user?.companyMemberships[0];

                setCompanyIdSelectedInLocalStorage(companyMembership.companyId)
                setLocalUserIdIdSelectedInLocalStorage(companyMembership.localUserId)


                await setUserPermissionsForCompanyInLocalStorage();

                const companyDetails = await getCompanyDetails(companyMembership.companyId);
                if(companyDetails.companyCreationStatus !== CompanyCreationStatus.COMPLETED) {

                    setCompanySelectedInLocalStorage(companyDetails)

                    setIsLoading(false);

                    toast({
                        title: `${t('Login Successful')}!`,
                        description: `${t('You have been signed in as')} ${username}`,
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    navigate('/indicators');
                }
            }
            // multi company membership
            setIsLoading(false);
            toast({
                title: `${t('Login Successful')}!`,
                description: `${t('You have been signed in as')} ${username}`,
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });
            navigateToCompaniesPage(navigate)
        } catch (err : any) {
            if(err) {
                if((err.message || '').includes('NotAuthorizedException')) {
                    toast({
                        title: `${t('Login Failed')}!`,
                        description: t('Incorrect username/password'),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                }
                else if((err.message || '').includes('UserNotConfirmedException')) {
                    toast({
                        title: `${t('Login Failed')}!`,
                        description: t('User is not confirmed'),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                }
                else {
                    toast({
                        title: `${t('Login Failed')}!`,
                        description: `${t(err?.message ? err?.message.toString() : `Sorry, we couldn't sign you in`)}!`,
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                }
            }
        }
        finally {
            setIsLoading(false);
            return;
        }
    }

    const handleUsernameChange = (event: { target: HTMLInputElement }) => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event: { target: HTMLInputElement }) => {
        setPassword(event.target.value);
    }

    return (
        <Flex bg='#f7fafc' w={"100%"} h={"100vh"}>
            <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                <Stack spacing="8">
                    <Stack spacing="6">
                        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                            <Heading size={useBreakpointValue({ base: 'l', md: 'xl' })}>
                                {t("Service Monitoring System")}
                            </Heading>
                            <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                                {t("Log in to your account")}
                            </Heading>
                            <HStack spacing="1" justify="center">
                                <Text color="muted">{t("Don't have an account?")}</Text>
                                <Button variant="link" colorScheme="blue" onClick={() => navigate('/signup')}>
                                    {t("Sign up")}
                                </Button>
                            </HStack>
                        </Stack>
                    </Stack>
                    <Box
                        py={{ base: '0', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg="white"
                        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                        <Stack spacing="6">
                            <Stack spacing="5">
                                <FormControl>
                                    <FormLabel htmlFor="email">{t("Email")}</FormLabel>
                                    <Input id="email" type="email" onChange={handleUsernameChange}/>
                                </FormControl>
                            </Stack>
                            <Stack spacing="5">
                                <FormControl>
                                    <FormLabel htmlFor="email">{t("Password")}</FormLabel>
                                    <Input id="password" type="password" onChange={handlePasswordChange}/>
                                </FormControl>
                            </Stack>
                            <HStack justify="space-between">
                                <Checkbox defaultIsChecked>{t("Remember me")}</Checkbox>
                                <Button variant="link" colorScheme="blue" size="sm" onClick={() => navigate('/forgotPassword')}>
                                    {t("Forgot password?")}
                                </Button>
                            </HStack>
                            <Stack spacing="6">
                                <Button isLoading={isLoading} colorScheme="blue" onClick={onLogin}>{t("Sign in")}</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </Flex>
    );
};
