import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {Box, Center} from "@chakra-ui/react";
import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import * as React from "react";
import {TableDataForPastNQuarters} from "../report3/TableDataForPastNQuarters";

export const TablesAllIndicators3YearAchievementData = (
    props: {
        chartName : string,
        indicatorDataEntries : Map<IndicatorResponse, IndicatorDataEntryResponse[]>,
        indicatorDataType : IndicatorDataType,
        reportDuration : moment.Moment,
        onChartImageURI: any,
    }
): JSX.Element => {
    const {
        chartName,
        indicatorDataEntries,
        indicatorDataType,
        reportDuration,
        onChartImageURI
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const yearsCount = 3;

    return (
        <>
            {
                Array.from(indicatorDataEntries.entries()).map(([indicatorResponse, indicatorDataEntriesResponses]) => {
                    return <Center>
                        <Box m={10} width={"100%"}>
                            <TableDataForPastNQuarters
                                chartName={`${indicatorResponse.name[selectedLanguage]} - ${chartName}`}
                                quarterCount={yearsCount * 4}
                                reportDuration={reportDuration}
                                indicatorDataEntries={indicatorDataEntriesResponses}
                                indicatorData={indicatorResponse}
                                indicatorDataType={indicatorDataType}
                                onChartImageURI={onChartImageURI}
                            />
                        </Box>
                    </Center>
                })
            }
        </>
    )
}
