import {MDBTable, MDBTableBody} from "mdb-react-ui-kit";
import {Button, Flex, Modal} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import Rating from "../Reliability";
import React, { Component } from "react";
import {displayValue} from "./DisplayValueHelper";

export const PreviewTable = (props: any) => {
    const { phaseRecord, finalSave, reject, currentPhaseIndex, indicatorDataType, columns } = props;
    const {t, i18n} = useTranslation();

    return <>
        <MDBTable bordered>
            <MDBTableBody>
                { phaseRecord ? Object.keys(phaseRecord).map((recordKey, idx) => {
                    const targetColumn = columns.find((c: any) => recordKey === c.name);
                    return <tr key={recordKey + idx}>
                        <td>{targetColumn.displayName[i18n.language]} - {targetColumn.description[i18n.language]}</td>
                        <td>
                            {
                                recordKey === 'reliability' ?
                                    <Rating
                                        ref={null}
                                        // @ts-ignore
                                        initialValue={phaseRecord[recordKey].value}
                                        viewOnly={true}
                                        size={28}
                                        icon="star"
                                        scale={3}
                                        fillColor="gold"
                                        strokeColor="grey"
                                    /> : displayValue(phaseRecord[recordKey], currentPhaseIndex, indicatorDataType, t).map(value => <span>{value} <br/></span>)
                            }
                        </td>
                    </tr>;
                }) : null }
            </MDBTableBody>
        </MDBTable>
        <Flex justifyContent={'center'}>
            <Button mr={4} colorScheme={"red"} onClick={reject}>{t("Discard")}</Button>
            <Button colorScheme={"green"} onClick={finalSave}>{t("Confirm")}</Button>
        </Flex>
    </>
}
