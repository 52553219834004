import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import moment from "moment";
import {ICompany} from "../../../../routes/CompaniesList";
import {Flex, Heading} from "@chakra-ui/react";
import {CellType, ColumnDataType, Table} from "../../GenericCharts/Table";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {computeFinalScore} from "../../utilities/ProcessData";
import {getLastPhaseForIndicatorEntry} from "../../../../helpers/ApplicationHelper";
import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import {getPerformanceColour} from "../../ReportsHelper";
import {IndicatorNumber} from "../../../../enums/IndicatorNumber";

export const TableYearlyIndicatorAchievementCompanyWise = (
    props: {
        chartName : string,
        indicatorDataEntriesForEachCompany : Map<ICompany, Map<IndicatorResponse, IndicatorDataEntryResponse[]> | null>,
        reportDuration : moment.Moment,
        onChartImageURI: any,
    }
): JSX.Element => {


    const {
        chartName,
        indicatorDataEntriesForEachCompany,
        reportDuration,
        onChartImageURI
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const allIndicatorMap : Map<IndicatorNumber, IndicatorResponse> = new Map<IndicatorNumber, IndicatorResponse>();

    const allYears : Set<number> = new Set<number>();

    Array.from(indicatorDataEntriesForEachCompany.values()).forEach(
        indicatorDataEntriesForCompany => {
            if (indicatorDataEntriesForCompany) {
                Array.from(indicatorDataEntriesForCompany.entries()).forEach(
                    ([indicatorResponse, indicatorDataEntries]) => {
                        if (!allIndicatorMap.has(indicatorResponse.indicatorNumber)) {
                            allIndicatorMap.set(indicatorResponse.indicatorNumber, indicatorResponse);
                        }
                        indicatorDataEntries.forEach(
                            indicatorDataEntry => allYears.add(moment(indicatorDataEntry.recordingDate).year())
                        )
                    }
                )
            }
        }
    )

    const allIndicators : IndicatorResponse[] = Array.from(allIndicatorMap.values());

    const chooseIndicatorEntryInYearEntries = (indicatorDataEntryResponsesForYear : IndicatorDataEntryResponse[]) : IndicatorDataEntryResponse => {
        // get only the latest indicator entry
        return indicatorDataEntryResponsesForYear
            .filter(indicatorDataEntry => getLastPhaseForIndicatorEntry(indicatorDataEntry) !== undefined)
            .sort(
                (entry1, entry2) =>
                    moment(entry1.recordingDate).isBefore(moment(entry2.recordingDate)) ? 1 : -1
            )[0];
    }

    const getAllLatestIndicatorEntriesForEachYear =
        (indicatorDataEntryResponses : IndicatorDataEntryResponse[]) : Map<number, IndicatorDataEntryResponse | null> => {
            const indicatorResponsesYearWise : Map<number, IndicatorDataEntryResponse[]> =
                indicatorDataEntryResponses
                    .reduce((prev, curr) => {
                        const indicatorEntryYear : number = moment(curr.recordingDate).year();
                        allYears.add(indicatorEntryYear);
                        if(prev.has(indicatorEntryYear)) {
                            // @ts-ignore
                            prev.get(indicatorEntryYear).push(curr)
                        }
                        else {
                            prev.set(indicatorEntryYear, [curr])
                        }
                        return prev;
                    }, new Map<number, IndicatorDataEntryResponse[]>())

            return Array.from(allYears).reduce(
                (prev, year) =>
                    prev.set(
                        year,
                        indicatorResponsesYearWise.get(year)
                            ? chooseIndicatorEntryInYearEntries(
                                // @ts-ignore
                                indicatorResponsesYearWise.get(year)
                            ) : null
                    ),
                new Map<number, IndicatorDataEntryResponse | null>()
            )
        }

    const getIndicatorDataEntryYearWiseForCompany =
        (indicatorDataEntriesForCompany : Map<IndicatorResponse, IndicatorDataEntryResponse[]> | null)
            : Map<IndicatorResponse, Map<number, IndicatorDataEntryResponse | null>> | null => {
        if(indicatorDataEntriesForCompany === null) return null;

        return Array.from(indicatorDataEntriesForCompany.entries()).reduce(
                (prev, [indicatorResponse, indicatorDataEntries]) =>
                    prev.set(indicatorResponse, getAllLatestIndicatorEntriesForEachYear(indicatorDataEntries))
                , new Map<IndicatorResponse, Map<number, IndicatorDataEntryResponse | null>>());
    }

    const indicatorEntryDataWithOnlyLatestValuesInYear : Map<ICompany, Map<IndicatorResponse, Map<number, IndicatorDataEntryResponse | null>> | null> =
        Array.from(indicatorDataEntriesForEachCompany.entries()).reduce(
            (prev, [companyDetails, indicatorDataEntriesForCompany]) =>
                prev.set(companyDetails, getIndicatorDataEntryYearWiseForCompany(indicatorDataEntriesForCompany)),
            new Map<ICompany, Map<IndicatorResponse, Map<number, IndicatorDataEntryResponse | null>> | null>()
        );

    const tableData : ColumnDataType[] = Array.from(indicatorEntryDataWithOnlyLatestValuesInYear.entries()).map(
        ([companyDetails, indicatorDataEntriesForCompany]) => {
            if(indicatorDataEntriesForCompany) {
                return {
                    header: companyDetails.companyName,
                    singleValues: undefined,
                    stars: Array.from(indicatorDataEntriesForCompany.entries()).flatMap(
                        ([indicatorResponse, indicatorDataEntryForEachYear]) =>
                            Array.from(indicatorDataEntryForEachYear.entries()).map(
                                ([year, indicatorDataEntry]) => {
                                    const lastPhaseData = indicatorDataEntry ? getLastPhaseForIndicatorEntry(indicatorDataEntry) : null;

                                    return lastPhaseData
                                        ? parseInt(
                                        lastPhaseData['reliability'].value || '0'
                                        ) : 0
                                }
                            )
                    ),
                    values : Array.from(indicatorDataEntriesForCompany.entries()).flatMap(
                        ([indicatorResponse, indicatorDataEntryForEachYear]) =>
                            Array.from(indicatorDataEntryForEachYear.entries()).map(
                                ([year, indicatorDataEntry]) => {

                                    const lastPhaseData = indicatorDataEntry ? getLastPhaseForIndicatorEntry(indicatorDataEntry) : null;

                                    return lastPhaseData
                                        ? computeFinalScore(lastPhaseData, indicatorResponse, IndicatorDataType.AURA_REPORTING).score
                                        : null;

                                }
                            )
                    ),
                    notApplicable : Array.from(indicatorDataEntriesForCompany.entries()).map(
                        ([indicatorResponse, indicatorDataEntryResponse]) =>
                            null
                    ),
                    notReported : Array.from(indicatorDataEntriesForCompany.entries()).map(
                        ([indicatorResponse, indicatorDataEntryResponse]) =>
                            null
                    ),
                    targets : Array.from(indicatorDataEntriesForCompany.entries()).flatMap(
                        ([indicatorResponse, indicatorDataEntryForEachYear]) =>
                            Array.from(indicatorDataEntryForEachYear.entries()).map(
                                ([year, indicatorDataEntry]) => {

                                    const lastPhaseData = indicatorDataEntry ? getLastPhaseForIndicatorEntry(indicatorDataEntry) : null;

                                    return lastPhaseData
                                        ? parseInt(lastPhaseData['target'].value || '100')
                                        : 100;
                                }
                            )
                    ),
                    coloringFunctions : Array.from(indicatorDataEntriesForCompany.entries()).flatMap(
                        ([indicatorResponse, indicatorDataEntryForEachYear]) =>
                            Array.from(indicatorDataEntryForEachYear.entries()).map(
                                ([year, indicatorDataEntry]) => {
                                    return (performanceScore : number) =>
                                        getPerformanceColour(performanceScore, indicatorResponse.indicatorNumber);
                                }
                            )
                    ),
                    iconFunction: null
                }
            }
            return {
                header: companyDetails.companyName,
                singleValues: undefined,
                stars: Object.values(IndicatorNumber).map(indicatorNumber => 0),
                values: Object.values(IndicatorNumber).map(indicatorNumber => null),
                notApplicable : [],
                notReported : [],
                targets: Object.values(IndicatorNumber).map(indicatorNumber => 100),
                coloringFunctions: [],
                iconFunction: null
            }
        }
    )

    const getTableColumnsFromSeriesData = (): ColumnDataType[] => {
        return [
            {
                header: t('Indicators'),
                singleValues:
                    allIndicators.flatMap(indicatorDetails => {
                        return [
                            {
                                value : indicatorDetails.name[selectedLanguage],
                                cellType : CellType.complex,
                                rowSpan : allYears.size,
                                colSpan : 0,
                            },
                            ...(new Array<string>(allYears.size - 1)).fill('row').map(
                                yearIndex => ({
                                    value : '',
                                    cellType : CellType.discard,
                                    rowSpan : 0,
                                    colSpan : 0,
                                })
                            )
                        ]
                    }),
                stars: [],
                values: [],
                notApplicable : [],
                notReported : [],
                targets: [],
                coloringFunctions: [],
                iconFunction: null
            },
            {
                header: 'Year',
                singleValues:
                    allIndicators.flatMap(indicatorDetails => Array.from(allYears).map(year => year)),
                stars: [],
                values: [],
                notApplicable : [],
                notReported : [],
                targets: [],
                coloringFunctions: [],
                iconFunction: null
            },
            ...tableData
        ];
    }

    return (
        <>
            <Flex flexDirection={"column"}>
                <Heading size={'md'} mb={5}>{chartName}</Heading>
                <Table
                    name={chartName}
                    tableId={chartName}
                    columns={getTableColumnsFromSeriesData()}
                    noOfRows={allIndicators.length * allYears.size}
                />
            </Flex>
        </>
    )
}
