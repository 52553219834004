import {ICompany} from "../routes/CompaniesList";
import {baseApiUrl, executeRequest, RequestMethod} from "./BaseService";
import {CompanyResponse} from "../entities/response/company/CompanyResponse";
import {CompanyCreateRequest} from "../entities/request/company/CompanyCreateRequest";
import {
    getLocalUserIdIdSelectedFromLocalStorage,
    setCompanySelectedInLocalStorage
} from "../utilities/LocalStorageUtil";

const baseCompaniesUrl = baseApiUrl + '/companies';

export const getCompanyDetails = async (companyId : string, localUserId?: string) : Promise<ICompany> => {
    try {
        const res : CompanyResponse = await executeRequest(`${baseCompaniesUrl}/${companyId}`, RequestMethod.GET, undefined,
        localUserId ?
            {
                'Authorization': `companyId=${companyId},localUserId=${localUserId}`
            } : null
        );

        return convertCompanyResponseToICompany(res, localUserId);
    }
    catch (err) {
        throw err;
    }
}

export const createCompany = async (companyCreateRequest : CompanyCreateRequest) : Promise<CompanyResponse> => {
    try {
        const res = await executeRequest(baseCompaniesUrl, RequestMethod.POST, companyCreateRequest, undefined);

        return Promise.resolve(res as CompanyResponse);
    }
    catch (err) {
        throw err;
    }
}

export const patchCompany = async (
    companyId : string, attr : any,
    localUserDetails? : {companyId : string, localUserId: string}
) : Promise<CompanyResponse> => {
    try {
        const res = await executeRequest(
            `${baseCompaniesUrl}/${companyId}`,
            RequestMethod.PATCH,
            attr,
            localUserDetails ?
                {
                    'Authorization': `companyId=${localUserDetails.companyId},localUserId=${localUserDetails.localUserId}`
                } : null
        );

        setCompanySelectedInLocalStorage(convertCompanyResponseToICompany(res));

        return Promise.resolve(res as CompanyResponse);
    }
    catch (err) {
        throw err;
    }
}

const convertCompanyResponseToICompany = (companyResponse : CompanyResponse, localUserId?: string) : ICompany => {
    return {
        companyId: companyResponse._id,
        localUserId: localUserId ? localUserId : getLocalUserIdIdSelectedFromLocalStorage(),
        companyName: companyResponse.name,
        companyCreationStatus: companyResponse.companyCreationStatus,
        companyDetails : companyResponse.details,
        companyMetaData : companyResponse.metaData,
        companyEnabled : companyResponse.enabled,
        companySampleDataAdded : companyResponse.sampleDataAdded,
    } as ICompany;
}
