import * as React from "react";
import './ViewReports.css';
import {IndicatorResponse} from "../entities/response/indicator/IndicatorResponse";
import {IndicatorDataType} from "../enums/IndicatorDataType";
import {Report3_Reporting} from "./reports/reporting/Report3_Reporting";
import {
    Badge,
    Box,
    Flex,
    Heading,
    VStack
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../i18n/SelectedLanguage";
import {QuarterPicker} from "./reports/durationPicker/QuarterPicker";
import moment from "moment";
import {getCurrentDateMoment, getStartOfQuarter} from "../helpers/DateHelper";

export const ViewIndicatorSpecificReports = (
    props: {
        indicator: IndicatorResponse,
        indicatorDataType: IndicatorDataType
    }
): JSX.Element => {
    const { indicator, indicatorDataType } = props;

    const [reportDuration, setReportDuration] = React.useState<moment.Moment>(moment(getStartOfQuarter(getCurrentDateMoment().toDate())).subtract(1, 'quarter'));

    const { t, i18n } = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    return (
        <>
            <Flex wrap={'wrap'} m={5} justifyContent={"space-between"}>
                <VStack alignItems={"start"}>
                    <Heading as='h5' size='l'>{indicator?.name[selectedLanguage]}</Heading>
                    <Box>
                        <Badge mr={1} colorScheme={'red'}>{IndicatorDataType.AURA_REPORTING.split("_")[0]}</Badge>
                        <Badge mr={1} colorScheme={'green'}>{t(IndicatorDataType.AURA_REPORTING.split("_")[1])}</Badge>
                    </Box>
                </VStack>
                {
                    <QuarterPicker selectedDuration={reportDuration} setSelectedDuration={setReportDuration} />
                }
            </Flex>

            {
                <Report3_Reporting indicatorId={indicator._id} reportDuration={reportDuration} />
            }
        </>
    );
};
