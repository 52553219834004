import React from "react";
import {checkUserLoggedIn} from "../utilities/AuthUtil";
import {getIndicatorById} from "../services/IndicatorService";
import {IndicatorResponse} from "../entities/response/indicator/IndicatorResponse";
import {useNavigate} from "react-router-dom";
import {ViewIndicatorSpecificReports} from "./ViewIndicatorSpecificReports";
import {IndicatorDataType} from "../enums/IndicatorDataType";
import {navigateToLoginPage} from "../helpers/NavigationHelper";

export const ViewReportsOuter = (): JSX.Element | null => {
    const navigate = useNavigate();
    const [indicator, setIndicator] = React.useState<IndicatorResponse>();

    React.useEffect(() => {
        const url = new URL(window.location.href);
        if (!checkUserLoggedIn()) {
            navigateToLoginPage(navigate)
        }

        const id = url.searchParams.get('id');

        if (id) {
            getIndicatorById(id)
                .then((indicatorResponse : IndicatorResponse) => {
                    setIndicator(indicatorResponse);
                }).catch(err => {
                navigate('/indicators');
            })
        } else {
            navigate('/indicators');
        }
    }, []);

    // This still needs to be integrated TODO
    return indicator ? <ViewIndicatorSpecificReports indicator={indicator} indicatorDataType={IndicatorDataType.AURA_REPORTING} /> : null;
}
