import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import moment from "moment";
import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {Alert, Box, Flex, Heading, VStack} from "@chakra-ui/react";
import * as React from "react";
import {BarChart, BarChartDataType} from "../../GenericCharts/BarChart";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {computeFinalScore} from "../../utilities/ProcessData";
import {getDisplayNameOfIndicatorNumber, getLastPhaseForIndicatorEntry} from "../../../../helpers/ApplicationHelper";
import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import {getPerformanceColour} from "../../ReportsHelper";
import {ICompany} from "../../../../routes/CompaniesList";
import {IndicatorNumber} from "../../../../enums/IndicatorNumber";

export const QuarterIndicatorAchievementColumnChartForAllCompanies = (
    props: {
        chartName : string,
        indicatorDataEntriesForEachCompany : Map<ICompany, Map<IndicatorResponse, IndicatorDataEntryResponse | null> | null>,
        reportDuration : moment.Moment,
        onChartImageURI: any,
    }
): JSX.Element => {

    const {
        chartName,
        indicatorDataEntriesForEachCompany,
        reportDuration,
        onChartImageURI
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const allIndicatorMap : Map<IndicatorNumber, IndicatorResponse> = new Map<IndicatorNumber, IndicatorResponse>();

    Array.from(indicatorDataEntriesForEachCompany.values()).forEach(
        indicatorDataEntriesForCompany => {
            if (indicatorDataEntriesForCompany) {
                Array.from(indicatorDataEntriesForCompany.keys()).forEach(
                    indicatorResponse => {
                        if (!allIndicatorMap.has(indicatorResponse.indicatorNumber)) {
                            allIndicatorMap.set(indicatorResponse.indicatorNumber, indicatorResponse);
                        }
                    }
                )
            }
        }
    )

    const indicatorEntriesDataForEachCompanyTransformed : Map<IndicatorNumber, Map<ICompany, IndicatorDataEntryResponse | null> | null> =
        Array.from(indicatorDataEntriesForEachCompany.entries()).reduce(
            (prev, [companyDetails, indicatorDataEntriesForCompany]) => {
                if(indicatorDataEntriesForCompany) {
                    Array.from(indicatorDataEntriesForCompany.entries()).forEach(
                        ([indicatorResponse, indicatorDataEntryForCompany]) => {
                            if(!prev.has(indicatorResponse.indicatorNumber)) {
                                prev.set(
                                    indicatorResponse.indicatorNumber,
                                    new Map<ICompany, IndicatorDataEntryResponse | null>()
                                );
                            }
                            prev.get(indicatorResponse.indicatorNumber)?.set(companyDetails, indicatorDataEntryForCompany);
                        }
                    )
                }
                return prev;
            },
            new Map<IndicatorNumber, Map<ICompany, IndicatorDataEntryResponse | null> | null>()
        )

    const getBarChartDataType = (
        indicatorResponse : IndicatorResponse,
        indicatorDataEntriesForEachCompany :  Map<ICompany, IndicatorDataEntryResponse | null>
    ) :  BarChartDataType[] => {
        return Array.from(indicatorDataEntriesForEachCompany.entries()).map(([companyDetails, indicatorDataEntry]) => {

            const lastPhaseData = indicatorDataEntry ? getLastPhaseForIndicatorEntry(indicatorDataEntry) : null;

            return {
                value : lastPhaseData ? computeFinalScore(lastPhaseData, indicatorResponse, IndicatorDataType.AURA_REPORTING).score : 0,
                target : lastPhaseData ? parseInt(lastPhaseData['target'].value || '100') : 0,
                stars : lastPhaseData ? parseInt(lastPhaseData['reliability'].value || '0') : 0,
                legendName: companyDetails.companyName,
                colouringFunction: (performanceScore : number) => getPerformanceColour(performanceScore, indicatorResponse.indicatorNumber),
                barName : companyDetails.companyName,
            }
        });
    }

    return (
        <>
            <Flex flexDirection={"column"}>

                {
                    Array.from(indicatorEntriesDataForEachCompanyTransformed.entries()).map(
                        ([indicatorNumber, indicatorDataEntriesForEachCompany]) => {
                            const indicatorResponse : IndicatorResponse | undefined = allIndicatorMap.get(indicatorNumber);
                            return (<>
                                <Box m={10} width={"100%"}>
                                    {
                                        indicatorResponse && indicatorDataEntriesForEachCompany ? (<>
                                            <BarChart
                                                getChartImageURI={onChartImageURI}
                                                chartId={'quarterIndicatorAchievementColumnChartForAllCompanies' + indicatorNumber}
                                                chartName={`${indicatorResponse?.name[selectedLanguage]} - ${chartName}`}
                                                yaxis={t('Points / Percentage')}
                                                data={getBarChartDataType(indicatorResponse, indicatorDataEntriesForEachCompany)} />
                                        </>) : (<>
                                            <VStack h={"80vh"} justify={'center'}>
                                                <Alert w={"40%"}>{`${t("No data found for indicator found")} - ${indicatorResponse?.name[selectedLanguage]}`}!</Alert>
                                            </VStack>
                                        </>)
                                    }

                                </Box>
                            </>)
                        }
                    )
                }
            </Flex>
        </>
    )
}
