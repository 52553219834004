import {IndicatorNumber} from "../../enums/IndicatorNumber";
import {IndicatorResponse} from "../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataType} from "../../enums/IndicatorDataType";

export const getPerformanceColour = (performanceScore : number, indicatorNumber : IndicatorNumber) => {
    switch (indicatorNumber) {
        case IndicatorNumber.INDICATOR_1:
            if(performanceScore < 50) {
                return getLowColour();
            }
            else if(performanceScore < 80) {
                return getMediumColour();
            }
            else return getHighColour();
        case IndicatorNumber.INDICATOR_2:
            if(performanceScore < 50) {
                return getLowColour();
            }
            else if(performanceScore < 80) {
                return getMediumColour();
            }
            else return getHighColour();
        case IndicatorNumber.INDICATOR_3:
            if(performanceScore < 60) {
                return getLowColour();
            }
            else if(performanceScore < 85) {
                return getMediumColour();
            }
            else return getHighColour();
        case IndicatorNumber.INDICATOR_4:
            if(performanceScore < 50) {
                return getLowColour();
            }
            else if(performanceScore < 80) {
                return getMediumColour();
            }
            else return getHighColour();
        case IndicatorNumber.INDICATOR_5:
            if(performanceScore < 50) {
                return getLowColour();
            }
            else if(performanceScore < 80) {
                return getMediumColour();
            }
            else return getHighColour();
        case IndicatorNumber.INDICATOR_6:
            if(performanceScore < 80) {
                return getLowColour();
            }
            else if(performanceScore < 100) {
                return getMediumColour();
            }
            else return getHighColour();
        case IndicatorNumber.INDICATOR_7:
            if(performanceScore < 70 || performanceScore >=120) {
                return getLowColour();
            }
            else if(performanceScore < 100 || performanceScore >=110) {
                return getMediumColour();
            }
            else return getHighColour();
        case IndicatorNumber.INDICATOR_8:
            if(performanceScore < 75) {
                return getLowColour();
            }
            else if(performanceScore < 90) {
                return getMediumColour();
            }
            else return getHighColour();
    }
}

export const getHighColour = () : string => {
    return "#b6d7a8";
}

export const getMediumColour = () : string => {
    return "#f9cb9c";
}

export const getLowColour = () : string => {
    return "#ea9a99";
}


export const getNoOfRowsForIndicator = (indicator: IndicatorResponse, indicatorDataType: IndicatorDataType): number => {
    return indicator.indicatorDataTypeStructureList[indicatorDataType]?.indicatorDataTypeEntryColumns.length ?? 0;
}
