import * as React from 'react';
import {useTranslation} from "react-i18next";
import {ICompany} from "../../../routes/CompaniesList";
import {IndicatorResponse} from "../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataEntryResponse} from "../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {FileType} from "../../../enums/FileType";
import {exportZip} from "../../../utilities/Export";
import {SelectedLanguage} from "../../../i18n/SelectedLanguage";
import moment from "moment/moment";
import {Alert, Box, Button, Center, Flex, Spinner, Text, VStack} from "@chakra-ui/react";
import {getUserCompanyMembershipsFromLocalStorage} from "../../../utilities/LocalStorageUtil";
import {getCompanyDetails} from "../../../services/CompanyService";
import {getAllIndicators} from "../../../services/IndicatorService";
import {filterIndicatorDataEntriesForIndicator} from "../../../services/IndicatorDataEntryService";
import {EntitySearch} from "../../../entities/request/EntitySearch";
import {FieldSearch} from "../../../entities/request/FieldSearch";
import {SearchTypes} from "../../../enums/SearchTypes";
import {IndicatorDataType} from "../../../enums/IndicatorDataType";
import {getStartRecordingDateForDate} from "../../../helpers/ApplicationHelper";
import {getEndOfYear, getYearDisplayValue} from "../../../helpers/DateHelper";
import {TableCompanyWise3YearLatestDataForIndicator} from "../common/TableCompanyWise3YearLatestDataForIndicator";
import {IndicatorNumber} from "../../../enums/IndicatorNumber";

export const Report1_Complementary = (
    props: {
        reportDuration : moment.Moment
    }
): JSX.Element => {

    const { reportDuration } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const [indicatorDataEntriesForEachCompany, setIndicatorDataEntriesForEachCompany] =
        React.useState<Map<ICompany, Map<IndicatorResponse, IndicatorDataEntryResponse[]> | null> | null>();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    React.useEffect(() => {

        setIndicatorDataEntriesForEachCompany(null);

        if(!reportDuration.isValid()) return;

        setIsLoading(true);

        Promise.all(
            getUserCompanyMembershipsFromLocalStorage().map( async (companyMembership) => {
                const companyId : string = companyMembership.companyId;
                const localUserId : string = companyMembership.localUserId;

                const companyDetails : ICompany = await getCompanyDetails(companyId, localUserId);

                const indicatorResponses : IndicatorResponse[] =
                    (await getAllIndicators(companyId, localUserId)).filter(indicatorResponse => indicatorResponse.indicatorNumber !== IndicatorNumber.INDICATOR_6);

                const indicatorDataEntriesForIndicators : {
                    indicator : IndicatorResponse,
                    indicatorDataEntries : IndicatorDataEntryResponse[]
                }[] = await Promise.all(
                    indicatorResponses.map( async (indicatorResponse) => {

                        const indicatorDataEntriesForIndicator: IndicatorDataEntryResponse[] = await filterIndicatorDataEntriesForIndicator(
                            indicatorResponse.indicatorNumber,
                            new EntitySearch([
                                new FieldSearch(
                                    SearchTypes.VALUE_EQUAL,
                                    'indicatorNumber',
                                    indicatorResponse.indicatorNumber
                                ),
                                new FieldSearch(
                                    SearchTypes.VALUE_EQUAL,
                                    'indicatorDataType',
                                    IndicatorDataType.AURA_COMPLEMENTARY
                                ),
                                new FieldSearch(
                                    SearchTypes.VALUE_GREATER_THAN_EQUAL_TO,
                                    'recordingDate',
                                    getStartRecordingDateForDate(
                                        moment(reportDuration).subtract(2, 'year').toDate(), IndicatorDataType.AURA_COMPLEMENTARY
                                    )
                                ),
                                new FieldSearch(
                                    SearchTypes.VALUE_LESS_THAN_EQUAL_TO,
                                    'recordingDate',
                                    moment(getEndOfYear(reportDuration.toDate()))
                                )
                            ]),
                            {companyId, localUserId}
                        );

                        return {
                            indicator : indicatorResponse,
                            indicatorDataEntries: indicatorDataEntriesForIndicator
                        }
                    })
                )

                if(
                    indicatorDataEntriesForIndicators.some(
                        indicatorDataEntriesForIndicator =>
                            indicatorDataEntriesForIndicator.indicatorDataEntries.length > 0 && indicatorDataEntriesForIndicator.indicatorDataEntries.some(
                                indicatorDataEntryForIndicator => moment(indicatorDataEntryForIndicator.recordingDate).year() === reportDuration.year()
                            )
                    )
                ) {
                    return {
                        companyDetails,
                        indicatorsDataEntries :
                            indicatorDataEntriesForIndicators.reduce(
                                (prev, curr) => prev.set(curr.indicator, curr.indicatorDataEntries),
                                new Map()
                            )
                    };
                }

                return {
                    companyDetails,
                    indicatorsDataEntries : null
                };
            })
        ).then(
            (
                indicatorsDataEntriesForCompanies : {
                    companyDetails : ICompany,
                    indicatorsDataEntries : Map<IndicatorResponse, IndicatorDataEntryResponse[]> | null
                }[]
            ) => {
                if(indicatorsDataEntriesForCompanies.some(
                    indicatorsDataEntriesForCompany => indicatorsDataEntriesForCompany.indicatorsDataEntries !== null)
                ) {
                    setIndicatorDataEntriesForEachCompany(
                        indicatorsDataEntriesForCompanies.reduce(
                            (prev, curr) => prev.set(curr.companyDetails, curr.indicatorsDataEntries),
                            new Map<ICompany, Map<IndicatorResponse, IndicatorDataEntryResponse[]> | null>()
                        )
                    )
                }
                setIsLoading(false)
            }
        )
    }, [reportDuration]);

    const imageZipConfigs = React.useRef<{id: string; name: string; type: FileType, data: any}[]>([]);

    const onChartImageURI = (chartId: string, chartName: string, data: any) => {
        imageZipConfigs.current = imageZipConfigs.current.filter(elem => elem.id !== chartId);
        imageZipConfigs.current.push({
            id: chartId,
            name: chartName,
            data: data.imgURI,
            type: FileType.image
        });
    };

    const _exportZip = React.useCallback(() => {
        const tablesZipConfig = [
            {
                id: "report2",
                name: t("Table for all Indicators comparing all 5 Municipalities"),
                type: FileType.excel
            },
            ...imageZipConfigs.current
        ];
        exportZip(tablesZipConfig, t("Table for all Indicators comparing all 5 Municipalities"));
    }, [imageZipConfigs]);

    return (
       <>
           {
               isLoading ? (
                   <>
                       <VStack h={"80vh"} justify={'center'}>
                           <Spinner size='xl' />
                           <Text>{t("Loading report, please wait")}!</Text>
                       </VStack>
                   </>
               ) : (
                   <>
                       {
                           reportDuration.isValid() ? (
                               <>
                                   {
                                       indicatorDataEntriesForEachCompany ? (
                                           <>
                                               <Flex flexDirection={"column"}>
                                                   <Button mt="2" width={"fit-content"} alignSelf={"end"} colorScheme={'green'} onClick={_exportZip}>{t("Export ZIP")}</Button>
                                               </Flex>

                                               <Center>
                                                   <Box m={10} width={"80%"}>
                                                       <TableCompanyWise3YearLatestDataForIndicator
                                                           chartName={`${getYearDisplayValue(reportDuration)} - ${t("Complementary Data For Every Municipality")}`}
                                                           indicatorDataEntriesForEachCompany={indicatorDataEntriesForEachCompany}
                                                           indicatorDataType={IndicatorDataType.AURA_COMPLEMENTARY}
                                                           reportDuration={reportDuration}
                                                           onChartImageURI={onChartImageURI}
                                                       />
                                                   </Box>
                                               </Center>
                                           </>
                                       ) : (
                                           <VStack h={"80vh"} justify={'center'}>
                                               <Alert w={"40%"}>{t("No data found for this duration")}!</Alert>
                                           </VStack>
                                       )
                                   }
                               </>
                           ) : (
                               <VStack h={"80vh"} justify={'center'}>
                                   <Alert w={"40%"}>{t("Please select report duration")}!</Alert>
                               </VStack>
                           )
                       }
                   </>
               )
           }
       </>
    );
};
