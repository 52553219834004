import moment from 'moment';

export const getCurrentDateMoment = () : moment.Moment => {
    return moment();
}

export const getStartOfQuarter = (date : Date) : Date => {
    return moment(date).startOf('quarter').toDate();
}

export const getEndOfQuarter = (date : Date) : Date => {
    return moment(date).endOf('quarter').toDate();
}

export const getStartOfSemester = (date : Date) : Date => {
    const dateMoment = moment(date);
    if(dateMoment.quarter() % 2 === 1) {
        return dateMoment.startOf('quarter').toDate()
    }
    return moment(date).subtract(1, 'quarter').startOf('quarter').toDate();
}

export const getEndOfSemester = (date : Date) : Date => {
    const dateMoment = moment(date);
    if(dateMoment.quarter() % 2 === 0) {
        return dateMoment.endOf('quarter').toDate()
    }
    return moment(date).add(1, 'quarter').endOf('quarter').toDate();
}

export const getStartOfYear = (date : Date) : Date => {
    return moment(date).startOf('year').toDate();
}

export const getEndOfYear = (date : Date) : Date => {
    return moment(date).endOf('year').toDate();
}

export const getQuarter = (date : Date) : number => {
    return moment(date).quarter()
}

export const getSemester = (date : Date) : number => {
    return Math.floor(moment(date).quarter()/2) + 1;
}

export const getYear = (date : Date) : number => {
    return moment(date).year()
}

export const getYearQuarterCount = (reportDuration: moment.Moment, quarterCount: number) : {[key : number]: number} => {
    const dateMoment = moment(reportDuration);

    const yearGroupCount : {[key : number]: number} = {};

    while(quarterCount-- > 0) {

        const year = dateMoment.year();

        dateMoment.subtract(1, 'quarter');

        if(yearGroupCount[year]) {
            yearGroupCount[year]++;
        }
        else {
            yearGroupCount[year] = 1;
        }
    }

    return yearGroupCount;
}

export const getYearSemesterCount = (reportDuration: moment.Moment, semesterCount: number) : {[key : number]: number} => {
    const dateMoment = moment(reportDuration);

    const yearGroupCount : {[key : number]: number} = {};

    while(semesterCount-- > 0) {

        const year = dateMoment.year();

        dateMoment.subtract(2, 'quarter');

        if(yearGroupCount[year]) {
            yearGroupCount[year]++;
        }
        else {
            yearGroupCount[year] = 1;
        }
    }

    return yearGroupCount;
}

export const getYears = (reportDuration: moment.Moment, yearCount: number) : number[] => {
    const dateMoment = moment(reportDuration);

    const years = [];

    while(yearCount--) {
        years.push(dateMoment.year())
        dateMoment.subtract(1, 'year');
    }

    return years.reverse();
}


export const getQuarterDisplayValue = (optionMoment : moment.Moment) : string => {
    return `Q${getQuarter(optionMoment.toDate())} - ${getYear(optionMoment.toDate())}`;
}


export const getSemesterDisplayValue = (optionMoment : moment.Moment) : string => {
    return `S${getSemester(optionMoment.toDate())} - ${getYear(optionMoment.toDate())}`;
}

export const getYearDisplayValue = (optionMoment : moment.Moment) : string => {
    return `${getYear(optionMoment.toDate())}`;
}


