import {set_cptable} from "xlsx";
import * as cptable from 'xlsx';
import JSZip from "jszip";
import {saveAs} from "save-as";
import {FileType} from "../enums/FileType";
import ApexCharts from "apexcharts";

export function tableToCSV(tableId, name) {
    // Variable to store the final csv data
    let csv_data = [];

    // Get each row data
    const rows = document.getElementById(tableId).getElementsByTagName('tr');
    for (let i = 0; i < rows.length; i++) {

        // Get each column data
        const cols = rows[i].querySelectorAll('td,th');

        // Stores each csv row data
        let csvrow = [];
        for (var j = 0; j < cols.length; j++) {

            // Get the text data of each cell of
            // a row and push it to csvrow
            csvrow.push(cols[j].innerHTML);
        }

        // Combine each column value with comma
        csv_data.push(csvrow.join(","));
    }
    // combine each row data with new line character
    csv_data = csv_data.join('\n');

    /* We will use this function later to download
    the data in a csv file downloadCSVFile(csv_data);
    */
    downloadCSVFile(csv_data, name);
}

export function ExportToExcel(id, type, fn, dl) {
    set_cptable(cptable);
    var elt = document.getElementById(id).getElementsByTagName('table')[0];
    var wb = cptable.utils.table_to_book(elt, { sheet: "sheet1" });
    return dl ?
        cptable.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
        cptable.writeFile(wb, fn || ('MySheetName.' + (type || 'xlsx')));
}

export const exportZip = async (tablesZipConfig, zipName) => {
    const zip = new JSZip();
    const files = tablesZipConfig.map(async config => {
        if (config.type === FileType.excel) {
            const data = getExcelData(config.id);
            zip.file(config.name + '.xlsx', data);
            return 'zipped';
        } else {
            zip.file(config.name + '.png', config.data.replace(/^data:image\/(png|jpg);base64,/, ""), { base64: true });
            return 'zipped';
        }
    });
    await Promise.all(files);
    zip.generateAsync({type:"blob"}).then(function(content) {
        saveAs(content, `${zipName ?? 'reports'}.zip`);
    });
}

export function getExcelData(id) {
    set_cptable(cptable);
    var elt = document.getElementById(id).getElementsByTagName('table')[0];
    var wb = cptable.utils.table_to_book(elt, { sheet: "sheet1" });

    return cptable.writeXLSX(wb, { type: 'buffer' });
}

export function downloadCSVFile(csv_data, name) {
    // Create CSV file object and feed our
    // csv_data into it
    const CSVFile = new Blob([csv_data], { type: "text/csv" });

    // Create to temporary link to initiate
    // download process
    const temp_link = document.createElement('a');

    // Download csv file
    temp_link.download = name + ".csv";
    const url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
}