import {Response} from '../Response';
import {FieldTypes} from '../../../enums/FieldTypes';
import {IndicatorDataType} from '../../../enums/IndicatorDataType';
import {SecurityGroupResponse} from '../securityGroup/SecurityGroupResponse';
import {IndicatorNumber} from "../../../enums/IndicatorNumber";


export interface LocaleString {
    en: string;
    pt: string;
}

export interface IndicatorResponse extends Response {
    name: LocaleString;

    indicatorNumber: IndicatorNumber;

    description: LocaleString;

    access: {
        editors: string[],
        readers: string[]
    }

    fields: [FieldResponse];

    indicatorDataTypeStructureList: {[key in IndicatorDataType]: IndicatorDataTypeStructureResponse};
}

export interface IndicatorDataTypeStructureResponse {
    indicatorDataType: IndicatorDataType;

    reportingFrequencyInDays: number;
    currentPhaseIndex: number

    indicatorDataTypeEntryColumns: IndicatorDataTypeEntryColumnResponse[];
    phaseSequence: IndicatorDataEntryPhaseStructureResponse[];
}

export enum IndicatorDataTypeEntryColumnDataType {
    LIST = 'LIST',
    NUMBER = 'NUMBER',
    STRING = 'STRING'
}

export interface IndicatorDataTypeEntryColumnListValuesResponse {
    columnDataType : IndicatorDataTypeEntryColumnDataType
    values : string[]
}

export interface IndicatorDataTypeEntryColumnNumberValueResponse {
    columnDataType : IndicatorDataTypeEntryColumnDataType
    gte : number;
    lte : number;
}

export interface IndicatorDataTypeEntryColumnStringValueResponse {
    columnDataType : IndicatorDataTypeEntryColumnDataType
}

export interface IndicatorDataTypeEntryColumnListPointingRuleResponse {
    points : number;
    value : string;
}

export interface IndicatorDataTypeEntryColumnNumberPointingRuleResponse {
    points : number;
    lte : number;
    gte : number;
}

export interface IndicatorDataTypeEntryColumnStringPointingRuleResponse {
    points : number;
    value : string;
}

export interface IndicatorDataTypeEntryColumnResponse {
    displayName: LocaleString;
    name: string;
    defaultValue: string;
    description: LocaleString;
    additionalInformation: LocaleString;
    indicatorDataType: IndicatorDataType;
    columnName: string;
    notApplicableSupported : string[]; // phases where NA supported
    notReportedSupported : string[]; // phases where NR supported
    unit: {en: string, pt: string};
    dataValidation : IndicatorDataTypeEntryColumnListValuesResponse | IndicatorDataTypeEntryColumnNumberValueResponse | IndicatorDataTypeEntryColumnStringValueResponse;
    pointingRules : IndicatorDataTypeEntryColumnListPointingRuleResponse[] | IndicatorDataTypeEntryColumnNumberPointingRuleResponse[] | IndicatorDataTypeEntryColumnStringPointingRuleResponse[]
}

export interface IndicatorDataEntryPhaseStructureResponse {
    name: string;
    displayName: LocaleString;
    supportedDataInputs : IndicatorDataEntryPhaseSupportedDataInputsResponse;
    securityGroups: SecurityGroupResponse[];
}

export interface IndicatorDataEntryPhaseSupportedDataInputsResponse {
    value : boolean;
    attachmentPaths : boolean;
    notes : boolean;
    additionalNotes : boolean;
}

export interface FieldResponse {
    _id: number;
    name: string;
    type: FieldTypes;
    ownerId: number;
}

export interface EmailResponse {
    to: [string];
    subject:  string;
    from:  string;
    body:  string;
}

// tslint:enable: max-classes-per-file
