import * as React from "react";
import {InputAttributes} from "react-digit-input";

export const DigitInputElement = React.forwardRef<HTMLInputElement,
    Omit<InputAttributes, 'ref'> & {
    autoFocus?: boolean;
}>(({...props}, ref) => {
    return (
        <label>
            <input {...props} ref={ref} inputMode="decimal"/>
        </label>
    );
});