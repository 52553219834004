import {Container} from "react-bootstrap";
import {Alert, Center, SimpleGrid, Spinner, Text, useToast, VStack} from "@chakra-ui/react";
import React from "react";
import {IndicatorCard} from "./IndicatorCard";
import {useNavigate} from "react-router-dom";
import {IndicatorResponse} from "../entities/response/indicator/IndicatorResponse";
import {getAllIndicators} from "../services/IndicatorService";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../i18n/SelectedLanguage";
import {toastDuration} from "../constants/ApplicationConstants";
import {AddSampleData} from "./AddSampleData";
import {checkIfUserHasCompanyLevelPermissions} from "../helpers/PermissionsHelper";
import {CompanyLevelActions} from "../enums/featureActions/CompanyLevelActions";
import {
    checkCompanyStorageVariablesElseNavigate,
} from "../helpers/NavigationHelper";

export const IndicatorList = () => {
    const toast = useToast();
    const {i18n,t} = useTranslation();
    const [indicators, setIndicators] = React.useState<IndicatorResponse[]>();
    const [refresh, setRefresh] = React.useState<boolean>(true);
    const navigate = useNavigate();
    const selectedLanguage = i18n.language as SelectedLanguage;


    React.useEffect(() => {

        checkCompanyStorageVariablesElseNavigate(navigate);

        if(refresh) {
            if(checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.LIST_INDICATORS)) {
                getAllIndicators()
                    .then((indicatorsResponse : IndicatorResponse[]) => {
                        setIndicators(indicatorsResponse);
                        setRefresh(false);
                    })
            }
        }
    }, [refresh]);

    const openIndicator = (indicatorResponse: IndicatorResponse) => {
        if (indicatorResponse) {

            toast({
                title: t(`Indicator selected`),
                description: `${indicatorResponse.name[selectedLanguage]}`,
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });

            const url = new URL('/indicators/details', window.location.href);

            url.searchParams.set('id', indicatorResponse._id);

            navigate({
                pathname: '/indicators/details',
                search: `?id=${indicatorResponse._id}`
            });
        }
        else {
            toast({
                title: t(`Indicator couldn't be selected`),
                description: '',
                status: 'error',
                duration: toastDuration,
                isClosable: true,
            });
        }
    };

    return(
        <Container>
            {
                checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.LIST_INDICATORS) ? (<>
                    <AddSampleData />
                    {
                        indicators ? null : (
                            <Center height={400}>
                                <Text mr={10}>{t("Loading Indicators")}</Text>
                                <Spinner />
                            </Center>
                        )
                    }
                    <SimpleGrid m={5} columns={{ sm: 1, md: 1, lg: 2, xl: 3}} spacing={10}>
                        {
                            indicators?.map((indicator: IndicatorResponse, idx: number) => {
                                return (
                                    <IndicatorCard
                                        indicator={indicator}
                                        onClick={openIndicator}
                                        key={`indicator-${idx}`}
                                    />
                                );
                            })
                        }
                    </SimpleGrid>
                </>) : (<>
                    <VStack h={"80vh"} justify={'center'}>
                        <Alert w={"40%"}>{t("Insufficient permissions")}!</Alert>
                    </VStack>
                </>)
            }
        </Container>
    );
};
