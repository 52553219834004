import * as React from 'react';
import {Alert, Box, Button, Center, Flex, Skeleton, Spinner, Text, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {getIndicatorById} from "../../../services/IndicatorService";
import {
    filterIndicatorDataEntriesForIndicator,
} from "../../../services/IndicatorDataEntryService";
import {IndicatorDataType} from "../../../enums/IndicatorDataType";
import {FileType} from "../../../enums/FileType";
import {exportZip} from "../../../utilities/Export";
import {IndicatorResponse} from "../../../entities/response/indicator/IndicatorResponse";
import {SelectedLanguage} from "../../../i18n/SelectedLanguage";
import moment from "moment/moment";
import {EntitySearch} from "../../../entities/request/EntitySearch";
import {FieldSearch} from "../../../entities/request/FieldSearch";
import {SearchTypes} from "../../../enums/SearchTypes";
import {
    getEndRecordingDateForDate,
    getLastPhaseForIndicatorEntry,
    getStartRecordingDateForDate
} from "../../../helpers/ApplicationHelper";
import {IndicatorDataEntryResponse} from "../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {TargetAchievementSemiDonutChart} from "./report3/TargetAchievementSemiDonutChart";
import {AchievementColumnChartForPast8Quarters} from "./report3/AchievementColumnChartForPast8Quarters";
import {TableDataForPastNQuarters} from "./report3/TableDataForPastNQuarters";
import {getQuarter, getQuarterDisplayValue, getYear} from "../../../helpers/DateHelper";

export const Report3_Reporting = (
    props: {
        indicatorId: string,
        reportDuration : moment.Moment
    }
): JSX.Element => {
    const { indicatorId, reportDuration } = props;
    const {t, i18n} = useTranslation();
    const [indicatorData, setIndicatorData] = React.useState<IndicatorResponse>();

    const [indicatorDataEntry, setIndicatorDataEntry] = React.useState<IndicatorDataEntryResponse | null>(null);

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const imageZipConfigs = React.useRef<{id: string; name: string; type: FileType, data: any}[]>([]);

    const selectedLanguage = i18n.language as SelectedLanguage;

    React.useEffect(() => {
        setIndicatorDataEntry(null);

        if(!reportDuration.isValid()) return;

        setIsLoading(true);

        getIndicatorById(indicatorId).then(indicatorResponse => {
            setIndicatorData(indicatorResponse);

            filterIndicatorDataEntriesForIndicator(
                indicatorResponse.indicatorNumber,
                new EntitySearch([
                    new FieldSearch(
                        SearchTypes.VALUE_EQUAL,
                        'indicatorNumber',
                        indicatorResponse.indicatorNumber
                    ),
                    new FieldSearch(
                        SearchTypes.VALUE_EQUAL,
                        'indicatorDataType',
                        IndicatorDataType.AURA_REPORTING
                    ),
                    new FieldSearch(
                        SearchTypes.VALUE_GREATER_THAN_EQUAL_TO,
                        'recordingDate',
                        getStartRecordingDateForDate(
                            reportDuration.toDate(), IndicatorDataType.AURA_REPORTING
                        )
                    ),
                    new FieldSearch(
                        SearchTypes.VALUE_LESS_THAN_EQUAL_TO,
                        'recordingDate',
                        getEndRecordingDateForDate(
                            reportDuration.toDate(), IndicatorDataType.AURA_REPORTING
                        )
                    )
                ])
            ).then(
                async (indicatorDataEntryResponses : IndicatorDataEntryResponse[]) => {
                    if(
                        indicatorDataEntryResponses
                        && indicatorDataEntryResponses[0]
                        && indicatorDataEntryResponses[0].approvalProcessDataMap
                        && getLastPhaseForIndicatorEntry(indicatorDataEntryResponses[0])
                    ) {
                        setIndicatorDataEntry(indicatorDataEntryResponses[0]);
                    }
                    setIsLoading(false);
                }
            )

        })
    }, [
        indicatorId,
        reportDuration
    ]);

    const _exportZip = React.useCallback(() => {
        const tablesZipConfig = [
            {
                name: t("Table Representing Indicator Fields for past 2 years"),
                id: indicatorData?._id + "report3",
                type: FileType.excel
            },
            ...imageZipConfigs.current
        ];
        exportZip(tablesZipConfig, t("Table for all Indicators comparing all 5 Municipalities"));
        exportZip(tablesZipConfig);
    }, [imageZipConfigs]);

    const onChartImageURI = (chartId: string, chartName: string, data: any) => {
        imageZipConfigs.current = imageZipConfigs.current.filter(elem => elem.id !== chartId);
        imageZipConfigs.current.push({
            id: chartId,
            name: chartName,
            data: data.imgURI,
            type: FileType.image
        });
    };

    return (
        <>
            {
                isLoading ? (
                    <>
                        <VStack h={"80vh"} justify={'center'}>
                            <Spinner size='xl' />
                            <Text>{t("Loading report, please wait")}!</Text>
                        </VStack>
                    </>
                ) : (
                    <>
                        {
                            reportDuration.isValid() ? (
                                <>
                                    {
                                        indicatorData && indicatorDataEntry ? (
                                            <>
                                                <Flex flexDirection={"column"}>
                                                    <Button mt="2" width={"fit-content"} alignSelf={"end"} colorScheme={'green'} onClick={_exportZip}>{t("Export ZIP")}</Button>
                                                </Flex>

                                                <Center>
                                                    <Box m={10} width={"80%"}>
                                                        <TargetAchievementSemiDonutChart
                                                            chartName={`${getQuarterDisplayValue(reportDuration)} ${t("Achievement")}`}
                                                            indicatorDataEntry={indicatorDataEntry}
                                                            indicatorData={indicatorData}
                                                            indicatorDataType={IndicatorDataType.AURA_REPORTING}
                                                            onChartImageURI={onChartImageURI}
                                                        />
                                                    </Box>
                                                </Center>
                                                <Center>
                                                    <Box m={10} width={"80%"}>
                                                        <AchievementColumnChartForPast8Quarters
                                                            chartName={t("Achievement for last 2 years")}
                                                            reportDuration={reportDuration}
                                                            indicatorDataEntry={indicatorDataEntry}
                                                            indicatorData={indicatorData}
                                                            indicatorDataType={IndicatorDataType.AURA_REPORTING}
                                                            onChartImageURI={onChartImageURI}
                                                        />
                                                    </Box>
                                                </Center>
                                                <Center>
                                                    <Box m={10} width={"80%"}>
                                                        <TableDataForPastNQuarters
                                                            chartName={t("Reporting data for past 2 years")}
                                                            quarterCount={8}
                                                            reportDuration={reportDuration}
                                                            indicatorDataEntry={indicatorDataEntry}
                                                            indicatorData={indicatorData}
                                                            indicatorDataType={IndicatorDataType.AURA_REPORTING}
                                                            onChartImageURI={onChartImageURI}
                                                        />
                                                    </Box>
                                                </Center>
                                            </>
                                        ) : (
                                            <VStack h={"80vh"} justify={'center'}>
                                                <Alert w={"40%"}>{t("No data found for this duration")}!</Alert>
                                            </VStack>
                                        )
                                    }
                                </>
                            ): (
                                <VStack h={"80vh"} justify={'center'}>
                                    <Alert w={"40%"}>{t("Please select report duration")}!</Alert>
                                </VStack>
                            )
                        }
                    </>
                )
            }
        </>
    );
};
