import {
    Button, Link,
    Popover,
    PopoverArrow, PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger
} from "@chakra-ui/react";
import {AttachmentIcon} from "@chakra-ui/icons";

export interface AttachmentPath {
    Location: string;
    Key: string;
}

export const AttachmentViewPopover = (props: { attachmentPaths: AttachmentPath[] }) => {
    const { attachmentPaths } = props;
    return (
        <Popover>
            <PopoverTrigger>
                <AttachmentIcon ml={2} color={'red'} />
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>{attachmentPaths.length} Attachments</PopoverHeader>
                <PopoverBody>
                    {
                        attachmentPaths.map(attachmentPath => <Link
                            className={'attachmentLink'} href={attachmentPath.Location} isExternal>
                            {attachmentPath.Key}
                        </Link>)
                    }
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}