import {Select} from "@chakra-ui/react";
import moment from "moment";
import {dateEntryRecordingStartDate} from "../../../constants/ApplicationConstants";
import {getCurrentDateMoment, getYear, getYearDisplayValue} from "../../../helpers/DateHelper";

export const YearPicker = (props: any) => {
    const {
        selectedDuration,
        setSelectedDuration
    } = props;

    const yearMoments = [];

    let dateMoment = moment(dateEntryRecordingStartDate);

    while(dateMoment.isBefore(getCurrentDateMoment().subtract(1, 'year'))) {
        yearMoments.push(moment(dateMoment))
        dateMoment = dateMoment.add(1, 'years')
    }

    yearMoments.reverse();

    return (
        <>
            <Select
                value={selectedDuration.toISOString()}
                width={"fit-content"}
                borderWidth={2}
                borderColor='black'
                placeholder='Select Year'
                onChange={(e) => setSelectedDuration(moment(e.target.value))}
            >
                {
                    yearMoments.map(optionMoment =>
                        <option value={optionMoment.toISOString()}>{getYearDisplayValue(optionMoment)}</option>
                    )
                }
            </Select>
        </>
    );
}
