import React from "react";
import {
    Alert,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select, Spinner, Text,
    useDisclosure,
    useToast, VStack
} from "@chakra-ui/react";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {checkUserLoggedIn} from "../utilities/AuthUtil";
import {GlobalUserResponse} from "../entities/response/user/GlobalUserResponse";
import {getAllLocalUsersInCompany} from "../services/LocalUserService";
import {LocalUserResponse} from "../entities/response/user/LocalUserResponse";
import {getGlobalUserById} from "../services/GlobalUserService";
import {inviteUser} from "../services/UserInviteService";
import {UserInviteResponse} from "../entities/response/userInvite/UserInviteResponse";
import {getCompanyIdSelectedFromLocalStorage} from "../utilities/LocalStorageUtil";
import {getAllUserGroups} from "../services/UserGroupService";
import {UserGroupResponse} from "../entities/response/userGroup/UserGroupResponse";
import {UserGroupType} from "../enums/UserGroupType";
import {toastDuration} from "../constants/ApplicationConstants";
import {navigateToLoginPage} from "../helpers/NavigationHelper";

export const ManageLocalUsers = (props: { isActive: boolean }) => {
    const { isActive } = props;
    const [refresh, setRefresh] = React.useState<boolean>(true);
    const toast = useToast();
    const [usersDetails, setUsersDetails] = React.useState<GlobalUserResponse[] | null>();
    const [userGroups, setUserGroups] = React.useState<UserGroupResponse[] | null>();
    const [userGroupSelectedForInvitation, setUserGroupSelectedForInvitation] = React.useState<string>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [inviteEmail, setInviteEmail] = React.useState<string>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (!checkUserLoggedIn()) {
            navigateToLoginPage(navigate)
        }
    }, []);

    React.useEffect(() => {
        getAllUserGroups(getCompanyIdSelectedFromLocalStorage() ?? undefined)
            .then(userGroupsResponse => {
                setUserGroups(userGroupsResponse);
            });
    }, []);

    React.useEffect(() => {
        if (isActive && refresh) {
            try {
                getAllLocalUsersInCompany()
                    .then(async (localUsers : LocalUserResponse[]) => {

                        const globalUserDetails : GlobalUserResponse[] =
                            await Promise.all(localUsers.map(localUser => getGlobalUserById(localUser.globalUserId)))

                        setUsersDetails(globalUserDetails);

                        setRefresh(false);

                    })
                    .catch(err => {
                        toast({
                            title: t('Failed to load users for the municipality'),
                            description: t(err?.message?.toString()),
                            status: 'error',
                            duration: toastDuration,
                            isClosable: true,
                        });
                        return;
                    })
            }
            catch (err : any) {
                toast({
                    title: t('Failed to load users for the municipality'),
                    description: t(err?.toString()),
                    status: 'error',
                    duration: toastDuration,
                    isClosable: true,
                });
                setRefresh(false);
            }
        }
    }, [isActive, refresh]);

    const sendInvite = () => {
        if(inviteEmail) {
            // TODO: use the user group id selected
            inviteUser({
                "emailAddress" :  inviteEmail,
                "name" : "",
                "userGroupsToJoin": userGroupSelectedForInvitation ? [userGroupSelectedForInvitation] : []
            })
                .then((userInvite : UserInviteResponse) => {
                    setRefresh(true);
                    onClose();
                    toast({
                        title: t('User invite sent successfully'),
                        description: '',
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true,
                    });
                })
                .catch(err => {
                    toast({
                        title: t('Failed to send invite to user'),
                        description: t(err?.message?.toString()),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    onClose();
                    return;
                })
        }
    };

    const handleInviteEmailChange = ((event: { target: HTMLInputElement }) => {
        const value = event.target.value;
        setInviteEmail(value);
    });

    const handleUserGroupChange = (event: any) => {
        setUserGroupSelectedForInvitation(event.target.value);
    }

    return (
        <>
            <Flex justifyContent={"space-between"}>
                <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"} isCentered>
                    <ModalOverlay />
                    <ModalContent height={"70vh"}>
                        <ModalHeader>{t("Invite user")}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody m={5}>
                            <FormControl key={`input-field-email`} mb={2}>
                                <FormLabel htmlFor="email">{t("Email Address")}</FormLabel>
                                <Input onChange={handleInviteEmailChange} className='entry-input' id="email" type='text' />
                            </FormControl>
                            <FormControl key={`input-field-ug`} mb={2}>
                                <FormLabel htmlFor="ug">{t("User Group")}</FormLabel>
                                <Select onChange={handleUserGroupChange}>
                                    <option>NA</option>
                                    {
                                        userGroups?.map(userGroup => {
                                            if (userGroup.userGroupType === UserGroupType.GLOBAL_USER_GROUP)
                                                return null;
                                            return <option value={userGroup._id}>{t(userGroup.name)}</option>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Button mt={8} colorScheme={"blue"} onClick={sendInvite}>{t("Send invite")}</Button>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Flex>
            {
                usersDetails && userGroups ? (
                    <>
                        <Flex mb={5} w={"100%"} justifyContent={"flex-end"}>
                            <Button onClick={onOpen} colorScheme={'green'}>{t("Invite user")}</Button>
                        </Flex>
                        <MDBTable bordered striped>
                            <MDBTableHead>
                                <tr className={'table-dark'}>
                                    <th>{t("Name")}</th>
                                    <th>{t("Email Address")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {usersDetails?.map((userDetails: GlobalUserResponse) => {
                                    return (
                                        <tr key={`user-details-list-${userDetails.email}`}>
                                            <td>{userDetails.name}</td>
                                            <td>{userDetails.email}</td>
                                        </tr>
                                    );
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </>
                ) : (
                    <VStack h={"80vh"} justify={'center'}>
                        <Spinner size='xl' />
                        <Text>{t("Loading company users, please wait")}!</Text>
                    </VStack>
                )
            }
        </>
    );
}
