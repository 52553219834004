import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {Center} from "@chakra-ui/react";
import {computeFinalScore} from "../../utilities/ProcessData";
import * as React from "react";
import {HalfPieChartCustomized} from "../../GenericCharts/HalfPieChart";
import {getLastPhaseForIndicatorEntry} from "../../../../helpers/ApplicationHelper";
import {getPerformanceColour} from "../../ReportsHelper";

export const TargetAchievementSemiDonutChart = (
    props : {
        chartName : string,
        indicatorDataEntry : IndicatorDataEntryResponse,
        indicatorData : IndicatorResponse,
        indicatorDataType: IndicatorDataType,
        onChartImageURI: any
    }
) => {
    const {
        chartName,
        indicatorDataEntry,
        indicatorData,
        indicatorDataType,
        onChartImageURI
    } = props;

    const lastPhaseForIndicatorEntry = getLastPhaseForIndicatorEntry(indicatorDataEntry);

    const achievedScore = lastPhaseForIndicatorEntry ? computeFinalScore(lastPhaseForIndicatorEntry, indicatorData, indicatorDataType).score : 0;
    const targetScore = lastPhaseForIndicatorEntry ? parseInt(lastPhaseForIndicatorEntry['target'].value || '100') : 100

    return (
        <>
            <Center m={5}>
                <HalfPieChartCustomized
                    achieved={achievedScore}
                    target={targetScore}
                    chartColour={getPerformanceColour(achievedScore, indicatorData.indicatorNumber)}
                    title={chartName}
                />
            </Center>
        </>
    );

}
