import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {BarChartGrouped, BarChartGroupedDataType} from "../../GenericCharts/BarChartGrouped";
import * as React from "react";
import {processDataGroup} from "../../utilities/ProcessData";
import moment from "moment";
import {Skeleton} from "@chakra-ui/react";
import {filterIndicatorDataEntriesForIndicator} from "../../../../services/IndicatorDataEntryService";
import {EntitySearch} from "../../../../entities/request/EntitySearch";
import {FieldSearch} from "../../../../entities/request/FieldSearch";
import {SearchTypes} from "../../../../enums/SearchTypes";
import {
    getEndRecordingDateForDate,
    getStartRecordingDateForDate
} from "../../../../helpers/ApplicationHelper";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {getYearQuarterCount} from "../../../../helpers/DateHelper";
import {IGroupedSeriesData} from "../../interfaces/GroupedData";
import {getPerformanceColour} from "../../ReportsHelper";

export const AchievementColumnChartForPast8Quarters = (
    props : {
        chartName : string,
        reportDuration : moment.Moment
        indicatorDataEntry : IndicatorDataEntryResponse,
        indicatorData : IndicatorResponse,
        indicatorDataType: IndicatorDataType,
        onChartImageURI: any
    }
) => {
    const {
        chartName,
        reportDuration,
        indicatorDataEntry,
        indicatorData,
        indicatorDataType,
        onChartImageURI
    } = props;

    const { t, i18n } = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [allRelevantIndicatorEntries, setAllRelevantIndicatorEntries] = React.useState<IndicatorDataEntryResponse[] | null>(null)

    React.useEffect(() => {
        filterIndicatorDataEntriesForIndicator(
            indicatorData.indicatorNumber,
            new EntitySearch([
                new FieldSearch(
                    SearchTypes.VALUE_EQUAL,
                    'indicatorNumber',
                    indicatorData.indicatorNumber
                ),
                new FieldSearch(
                    SearchTypes.VALUE_EQUAL,
                    'indicatorDataType',
                    indicatorDataType
                ),
                new FieldSearch(
                    SearchTypes.VALUE_GREATER_THAN_EQUAL_TO,
                    'recordingDate',
                    getStartRecordingDateForDate(
                        moment(reportDuration).subtract(7, 'quarter').toDate(), indicatorDataType
                    )
                ),
                new FieldSearch(
                    SearchTypes.VALUE_LESS_THAN_EQUAL_TO,
                    'recordingDate',
                    getEndRecordingDateForDate(
                        reportDuration.toDate(), indicatorDataType
                    )
                )
            ])
        ).then(
            (indicatorDataEntryResponses : IndicatorDataEntryResponse[]) => {
                setAllRelevantIndicatorEntries(indicatorDataEntryResponses);
                setIsLoading(false);
            }
        )
    }, [
        indicatorDataEntry,
        indicatorDataType,
        reportDuration
    ]);

    const getGroupNamesForIndicatorYearWise = (
        reportDuration: moment.Moment, quarterCount: number
    ): { title: string, cols: number}[] => {
        return Object.entries(getYearQuarterCount(reportDuration, quarterCount)).map(([year, yearGroupCounts]) => {
            return {
                title: year.toString(),
                cols: yearGroupCounts
            }
        });
    };

    const getBarChartGroupDataForIndicatorYearsForQuarters = (
        reportDuration: moment.Moment,
        groupedSeriesData: IGroupedSeriesData,
        quarterCount: number
    ): BarChartGroupedDataType[] => {

        let data: { [key: string]: BarChartGroupedDataType } = {};

        const dateMoment = moment(reportDuration);

        while(quarterCount-- > 0) {

            const year = dateMoment.year().toString();
            const quarter =`Q${dateMoment.quarter()}`;

            dateMoment.subtract(1, 'quarter');

            let value: number;
            let target: number;
            let star: number;
            let barName: string;
            let legendName: string;
            let colouringFunction: any;

            if (groupedSeriesData.groupedData[year]) {
                const entries = groupedSeriesData.groupedData[year];
                const entry = entries.find(e => e.entryName === (`${quarter} ${year}`));
                if (entry) {
                    value = entry.value;
                    target = entry.target;
                    star = entry.stars;
                    barName = quarter;
                    legendName = entry.legendName;
                    colouringFunction = (performanceScore : number) => getPerformanceColour(performanceScore, entry.indicator.indicatorNumber);
                } else {
                    value = 0;
                    target = -1;
                    star = 0;
                    barName = quarter;
                    legendName = ''
                    colouringFunction = null;
                }
            } else {
                value = 0;
                target = -1;
                star = 0;
                barName = quarter;
                legendName = ''
                colouringFunction = null;
            }

            if(data[year]) {
                data[year] = {
                    values : [value, ...data[year].values],
                    targets: [target, ...data[year].targets],
                    stars: [star, ...data[year].stars],
                    barNames: [barName, ...data[year].barNames],
                    legendNames: [legendName, ...data[year].legendNames],
                    colouringFunctions: [colouringFunction, ...data[year].colouringFunctions]
                }
            }
            else {
                data[year] = {
                    values : [value],
                    targets: [target],
                    stars: [star],
                    barNames: [barName],
                    legendNames: [legendName],
                    colouringFunctions: [colouringFunction]
                }
            }
        }

        return Object.values(data);
    }


    return (
        <>
            <Skeleton
                isLoaded={!isLoading && allRelevantIndicatorEntries !== null}
            >
                {
                    allRelevantIndicatorEntries && (
                        <BarChartGrouped
                            getChartImageURI={onChartImageURI}
                            chartName={chartName}
                            chartId={'grouped3'}
                            groups={getGroupNamesForIndicatorYearWise(reportDuration, 8)}
                            data={
                                getBarChartGroupDataForIndicatorYearsForQuarters(
                                    reportDuration,
                                    processDataGroup(
                                        indicatorData,
                                        allRelevantIndicatorEntries,
                                        indicatorDataType,
                                        selectedLanguage
                                    ), 8
                                )
                            } />
                    )
                }
            </Skeleton>
        </>
    );
}
