import {baseApiUrl, executeRequest, RequestMethod} from "./BaseService";
import {SecurityGroupResponse} from "../entities/response/securityGroup/SecurityGroupResponse";
import {SecurityGroupFeatures} from "../enums/SecurityGroupFeatures";
import {getCompanyIdSelectedFromLocalStorage} from "../utilities/LocalStorageUtil";

const baseSecurityGroupUrl = baseApiUrl + '/securityGroups';

export const getSecurityGroupById = async (securityGroupId : string, companyId? : string) : Promise<SecurityGroupResponse> => {

    const companyIdSelectedFromLocalStorage = getCompanyIdSelectedFromLocalStorage();

    if(!companyId && companyIdSelectedFromLocalStorage) {
        companyId = companyIdSelectedFromLocalStorage;
    }

    try {
        return await executeRequest(`${baseSecurityGroupUrl}/${companyId}/securityGroups/${securityGroupId}`, RequestMethod.GET, undefined, null) as SecurityGroupResponse;
    }
    catch (err) {
        throw err;
    }

}

export const getAllSecurityGroups = async (companyId? : string) : Promise<SecurityGroupResponse[]> => {

    const companyIdSelectedFromLocalStorage = getCompanyIdSelectedFromLocalStorage();

    if(!companyId && companyIdSelectedFromLocalStorage) {
        companyId = companyIdSelectedFromLocalStorage;
    }

    try {
        return await executeRequest(`${baseSecurityGroupUrl}/${companyId}`, RequestMethod.GET, undefined, null) as SecurityGroupResponse[];
    }
    catch (err) {
        throw err;
    }

}


export const addLocalUserToSecurityGroup = async (localUserId: string, securityGroupId: string) : Promise<SecurityGroupResponse> => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/addLocalUser`, RequestMethod.POST, {
            localUserId : localUserId,
            securityGroupId : securityGroupId,
        }, null) as SecurityGroupResponse;
    }
    catch (err) {
        throw err;
    }
}

export const removeLocalUserFromSecurityGroup = async (localUserId: string, securityGroupId: string) : Promise<SecurityGroupResponse> => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/removeLocalUser`, RequestMethod.POST, {
            localUserId : localUserId,
            securityGroupId : securityGroupId,
        }, null) as SecurityGroupResponse;
    }
    catch (err) {
        throw err;
    }
}

export const addUserGroupToSecurityGroup = async (userGroupToAddId: string, securityGroupId: string) : Promise<SecurityGroupResponse> => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/addUserGroup`, RequestMethod.POST, {
            userGroupToAddId : userGroupToAddId,
            securityGroupId : securityGroupId,
        }, null) as SecurityGroupResponse;
    }
    catch (err) {
        throw err;
    }
}

export const removeUserGroupFromSecurityGroup = async (userGroupToRemoveId: string, securityGroupId: string) : Promise<SecurityGroupResponse> => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/removeUserGroup`, RequestMethod.POST, {
            userGroupToRemoveId : userGroupToRemoveId,
            securityGroupId : securityGroupId,
        }, null) as SecurityGroupResponse;
    }
    catch (err) {
        throw err;
    }
}

export const checkLocalUserHasFeatureActionPermission = async (localUserId: string, feature: SecurityGroupFeatures, featureReferenceId : string, actionOnFeature : string) : Promise<boolean> => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/checkLocalUserHasFeatureActionPermission`, RequestMethod.POST, {
            localUserId : localUserId,
            feature : feature,
            featureReferenceId : featureReferenceId,
            actionOnFeature : actionOnFeature,
        }, null) as boolean;
    }
    catch (err) {
        throw err;
    }
}

export const getAllFeatureActionPermissionsWithLocalUser = async (localUserId: string, feature: SecurityGroupFeatures, featureReferenceId : string) : Promise<string[]> => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/getAllFeatureActionPermissionsWithLocalUser`, RequestMethod.POST, {
            localUserId : localUserId,
            feature : feature,
            featureReferenceId : featureReferenceId,
        }, null) as string[];
    }
    catch (err) {
        throw err;
    }
}

export const getAllPermissionsWithLocalUser = async () : Promise<any> => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/getAllPermissionsWithLocalUser`, RequestMethod.GET, undefined, null) as Map<SecurityGroupFeatures, any>;
    }
    catch (err) {
        throw err;
    }
}

export const getAllFeatureActionPermissionsWithUserGroup = async (userGroupId: string, feature: SecurityGroupFeatures, featureReferenceId : string) : Promise<string[]> => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/getAllFeatureActionPermissionsWithUserGroup`, RequestMethod.POST, {
            userGroupId : userGroupId,
            feature : feature,
            featureReferenceId : featureReferenceId,
        }, null) as string[];
    }
    catch (err) {
        throw err;
    }
}

export const getAllSecurityGroupsByFeatureAndAction = async (feature: SecurityGroupFeatures, featureReferenceId : string, actionOnFeature : string) : Promise<SecurityGroupResponse[]> => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/getByFeatureAndAction`, RequestMethod.POST, {
            feature : feature,
            featureReferenceId : featureReferenceId,
            actionOnFeature : actionOnFeature,
        }, null) as SecurityGroupResponse[];
    }
    catch (err) {
        throw err;
    }
}

export const getAllSecurityGroupsByFeature = async (feature: SecurityGroupFeatures, featureReferenceId : string) : Promise<SecurityGroupResponse[]> => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/getAllSecurityGroupsByFeature`, RequestMethod.POST, {
            feature : feature,
            featureReferenceId : featureReferenceId,
        }, null) as SecurityGroupResponse[];
    }
    catch (err) {
        throw err;
    }
}

export const getAllFeatureActions = async () : Promise<{ [key: string]: string[] } > => {
    try {
        return await executeRequest(`${baseSecurityGroupUrl}/getAllFeatureActions`, RequestMethod.GET, undefined, null) as { [key: string]: string[] };
    }
    catch (err) {
        throw err;
    }
}
