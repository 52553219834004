import {BarChartGrouped, BarChartGroupedDataType} from "../../GenericCharts/BarChartGrouped";
import {Box} from "@chakra-ui/react";
import * as React from "react";
import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {IGroupedData, IGroupedSeriesData} from "../../interfaces/GroupedData";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {getPerformanceColour} from "../../ReportsHelper";
import moment from "moment";
import {getLastPhaseForIndicatorEntry} from "../../../../helpers/ApplicationHelper";

export const ChartAllCompany3YearIndicatorAchievement = (
    props: {
        chartName : string,
        onChartImageURI: any,
        reportDuration : moment.Moment,
        dataGroupingForIndicator : {indicator : IndicatorResponse, groupedData : IGroupedSeriesData[]},
        yearsCount : number,
    }
): JSX.Element => {

    const {
        chartName,
        onChartImageURI,
        reportDuration,
        dataGroupingForIndicator,
        yearsCount
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const getLatestIndicatorDataEntryResponseInYear = (indicatorDataEntriesForYear : IGroupedData[]) : IGroupedData => {
        return indicatorDataEntriesForYear
            .filter(indicatorDataEntry => getLastPhaseForIndicatorEntry(indicatorDataEntry.indicatorDataEntry) !== undefined)
            .sort(
            (entry1, entry2) =>
                moment(entry1.indicatorDataEntry.recordingDate).isBefore(moment(entry2.indicatorDataEntry.recordingDate)) ? 1 : -1
        )[0];
    }

    const getBarChartGroupDataForIndicatorsYears = (): BarChartGroupedDataType[] => {
        const currentYear = reportDuration.year();
        let years: number[] = [];
        let data: BarChartGroupedDataType[] = [];

        for (let i = yearsCount - 1; i >=0; i--) {
            years.push(currentYear - i);
        }

        const processesGroupedData = dataGroupingForIndicator.groupedData || [];

        processesGroupedData.forEach(groupedSeriesData => {
            const entries = groupedSeriesData.groupedData;
            const values: number[] = [];
            const targets: number[] = [];
            const stars: number[] = [];
            const barNames: string[] = [];
            const legendNames: string[] = [];
            const colouringFunctions: any[] | null = [];
            years.map(year => {
                if (entries[year]) {
                    const lastEntry = getLatestIndicatorDataEntryResponseInYear(entries[year]);
                    values.push(lastEntry.value);
                    targets.push(lastEntry.target);
                    stars.push(lastEntry.stars);
                    barNames.push(year.toString());
                    legendNames.push(`${year.toString()} - ${groupedSeriesData.company?.companyName}`);
                    colouringFunctions.push((performanceScore : number) => getPerformanceColour(performanceScore, lastEntry.indicator.indicatorNumber));
                } else {
                    values.push(0);
                    targets.push(-1);
                    stars.push(0);
                    barNames.push(year.toString());
                    legendNames.push(`${year.toString()} - ${groupedSeriesData.company?.companyName}`);
                    colouringFunctions.push(null);
                }
            });
            data.push({
                legendNames,
                barNames,
                values,
                targets,
                stars,
                colouringFunctions
            })
        });

        return data;
    }


    return (
        <Box m={10}>
            <BarChartGrouped
                getChartImageURI={onChartImageURI}
                chartName={`${dataGroupingForIndicator.indicator.name[selectedLanguage]} - ${chartName}`}
                chartId={'all_indicators_report_2' + dataGroupingForIndicator.indicator.indicatorNumber}
                groups={
                    dataGroupingForIndicator
                        .groupedData.map(groupedSeriesData => {
                        return {
                            title: groupedSeriesData.company?.companyName || '',
                            cols: yearsCount,
                        }
                    })
                }
                data={
                    getBarChartGroupDataForIndicatorsYears()
                } />
        </Box>
    )
}
