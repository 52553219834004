import {IndicatorResponse} from "../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataEntryResponse} from "../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import moment from "moment";
import {ICompany} from "../../../routes/CompaniesList";
import {Flex, Heading} from "@chakra-ui/react";
import {CellType, ColumnDataType, ComplexCell, Table} from "../GenericCharts/Table";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../i18n/SelectedLanguage";
import {getLastPhaseForIndicatorEntry} from "../../../helpers/ApplicationHelper";
import {IndicatorDataType} from "../../../enums/IndicatorDataType";

export const TableYearlyIndicatorDataCompanyWise = (
    props: {
        chartName : string,
        onChartImageURI: any,
        reportDuration : moment.Moment,
        indicatorEntriesDataForEachCompany : Map<ICompany, IndicatorDataEntryResponse[]>,
        indicatorDataType : IndicatorDataType
        indicatorResponse : IndicatorResponse,
        allYears : Set<number>
    }
): JSX.Element => {


    const {
        chartName,
        onChartImageURI,
        reportDuration,
        indicatorEntriesDataForEachCompany,
        indicatorDataType,
        indicatorResponse,
        allYears
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const getLatestIndicatorDataEntryResponseInYear = (
        indicatorDataEntriesForYear : IndicatorDataEntryResponse[],
        year : number
    ) : IndicatorDataEntryResponse | null => {
        const indicatorDataEntriesFilteredAndSorted = indicatorDataEntriesForYear
            .filter(indicatorDataEntry => moment(indicatorDataEntry.recordingDate).year() === year)
            .filter(indicatorDataEntry => getLastPhaseForIndicatorEntry(indicatorDataEntry) !== undefined)
            .sort(
                (entry1, entry2) =>
                    moment(entry1.recordingDate).isBefore(moment(entry2.recordingDate)) ? 1 : -1
            );
        return indicatorDataEntriesFilteredAndSorted.length > 0 ? indicatorDataEntriesFilteredAndSorted[0] : null;
    }

    const tableData : ColumnDataType[] = Array.from(allYears).flatMap(year =>
        Array.from(indicatorEntriesDataForEachCompany.entries()).map(
            ([companyDetails, indicatorDataEntryResponses]) => {

                const latestIndicatorDataEntryResponseInYear : IndicatorDataEntryResponse | null =
                    getLatestIndicatorDataEntryResponseInYear(indicatorDataEntryResponses, year);

                const lastPhaseData = latestIndicatorDataEntryResponseInYear
                    ? getLastPhaseForIndicatorEntry(latestIndicatorDataEntryResponseInYear)
                    : null;

                return {
                    header: companyDetails.companyName,
                    singleValues: indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].indicatorDataTypeEntryColumns.map(
                        indicatorDataTypeEntryColumnResponse =>
                            lastPhaseData
                                ? lastPhaseData[indicatorDataTypeEntryColumnResponse.name]?.value || ''
                                : '-'
                    ),
                    stars: [],
                    values: [],
                    notApplicable : indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].indicatorDataTypeEntryColumns.map(
                        indicatorDataTypeEntryColumnResponse =>
                            lastPhaseData
                                ? lastPhaseData[indicatorDataTypeEntryColumnResponse.name]?.notApplicable
                                : null
                    ),
                    notReported : indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].indicatorDataTypeEntryColumns.map(
                        indicatorDataTypeEntryColumnResponse =>
                            lastPhaseData
                                ? lastPhaseData[indicatorDataTypeEntryColumnResponse.name]?.notReported
                                : null
                    ),
                    targets: [],
                    coloringFunctions: [],
                    iconFunction: null
                };
            }
        )
    );

    const getTableColumnsFromSeriesData = (): ColumnDataType[] => {
        return [
            {
                header: { value : '', cellType : CellType.discard, rowSpan : 1, colSpan : 3 },
                singleValues: indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].indicatorDataTypeEntryColumns.map(
                    indicatorDataTypeEntryColumnResponse => indicatorDataTypeEntryColumnResponse.displayName[selectedLanguage]
                ),
                stars: [],
                values: [],
                notApplicable : [],
                notReported : [],
                targets: [],
                coloringFunctions: [],
                iconFunction: null
            },
            {
                header: { value : '', cellType : CellType.discard, rowSpan : 1, colSpan : 1 },
                singleValues: indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].indicatorDataTypeEntryColumns.map(
                    indicatorDataTypeEntryColumnResponse => indicatorDataTypeEntryColumnResponse.description[selectedLanguage]
                ),
                stars: [],
                values: [],
                notApplicable : [],
                notReported : [],
                targets: [],
                coloringFunctions: [],
                iconFunction: null
            },
            {
                header: { value : '', cellType : CellType.discard, rowSpan : 1, colSpan : 1 },
                singleValues: indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].indicatorDataTypeEntryColumns.map(
                    indicatorDataTypeEntryColumnResponse => indicatorDataTypeEntryColumnResponse.unit[selectedLanguage]
                ),
                stars: [],
                values: [],
                notApplicable : [],
                notReported : [],
                targets: [],
                coloringFunctions: [],
                iconFunction: null
            },
            ...tableData
        ];
    }

    const getOtherHeaders = () => {

        const companyCount : number = indicatorEntriesDataForEachCompany.size;

        const headers: ComplexCell[] = [];

        Array.from(allYears).forEach(year => {
            headers.push({
                value: year,
                cellType: CellType.complex,
                colSpan: companyCount,
                rowSpan: 1,
            });

            const extra = {
                value: "",
                cellType: CellType.discard,
                colSpan: 0,
                rowSpan: 1,
            };
            for (let j=0; j<companyCount ;j++) {
                headers.push(extra);
            }
        })
        return headers;
    }

    return (
        <>
            <Flex flexDirection={"column"}>
                <Heading size={'md'} mb={5}>{chartName}</Heading>
                <Table
                    name={chartName}
                    tableId={chartName}
                    headers={[
                        { value: indicatorResponse.name[selectedLanguage], cellType: CellType.complex, colSpan: 3, rowSpan: 2},
                        ...getOtherHeaders()
                    ]}
                    columns={getTableColumnsFromSeriesData()}
                    noOfRows={indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].indicatorDataTypeEntryColumns.length}
                />
            </Flex>
        </>
    )
}
