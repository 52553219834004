import {NavigateFunction} from "react-router-dom";
import {
    getCompanyIdSelectedFromLocalStorage,
    getCompanyLevelPermissionsOfUserForCompanyInLocalStorage,
    getCompanySelectedFromLocalStorage, getIndicatorLevelPermissionsOfUserForCompanyInLocalStorage,
    getLocalUserIdIdSelectedFromLocalStorage,
    removeCompanyIdSelectedFromLocalStorage,
    removeCompanySelectedFromLocalStorage,
    removeLocalUserIdIdSelectedFromLocalStorage,
    removeLocalUserPermissionsFromLocalStorage
} from "../utilities/LocalStorageUtil";

export const navigateToLoginPage = (navigator : NavigateFunction) => {
    localStorage.clear();
    navigator('/login');
}

export const checkCompanyStorageVariablesElseNavigate = (navigator : NavigateFunction) => {
    if(!checkIfCompanyStorageVariablesSet()) {
        navigateToCompaniesPage(navigator);
    }
}

export const checkIfCompanyStorageVariablesSet = () => {
    return getCompanyIdSelectedFromLocalStorage() !== null
        && getLocalUserIdIdSelectedFromLocalStorage() !== null
        && getCompanySelectedFromLocalStorage() !== null
        && getCompanyLevelPermissionsOfUserForCompanyInLocalStorage() !== null
        && getIndicatorLevelPermissionsOfUserForCompanyInLocalStorage() !== null
}

export const navigateToCompaniesPage = (navigator : NavigateFunction) => {
    removeCompanyIdSelectedFromLocalStorage()
    removeCompanySelectedFromLocalStorage()
    removeLocalUserIdIdSelectedFromLocalStorage()
    removeLocalUserPermissionsFromLocalStorage()
    navigator('/companies');
}
