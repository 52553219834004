import {IndicatorResponse} from "../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataEntryResponse} from "../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../i18n/SelectedLanguage";
import * as React from "react";
import {processDataGrouped} from "../utilities/ProcessData";
import {IndicatorDataType} from "../../../enums/IndicatorDataType";
import {IGroupedSeriesData} from "../interfaces/GroupedData";
import {ICompany} from "../../../routes/CompaniesList";
import {IndicatorNumber} from "../../../enums/IndicatorNumber";
import {Box} from "@chakra-ui/react";
import {TableYearlyIndicatorDataCompanyWise} from "./TableYearlyIndicatorDataCompanyWise";

export const TableCompanyWise3YearLatestDataForIndicator = (
    props: {
        chartName : string,
        indicatorDataEntriesForEachCompany : Map<ICompany, Map<IndicatorResponse, IndicatorDataEntryResponse[]> | null>,
        indicatorDataType : IndicatorDataType,
        reportDuration : moment.Moment,
        onChartImageURI: any,
    }
): JSX.Element => {

    const {
        chartName,
        indicatorDataEntriesForEachCompany,
        indicatorDataType,
        reportDuration,
        onChartImageURI
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const allIndicatorMap : Map<IndicatorNumber, IndicatorResponse> = new Map<IndicatorNumber, IndicatorResponse>();

    const allYears : Set<number> = new Set<number>();

    Array.from(indicatorDataEntriesForEachCompany.values()).forEach(
        indicatorDataEntriesForCompany => {
            if (indicatorDataEntriesForCompany) {
                Array.from(indicatorDataEntriesForCompany.entries()).forEach(
                    ([indicatorResponse, indicatorDataEntries]) => {
                        if (!allIndicatorMap.has(indicatorResponse.indicatorNumber)) {
                            allIndicatorMap.set(indicatorResponse.indicatorNumber, indicatorResponse);
                        }
                        indicatorDataEntries.forEach(
                            indicatorDataEntry => allYears.add(moment(indicatorDataEntry.recordingDate).year())
                        )
                    }
                )
            }
        }
    )

    const indicatorEntriesDataForEachCompanyTransformed : Map<IndicatorNumber, Map<ICompany, IndicatorDataEntryResponse[]>> =
        Array.from(indicatorDataEntriesForEachCompany.entries()).reduce(
            (prev, [companyDetails, indicatorDataEntriesForCompany]) => {
                if(indicatorDataEntriesForCompany) {
                    Array.from(indicatorDataEntriesForCompany.entries()).forEach(
                        ([indicatorResponse, indicatorDataEntryForCompany]) => {
                            if(!prev.has(indicatorResponse.indicatorNumber)) {
                                prev.set(
                                    indicatorResponse.indicatorNumber,
                                    new Map<ICompany, IndicatorDataEntryResponse[]>()
                                );
                            }
                            prev.get(indicatorResponse.indicatorNumber)?.set(companyDetails, indicatorDataEntryForCompany);
                        }
                    )
                }
                return prev;
            },
            new Map<IndicatorNumber, Map<ICompany, IndicatorDataEntryResponse[]>>()
        )

    const dataGroupingForEachIndicator : Map<IndicatorNumber, {indicator : IndicatorResponse, groupedData : IGroupedSeriesData[]}> =
        Array.from(allIndicatorMap.entries()).reduce(
            (prev, [indicatorNumber, indicatorResponse]) =>
                prev.set(
                    indicatorNumber,
                    {
                        indicator : indicatorResponse,
                        groupedData :
                            indicatorEntriesDataForEachCompanyTransformed.get(indicatorNumber) ? processDataGrouped(
                                // @ts-ignore
                                Array.from(indicatorEntriesDataForEachCompanyTransformed.get(indicatorNumber).entries())
                                .map(
                                    ([companyDetails, indicatorDataEntriesResponseForCompany]) =>
                                        ({
                                            indicator : indicatorResponse,
                                            indicatorDataEntries : indicatorDataEntriesResponseForCompany,
                                            company : companyDetails
                                        }))
                            , IndicatorDataType.AURA_REPORTING,
                                selectedLanguage
                        ) : []
                    }
                )
            , new Map<IndicatorNumber, {indicator : IndicatorResponse, groupedData : IGroupedSeriesData[]}>()
        );

    return (
        <>
            {
                Array.from(indicatorEntriesDataForEachCompanyTransformed.entries()).map(
                    ([indicatorNumber, indicatorEntriesOfEachCompanyForIndicator]) => (
                        <>
                            {
                                dataGroupingForEachIndicator.get(indicatorNumber) !== null &&
                                dataGroupingForEachIndicator.get(indicatorNumber) !== undefined &&
                                indicatorEntriesDataForEachCompanyTransformed.get(indicatorNumber) && (
                                    <Box m={10}>
                                        <TableYearlyIndicatorDataCompanyWise
                                            chartName={`${dataGroupingForEachIndicator.get(indicatorNumber)?.indicator.name[selectedLanguage]} - ${chartName}`}
                                            onChartImageURI={onChartImageURI}
                                            reportDuration={reportDuration}
                                            // @ts-ignore
                                            indicatorEntriesDataForEachCompany={indicatorEntriesDataForEachCompanyTransformed.get(indicatorNumber)}
                                            indicatorDataType={indicatorDataType}
                                            // @ts-ignore
                                            indicatorResponse={allIndicatorMap.get(indicatorNumber)}
                                            allYears={allYears}
                                        />
                                    </Box>
                                )
                            }
                        </>
                    )
                )
            }
        </>
    )
}
