import React, {ReactNode, ReactText} from 'react';
import {
    Avatar,
    Box,
    BoxProps,
    Center,
    Drawer,
    DrawerContent,
    Flex,
    FlexProps,
    HStack,
    Icon,
    IconButton,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useDisclosure,
    VStack
} from '@chakra-ui/react';
import {FiChevronDown, FiGrid, FiHome, FiMenu, FiPieChart, FiUsers} from 'react-icons/fi';
import {IconType} from 'react-icons';
import {useNavigate} from "react-router-dom";
import {ICompany} from "../routes/CompaniesList";
import './SidebarWithHeader.css';
import {useTranslation} from "react-i18next";
import {
    checkIfGlobalUserHasManageGlobalUserGroupPermission, checkIfGlobalUserHasManageGlobalUsersPermissionPermission,
    getCompanySelectedFromLocalStorage,
    getGlobalUserFromLocalStorage,
} from "../utilities/LocalStorageUtil";
import {getLocalUserById} from "../services/LocalUserService";
import {MunicipalityReportPath} from "../App";
import {LocalUserResponse} from "../entities/response/user/LocalUserResponse";
import {GlobalUserResponse} from "../entities/response/user/GlobalUserResponse";
import {checkIfUserHasCompanyLevelPermissions} from "../helpers/PermissionsHelper";
import {CompanyLevelActions} from "../enums/featureActions/CompanyLevelActions";
import {navigateToCompaniesPage, navigateToLoginPage} from "../helpers/NavigationHelper";

interface LinkItemProps {
    name: string;
    icon: IconType;
    link: string;
}

export const SidebarWithHeader = (
    props: {
        children: ReactNode

        isNonCompanySpecificPage : boolean,
    }
) : JSX.Element => {

    const { isNonCompanySpecificPage, children } = props;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [localUser, setLocalUser] = React.useState<LocalUserResponse>();
    const [company, setCompany] = React.useState<ICompany>();

    React.useEffect(() => {
        if(!isNonCompanySpecificPage) {
            const companySelected: ICompany | null = getCompanySelectedFromLocalStorage();

            if (companySelected) {
                setCompany(companySelected)
                getLocalUserById(companySelected.localUserId).then(user => {
                    setLocalUser(user);
                });
            }
        }
    }, []);
    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent
                isNonCompanySpecificPage={isNonCompanySpecificPage}
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent isNonCompanySpecificPage={isNonCompanySpecificPage} onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} localUser={localUser} company={company} isNonCompanySpecificPage={isNonCompanySpecificPage} />
            <Box h={"100%"} ml={{ base: 0, md: 60 }} p="4">
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
    isNonCompanySpecificPage: boolean;
}

const SidebarContent = ({ onClose, isNonCompanySpecificPage, ...rest }: SidebarProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const LinkItems: Array<LinkItemProps> = [

        ...(!isNonCompanySpecificPage ? [{ name: t('Manage Indicators'), icon: FiGrid, link: '/indicators' }] : []),
        ...(!isNonCompanySpecificPage && (
            checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.INVITE_USER)
            || checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.UPDATE_USER_GROUP)
        ) ? [{ name: t('Manage Company Users & User Groups'), icon: FiUsers, link: '/local-users-and-user-groups' }] : []),
        ...(!isNonCompanySpecificPage && (
            checkIfUserHasCompanyLevelPermissions(CompanyLevelActions.VIEW_MUNICIPALITY_REPORT)
        ) ? [{ name: t('View Municipality Reports'), icon: FiPieChart, link: MunicipalityReportPath}] : []),


        ...(isNonCompanySpecificPage ? [{ name: t('View Companies'), icon: FiHome, link: '/companies' }] : []),

        ...(isNonCompanySpecificPage && (
            checkIfGlobalUserHasManageGlobalUsersPermissionPermission()
        ) ? [{ name: t('Manage All Users'), icon: FiUsers, link: '/global-users' }] : []),

        ...(isNonCompanySpecificPage && (
            checkIfGlobalUserHasManageGlobalUserGroupPermission()
        ) ? [{ name: t('Manage Multi Company Accesses'), icon: FiUsers, link: '/manageGlobalUserGroups' }] : []),
    ];

    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex flexDirection={"column"} height={"100%"}>
                <Flex mt={"4"} mb={"4"} alignItems="center" mx="8" justifyContent="space-between">
                    <Center>
                        <IconButton
                            boxSize='100%'
                            icon={
                                <Image
                                    boxSize='100%'
                                    src='/aura_logo.png'
                                    alt='AURA'
                                    onClick={() => navigate('/indicators')}
                                />
                            }
                            aria-label={"AURA"}
                        />

                    </Center>
                </Flex>
                <Box height={"-webkit-fill-available"}>
                    {LinkItems.map((link) => (
                        <NavItem key={link.name} icon={link.icon} onClick={() => navigate(link.link)} >
                            {t(link.name)}
                        </NavItem>
                    ))}
                </Box>
                <Flex mt={"4"} mb={"4"} alignItems="center" mx="8" justifyContent="space-between">
                    <Center>
                        <IconButton
                            boxSize='100%'
                            icon={
                                <Image
                                    boxSize='100%'
                                    src='/dnaas_logo.jpeg'
                                    alt='DNAAS'
                                    onClick={() => navigate('/indicators')}
                                />
                            }
                            aria-label={"DNAAS"}
                        />
                    </Center>
                </Flex>
            </Flex>
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
    return (
        <Link href="#" style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
    localUser: LocalUserResponse | undefined;
    company: ICompany | undefined;
    isNonCompanySpecificPage: boolean;
}
const MobileNav = ({ onOpen, localUser, company, isNonCompanySpecificPage, ...rest }: MobileProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const globalUser : GlobalUserResponse | null = getGlobalUserFromLocalStorage();

    const signOut = () => {
        navigateToLoginPage(navigate)
    }

    const switchCompany = () => {
        navigateToCompaniesPage(navigate)
    }

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text
                fontSize="2xl"
                m={4}
                fontFamily="monospace"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                fontWeight="bold">
                {!isNonCompanySpecificPage ? company?.companyName : null}
            </Text>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                    src={
                                        'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                    }
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">{localUser? localUser.name : globalUser?.name}</Text>
                                    <Text fontSize="xs">{localUser? localUser.email : globalUser?.email}</Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem onClick={switchCompany}>{t(isNonCompanySpecificPage ? "Select Company" : "Switch Company")}</MenuItem>
                            <MenuItem onClick={signOut}>{t("Sign out")}</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};
