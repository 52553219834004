import {Select} from "@chakra-ui/react";
import moment from "moment";
import {dateEntryRecordingStartDate} from "../../../constants/ApplicationConstants";
import {getCurrentDateMoment, getQuarter, getQuarterDisplayValue, getYear} from "../../../helpers/DateHelper";

export const QuarterPicker = (props: any) => {
    const {
        selectedDuration,
        setSelectedDuration
    } = props;

    const quarterMoments = [];

    let dateMoment = moment(dateEntryRecordingStartDate);

    while(dateMoment.isBefore(getCurrentDateMoment().subtract(1, 'quarter'))) {
        quarterMoments.push(moment(dateMoment))
        dateMoment = dateMoment.add(1, 'quarters')
    }

    quarterMoments.reverse();

    return (
        <>
            <Select
                value={selectedDuration.toISOString()}
                width={"fit-content"}
                borderWidth={2}
                borderColor='black'
                placeholder='Select Quarter'
                onChange={(e) => setSelectedDuration(moment(e.target.value))}
            >
                {
                    quarterMoments.map(optionMoment =>
                        <option value={optionMoment.toISOString()}>{getQuarterDisplayValue(optionMoment)}</option>
                    )
                }
            </Select>
        </>
    );
}
