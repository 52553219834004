import {
    IndicatorDataTypeEntryColumnDataType,
    IndicatorDataTypeEntryColumnListPointingRuleResponse,
    IndicatorDataTypeEntryColumnNumberPointingRuleResponse,
    IndicatorDataTypeEntryColumnResponse,
    IndicatorDataTypeEntryColumnStringPointingRuleResponse,
    IndicatorResponse
} from "../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataEntryResponse} from "../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {IGroupedData, IGroupedSeriesData} from "../interfaces/GroupedData";
import {IndicatorDataType} from "../../../enums/IndicatorDataType";
import {
    IndicatorDataColumnEntryResponse
} from "../../../entities/response/indicatorDataColumnEntry/IndicatorDataColumnEntryResponse";
import {ICompany} from "../../../routes/CompaniesList";
import {getLastPhaseForIndicatorEntry} from "../../../helpers/ApplicationHelper";
import {SelectedLanguage} from "../../../i18n/SelectedLanguage";
import {IndicatorNumber} from "../../../enums/IndicatorNumber";
import {roundNumberToNPrecision} from "../../../helpers/NumberHelper";

export const computeFinalScore = (
    lastPhaseData: { [key: string]: IndicatorDataColumnEntryResponse },
    indicator: IndicatorResponse,
    indicatorDataType: IndicatorDataType
): { score: number, individualEntries: (number | string)[], notApplicable : (boolean | null | undefined)[], notReported : (boolean | null | undefined)[] } => {

    const entriesOfLastPhase: string[] = [];
    const notApplicableOfLastPhase: (boolean | null | undefined)[] = [];
    const notReportedOfLastPhase: (boolean | null | undefined)[] = [];
    Object.keys(lastPhaseData).map((columnKey) => {
        const value = lastPhaseData[columnKey]?.value;

        notReportedOfLastPhase.push(lastPhaseData[columnKey]?.notReported);
        notApplicableOfLastPhase.push(lastPhaseData[columnKey]?.notApplicable);

        // check the pointing rules
        entriesOfLastPhase.push(
            value
            && value !== ''
            && !lastPhaseData[columnKey]?.notReported
            && !lastPhaseData[columnKey]?.notApplicable
                ? value : "-"
        );
    });

    const score = getScoreForIndicatorEntry(lastPhaseData, indicator, indicatorDataType);

    return { score, individualEntries: entriesOfLastPhase, notApplicable : notApplicableOfLastPhase, notReported : notReportedOfLastPhase };
}

const getScoreForIndicatorEntry = (
    phaseData: { [key: string]: IndicatorDataColumnEntryResponse },
    indicator: IndicatorResponse,
    indicatorDataType: IndicatorDataType
) : number => {
    let score = 0;
    const indicatorDataTypeEntryColumns : IndicatorDataTypeEntryColumnResponse[] = indicator.indicatorDataTypeStructureList[indicatorDataType] &&
        indicator.indicatorDataTypeStructureList[indicatorDataType].indicatorDataTypeEntryColumns;

    if(indicator.indicatorNumber === IndicatorNumber.INDICATOR_6) {
        const r6iValue = parseFloat(phaseData['r6i']?.value || '0')
        const r6iiValue = parseFloat(phaseData['r6ii']?.value || '0')
        const r6iiiValue = parseFloat(phaseData['r6iii']?.value || '0')

        if(r6iiiValue === 0) {
            return 0;
        }

        return roundNumberToNPrecision(((r6iValue - r6iiValue)/r6iiiValue) * 100, 2);
    }

    if(indicator.indicatorNumber === IndicatorNumber.INDICATOR_7) {
        const r7iValue = parseFloat(phaseData['r7i']?.value || '0')
        const r7iiValue = parseFloat(phaseData['r7ii']?.value || '0')
        const r7iiiValue = parseFloat(phaseData['r7iii']?.value || '0')

        if(r7iiiValue === 0) {
            return 0;
        }

        return roundNumberToNPrecision(((r7iValue + r7iiValue) / r7iiiValue) * 100, 2);
    }

    if(indicator.indicatorNumber === IndicatorNumber.INDICATOR_8) {
        const r8iValue = parseFloat(phaseData['r8i']?.value || '0')
        const r8iiValue = parseFloat(phaseData['r8ii']?.value || '0')

        if(r8iiValue === 0) {
            return 0;
        }

        return roundNumberToNPrecision(((r8iValue) / r8iiValue) * 100, 2);
    }

    Object.keys(phaseData).map((columnKey) => {

        const value = phaseData[columnKey]?.value;

        const column : IndicatorDataTypeEntryColumnResponse | undefined =
            indicatorDataTypeEntryColumns?.find((columnStructure: any) => columnStructure.name === columnKey);

        // check the pointing rules
        if (column && value) {
            const points = computePointingScore(indicator, column, value);
            score = score + (points ?? 0);
        }
    });

    return score;
}

const computePointingScore = (
    indicator: IndicatorResponse,
    column: IndicatorDataTypeEntryColumnResponse,
    value: string
) => {

    // TODO: Define custom pointing rules for AURA KPI 6 to 8

    const columnDataType : IndicatorDataTypeEntryColumnDataType = column?.dataValidation.columnDataType;

    const pointingRules = column?.pointingRules;

    switch (columnDataType) {
        case IndicatorDataTypeEntryColumnDataType.LIST:
            return (pointingRules as IndicatorDataTypeEntryColumnListPointingRuleResponse[])?.find(
                rule => rule.value === value
            )?.points || 0;
        case IndicatorDataTypeEntryColumnDataType.NUMBER:
            return (pointingRules as IndicatorDataTypeEntryColumnNumberPointingRuleResponse[])?.find(
                rule => parseFloat(value) <= rule.lte && parseFloat(value) >= rule.gte
            )?.points || 0;
        case IndicatorDataTypeEntryColumnDataType.STRING:
            return (pointingRules as IndicatorDataTypeEntryColumnStringPointingRuleResponse[])?.find(
                rule => rule.value === value
            )?.points || 0;
    }
};

export const processDataGrouped = (
    indicatorsDataEntries: {
        indicator: IndicatorResponse;
        company?: ICompany;
        indicatorDataEntries: IndicatorDataEntryResponse[]
    } [], indicatorDataType: IndicatorDataType, selectedLanguage : SelectedLanguage): IGroupedSeriesData[] => {
    return indicatorsDataEntries.map(
        indicatorsDataEntry =>
            processDataGroup(
                indicatorsDataEntry.indicator,
                indicatorsDataEntry.indicatorDataEntries,
                indicatorDataType,
                selectedLanguage,
                indicatorsDataEntry.company
            )
    );
}

export const processDataGroup = (
    indicator: IndicatorResponse,
    indicatorDataEntries: IndicatorDataEntryResponse[],
    indicatorDataType: IndicatorDataType,
    selectedLanguage : SelectedLanguage,
    company? : ICompany
): IGroupedSeriesData => {

    let groupedData: { [groupKey: string]: IGroupedData[] } = {};

    indicatorDataEntries.map(dataEntry => {
        if (dataEntry.indicatorDataType === indicatorDataType) {
            const entryName = dataEntry.name;

            const lastPhaseData : ({[key : string]: IndicatorDataColumnEntryResponse} | undefined) =
                getLastPhaseForIndicatorEntry(dataEntry);

            if (lastPhaseData) {
                const reliability = lastPhaseData['reliability']?.value;
                const targetScore = lastPhaseData['target']?.value ? parseInt(lastPhaseData['target'].value) : 0;

                const {score, individualEntries, notApplicable, notReported} = computeFinalScore(lastPhaseData, indicator, indicatorDataType);

                if (groupedData[entryName.split(" ")[1]]) {
                    groupedData[entryName.split(" ")[1]].push({
                        entryName: entryName,
                        value: score,
                        notApplicable: notApplicable,
                        notReported: notReported,
                        indicatorDataEntry : dataEntry,
                        stars: parseInt(reliability ?? '0'),
                        legendName: `${entryName} : ${indicator.name[selectedLanguage]}`,
                        indicator: indicator,
                        company: company,
                        target: targetScore,
                        individualEntries
                    });
                } else {
                    groupedData[entryName.split(" ")[1]] = [{
                        // @ts-ignore
                        entryName: entryName,
                        value: score,
                        indicatorDataEntry : dataEntry,
                        notApplicable: notApplicable,
                        notReported: notReported,
                        stars: parseInt(reliability ?? '0'),
                        legendName: `${entryName} : ${indicator.name[selectedLanguage]}`,
                        indicator: indicator,
                        company: company,
                        target: targetScore ?? 100,
                        individualEntries
                    }];
                }
            }
        }
        return "done";
    });

    return (
        {
            id: indicator.name.en,
            indicator: indicator,
            groupedData: groupedData,
            company: company
        } as IGroupedSeriesData
    );
}
