import * as React from 'react';
import ApexCharts from "apexcharts";
import {Alert, Box, Flex, Grid, Heading, Text} from "@chakra-ui/react";
import {BarChartDataType} from "./BarChart";
import {useTranslation} from "react-i18next";
import {getHighColour, getLowColour, getMediumColour} from "../ReportsHelper";

export interface BarChartGroupedDataType {
    values: number[];
    stars: number[];
    colouringFunctions: (((value: number) => string)| null)[];
    barNames: string[];
    legendNames: string[];
    targets: number[];
}

export interface IBarChartProps {
    chartName: string;
    chartId: string;
    groups: { title: string, cols: number }[];
    data: BarChartGroupedDataType[];
    getChartImageURI?: (chartId: string, charName: string, data: any) => void;
}

export const BarChartGrouped = (props: IBarChartProps): JSX.Element => {
    const { chartName, data, chartId, groups } = props;
    const {t} = useTranslation();

    const convertDataForRender = (data: BarChartGroupedDataType[]) => {
        let dataExpanded: BarChartDataType[] = [];
        data.forEach((dataSegment: BarChartGroupedDataType) => {
            dataSegment.values.forEach((value, index) => {
                dataExpanded.push({
                    value: value,
                    target: dataSegment.targets[index],
                    stars: dataSegment.stars[index],
                    legendName: dataSegment.legendNames[index],
                    barName: dataSegment.barNames[index],
                    colouringFunction: dataSegment.colouringFunctions ? dataSegment.colouringFunctions[index] : null,
                });
            });
        });
        return dataExpanded.map(dataSegment => {
            return {
                x: dataSegment.barName,
                y: dataSegment.value,
                stars: dataSegment.stars,
                legendName: dataSegment.legendName,
                fillColor: dataSegment.colouringFunction ? dataSegment.colouringFunction(dataSegment.value) : '#EB8C87',
                goals: dataSegment.target === -1 ? [] : [
                    {
                        name: t('Target'),
                        value: dataSegment.target,
                        strokeHeight: 5
                    }
                ]
            }
        });
    }

    const convertedData = convertDataForRender(data);
    const options = {
        plotOptions: {
            bar: {
                columnWidth: 40,
                dataLabels: {
                    orientation: 'vertical',
                    position: 'top'
                }
            }
        },
        chart: {
            type: 'bar',
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'end',
            formatter: (val: number, config: any) => "⭐".repeat(convertedData[config.dataPointIndex].stars),
        },
        yaxis: {
            max: 100,
        },
        xaxis: {
            group: {
                style: {
                    fontSize: '10px',
                    fontWeight: 700
                },
                groups: groups
            },
            labels: {
                formatter: (val: string, config: any) => convertedData[config.dataPointIndex]?.legendName ?? val
            },
        },
        series: [{
            name: t('Achieved'),
            data: convertedData
        }]
    }

    const renderApexChart = () => {
        const chartElement = document.querySelector("#" + chartId);

        if (chartElement) {
            chartElement.innerHTML = "";
            const chart = new ApexCharts(chartElement, options);
            chart.render().then(() => {
                window.setTimeout(function() {
                    chart.dataURI({ scale: 2 }).then((uri) => {
                        props.getChartImageURI && props.getChartImageURI(props.chartId, props.chartName, uri);
                    })
                }, 1000)
            });
        }
    }

    React.useEffect(() => {
        document.addEventListener("DOMContentLoaded", event => {
            renderApexChart();
        });
    });

    React.useEffect(() => {
        renderApexChart();
    }, [data]);

    return (
        <Flex flexDirection={"column"} width={"100%"}>
            <Heading size={'md'} mb={5}>{chartName}</Heading>
            <Flex overflowX={"auto"}>
                <Grid minW={1000}>
                    <div id={chartId}></div>
                </Grid>
            </Flex>
            <Alert status={'warning'}>
                <Box>
                    <Flex>
                        <Text mr={10}>{t("Reliability")} : </Text>
                        <Text mr={5}>⭐ {t("Low")}</Text>
                        <Text mr={5}>⭐⭐ {t("Medium")}</Text>
                        <Text mr={5}>⭐⭐⭐ {t("High")}</Text>
                        <Flex mr={5} alignItems={"center"}><Box w={6} mr={1} h={1} bg={'#a8a8a8'}></Box><Text>{t("Target")}</Text></Flex>
                    </Flex>
                    <Flex>
                        <Box>
                            <Text mr={10}>{t("Performance")} : </Text>
                        </Box>
                        <Flex alignItems={"center"} mr={5}>
                            <Box w={3} mr={1} h={3} bg={getLowColour()}></Box>
                            <Text >{t("Low Performance")}</Text>
                        </Flex>
                        <Flex alignItems={"center"} mr={5}>
                            <Box w={3} mr={1} h={3} bg={getMediumColour()}></Box>
                            <Text >{t("Medium Performance")}</Text>
                        </Flex>
                        <Flex alignItems={"center"} mr={5}>
                            <Box w={3} mr={1} h={3} bg={getHighColour()}></Box>
                            <Text >{t("High Performance")}</Text>
                        </Flex>
                    </Flex>
                </Box>
            </Alert>
        </Flex>
    );
}
