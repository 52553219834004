import {
    Box,
    Button,
    Center,
    Checkbox,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import * as React from 'react'
import useDigitInput from "react-digit-input";
import {useTranslation} from "react-i18next";
import {confirmSignUpUser, signUpUser} from "../services/GlobalUserService";
import {GlobalUserResponse} from "../entities/response/user/GlobalUserResponse";
import {DigitInputElement} from "./DigitInputElement";
import {toastDuration} from "../constants/ApplicationConstants";
import {navigateToLoginPage} from "../helpers/NavigationHelper";

const CustomOverlay = () => (
    <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px)'
    />
)

export const SignUp = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isConfirmBtnLoading, setIsConfirmBtnLoading] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>();
    const [username, setUsername] = React.useState<string>();
    const [password, setPassword] = React.useState<string>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [value, onChange] = React.useState('');
    const digits = useDigitInput({
        acceptedCharacters: /^[0-9]$/,
        length: 6,
        value,
        onChange
    });
    const { t } = useTranslation();

    const handleNameChange = (event: { target: HTMLInputElement }) => {
        setName(event.target.value);
    };

    const handleUsernameChange = (event: { target: HTMLInputElement }) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event: { target: HTMLInputElement }) => {
        setPassword(event.target.value);
    };

    const signUp = () => {
        setIsLoading(true);
        if (!name || !username || !password) {
            toast({
                title: 'Please check that none of Full Name, Email and Password are empty',
                description: '',
                status: 'error',
                duration: toastDuration,
                isClosable: true,
            });
            setIsLoading(false);
            return;
        }

        signUpUser({
            name : name,
            email : username,
            password : password,
        })
            .then((globalUserResponse: GlobalUserResponse) => {
                toast({
                    title: `Verification code sent to ${username}`,
                    description: 'Check your mailbox to find the verification code',
                    status: 'success',
                    duration: toastDuration,
                    isClosable: true,
                });
                onOpen();
                setIsLoading(false);
            })
            .catch(err => {
                toast({
                    title: 'Sign up failed!',
                    description: err?.message.toString(),
                    status: 'error',
                    duration: toastDuration,
                    isClosable: true,
                });
                setIsLoading(false);
                return;
            })
    };

    const confirmSignUp = () => {
        if(username && value) {
            setIsConfirmBtnLoading(true);
            confirmSignUpUser(username, value)
                .then(
                    result => {
                        toast({
                            title: 'Sign up successful',
                            description: 'Please login now!',
                            status: 'success',
                            duration: toastDuration,
                            isClosable: true
                        });

                        navigateToLoginPage(navigate)
                        setIsConfirmBtnLoading(false);
                    }
                )
                .catch(err => {
                    toast({
                        title: 'Something went wrong while trying to verify the OTP!',
                        description: err?.message?.toString(),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    setIsConfirmBtnLoading(false);
                    return;
                })
        }
    };

    return (
        <Flex bg='#f7fafc' w={"100%"} minH={"100vh"}>
            <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"} isCentered>
                    <CustomOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <Text>{t("Enter Code")}</Text>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box w={"100%"}>
                                <Box>
                                    {t("Please enter the code sent to")} {username}
                                </Box>
                                <Center mt={10} w={"100%"}>
                                    <Flex justifyContent={"center"} id="signUpPasscode" className="input-group">
                                        <DigitInputElement autoFocus {...digits[0]} />
                                        <DigitInputElement {...digits[1]} />
                                        <DigitInputElement {...digits[2]} />
                                        <DigitInputElement {...digits[3]} />
                                        <DigitInputElement {...digits[4]} />
                                        <DigitInputElement {...digits[5]} />
                                    </Flex>
                                </Center>
                            </Box>
                            <Center mb={5}>
                                <Button isLoading={isConfirmBtnLoading} mt={8} colorScheme={"blue"} onClick={confirmSignUp}>{t("Confirm")}</Button>
                            </Center>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Stack spacing="8">
                    <Stack spacing="6">
                        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                            <Heading size={useBreakpointValue({ base: 'l', md: 'xl' })}>
                                {t("Service Monitoring System")}
                            </Heading>
                            <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                                {t("Sign up for an account")}
                            </Heading>
                            <HStack spacing="1" justify="center">
                                <Text color="muted">{t("Already registered?")}</Text>
                                <Button variant="link" colorScheme="blue" onClick={
                                    () => {
                                        navigateToLoginPage(navigate)
                                    }
                                }>
                                    {t("Sign in")}
                                </Button>
                            </HStack>
                        </Stack>
                    </Stack>
                    <Box
                        py={{ base: '0', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg="white"
                        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                        <Stack spacing="6">
                            <Stack spacing="5">
                                <FormControl>
                                    <FormLabel htmlFor="name">{t("Full Name")}</FormLabel>
                                    <Input id="name" type="name" onChange={handleNameChange}/>
                                </FormControl>
                            </Stack>
                            <Stack spacing="5">
                                <FormControl>
                                    <FormLabel htmlFor="email">{t("Email")}</FormLabel>
                                    <Input id="email" type="email" onChange={handleUsernameChange}/>
                                </FormControl>
                            </Stack>
                            <Stack spacing="5">
                                <FormControl>
                                    <FormLabel htmlFor="email">{t("Password")}</FormLabel>
                                    <Input id="password" type="password" onChange={handlePasswordChange}/>
                                </FormControl>
                            </Stack>
                            <HStack justify="space-between">
                                <Checkbox defaultIsChecked>{t("Remember me")}</Checkbox>
                            </HStack>
                            <Stack spacing="6">
                                <Button isLoading={isLoading} colorScheme="blue" onClick={signUp}>{t("Sign up")}</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </Flex>
    );
};
