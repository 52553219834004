import {baseApiUrl, executeRequest, RequestMethod} from "./BaseService";
import {UserGroupResponse} from "../entities/response/userGroup/UserGroupResponse";
import {UserGroupCreateRequest} from "../entities/request/userGroup/UserGroupCreateRequest";
import {
    getCompanyIdSelectedFromLocalStorage,
    setUserPermissionsForCompanyInLocalStorage
} from "../utilities/LocalStorageUtil";

const baseUserGroupUrl = baseApiUrl + '/userGroups';

export const getUserGroupById = async (userGroupId : string, companyId? : string) : Promise<UserGroupResponse> => {

    const companyIdSelectedFromLocalStorage = getCompanyIdSelectedFromLocalStorage();

    if(!companyId && companyIdSelectedFromLocalStorage) {
        companyId = companyIdSelectedFromLocalStorage;
    }

    try {
        return await executeRequest(`${baseUserGroupUrl}/${companyId}/userGroups/${userGroupId}`, RequestMethod.GET, undefined, null) as UserGroupResponse;
    }
    catch (err) {
        throw err;
    }

}

export const getAllUserGroups = async (companyId? : string) : Promise<UserGroupResponse[]> => {
    const companyIdSelectedFromLocalStorage = getCompanyIdSelectedFromLocalStorage();

    if(!companyId && companyIdSelectedFromLocalStorage) {
        companyId = companyIdSelectedFromLocalStorage;
    }

    try {
        return await executeRequest(`${baseUserGroupUrl}/${companyId}/`, RequestMethod.GET, undefined, null) as UserGroupResponse[];
    }
    catch (err) {
        throw err;
    }
}

export const createUserGroup = async (userGroupCreateRequest : UserGroupCreateRequest) : Promise<UserGroupResponse> => {
    try {
        const res = await executeRequest(baseUserGroupUrl, RequestMethod.POST, userGroupCreateRequest, undefined);

        return Promise.resolve(res as UserGroupResponse);
    }
    catch (err) {
        throw err;
    }
}

export const addLocalUserToUserGroup = async (localUserId: string, userGroupId: string) : Promise<UserGroupResponse> => {
    try {
        const userGroupResponse : UserGroupResponse = await executeRequest(`${baseUserGroupUrl}/addLocalUser`, RequestMethod.POST, {
            localUserId : localUserId,
            userGroupId : userGroupId,
        }, null) as UserGroupResponse;

        await setUserPermissionsForCompanyInLocalStorage();

        return userGroupResponse;
    }
    catch (err) {
        throw err;
    }
}

export const removeLocalUserToUserGroup = async (localUserId: string, userGroupId: string) : Promise<UserGroupResponse> => {
    try {
        const userGroupResponse : UserGroupResponse = await executeRequest(`${baseUserGroupUrl}/removeLocalUser`, RequestMethod.POST, {
            localUserId : localUserId,
            userGroupId : userGroupId,
        }, null) as UserGroupResponse;

        await setUserPermissionsForCompanyInLocalStorage();

        return userGroupResponse;
    }
    catch (err) {
        throw err;
    }
}

export const addUserGroupToUserGroup = async (userGroupToAddId: string, userGroupId: string) : Promise<UserGroupResponse> => {
    try {
        return await executeRequest(`${baseUserGroupUrl}/addUserGroup`, RequestMethod.POST, {
            userGroupToAddId : userGroupToAddId,
            userGroupId : userGroupId,
        }, null) as UserGroupResponse;
    }
    catch (err) {
        throw err;
    }
}

export const removeUserGroupToUserGroup = async (userGroupToRemoveId: string, userGroupId: string) : Promise<UserGroupResponse> => {
    try {
        return await executeRequest(`${baseUserGroupUrl}/removeUserGroup`, RequestMethod.POST, {
            userGroupToRemoveId : userGroupToRemoveId,
            userGroupId : userGroupId,
        }, null) as UserGroupResponse;
    }
    catch (err) {
        throw err;
    }
}

export const checkLocalUserMembership = async (localUserId: string, userGroupId: string) : Promise<boolean> => {
    try {
        return await executeRequest(`${baseUserGroupUrl}/checkLocalUserMembership`, RequestMethod.POST, {
            localUserId : localUserId,
            userGroupId : userGroupId,
        }, null) as boolean;
    }
    catch (err) {
        throw err;
    }
}
