import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {BarChartGrouped, BarChartGroupedDataType} from "../../GenericCharts/BarChartGrouped";
import * as React from "react";
import {processDataGrouped} from "../../utilities/ProcessData";
import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import {IGroupedData} from "../../interfaces/GroupedData";
import {getPerformanceColour} from "../../ReportsHelper";
import {getLastPhaseForIndicatorEntry} from "../../../../helpers/ApplicationHelper";

export const ChartAllIndicator3YearAchievement = (
    props: {
        chartName : string,
        indicatorDataEntries : Map<IndicatorResponse, IndicatorDataEntryResponse[]>,
        reportDuration : moment.Moment,
        onChartImageURI: any,
    }
): JSX.Element => {

    const {
        chartName,
        indicatorDataEntries,
        reportDuration,
        onChartImageURI
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const yearsCount = 3;

    const processesGroupedData = processDataGrouped(
        Array.from(indicatorDataEntries.entries()).map(([indicator, indicatorDataEntryResponse]) => ({indicator, indicatorDataEntries : indicatorDataEntryResponse}))
        , IndicatorDataType.AURA_REPORTING, selectedLanguage
    )

    const getLatestIndicatorDataEntryResponseInYear = (indicatorDataEntriesForYear : IGroupedData[]) : IGroupedData => {
        return indicatorDataEntriesForYear
            .filter(indicatorDataEntry => getLastPhaseForIndicatorEntry(indicatorDataEntry.indicatorDataEntry) !== undefined)
            .sort(
                (entry1, entry2) =>
                    moment(entry1.indicatorDataEntry.recordingDate).isBefore(moment(entry2.indicatorDataEntry.recordingDate)) ? 1 : -1
            )[0];
    }

    const getBarChartGroupDataForIndicatorsYears = (): BarChartGroupedDataType[] => {
        const currentYear = reportDuration.year();
        let years: number[] = [];
        let data: BarChartGroupedDataType[] = [];

        for (let i = yearsCount - 1; i >=0; i--) {
            years.push(currentYear - i);
        }

        processesGroupedData.forEach(groupedSeriesData => {
            const entries = groupedSeriesData.groupedData;
            const values: number[] = [];
            const targets: number[] = [];
            const stars: number[] = [];
            const barNames: string[] = [];
            const legendNames: string[] = [];
            const colouringFunctions: any[] | null = [];
            years.map(year => {
                if (entries[year]) {
                    const lastEntry = getLatestIndicatorDataEntryResponseInYear(entries[year]);
                    values.push(lastEntry.value);
                    targets.push(lastEntry.target);
                    stars.push(lastEntry.stars);
                    barNames.push(year.toString());
                    legendNames.push(`${year.toString()} : ${groupedSeriesData.indicator.name[selectedLanguage]}`);
                    colouringFunctions.push((performanceScore : number) => getPerformanceColour(performanceScore, lastEntry.indicator.indicatorNumber));
                } else {
                    values.push(0);
                    targets.push(-1);
                    stars.push(0);
                    barNames.push(year.toString());
                    legendNames.push(`${year.toString()} : ${groupedSeriesData.indicator.name[selectedLanguage]}`);
                    colouringFunctions.push(null);
                }
            });

            data.push({
                legendNames,
                barNames,
                values,
                targets,
                stars,
                colouringFunctions
            })
        });

        return data;
    }


    return (
        <>
            <BarChartGrouped
                getChartImageURI={onChartImageURI}
                chartName={chartName}
                chartId={'all_indicators_report_5'}
                groups={processesGroupedData.map(groupedSeriesData => {
                    return {
                        title: groupedSeriesData.indicator.name[selectedLanguage].split("-")[0].trim(),
                        cols: yearsCount,
                    }
                })}
                data={getBarChartGroupDataForIndicatorsYears()} />
        </>
    )
}
