import {IndicatorResponse, LocaleString} from "../../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import moment from "moment";
import {ICompany} from "../../../../routes/CompaniesList";
import {Flex, Heading} from "@chakra-ui/react";
import {ColumnDataType, Table} from "../../GenericCharts/Table";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {computeFinalScore} from "../../utilities/ProcessData";
import {getLastPhaseForIndicatorEntry} from "../../../../helpers/ApplicationHelper";
import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import {getPerformanceColour} from "../../ReportsHelper";
import {IndicatorNumber} from "../../../../enums/IndicatorNumber";

export const TableQuarterIndicatorAchievementCompanyWise = (
    props: {
        chartName : string,
        indicatorDataEntriesForEachCompany : Map<ICompany, Map<IndicatorResponse, IndicatorDataEntryResponse | null> | null>,
        reportDuration : moment.Moment,
        onChartImageURI: any,
    }
): JSX.Element => {


    const {
        chartName,
        indicatorDataEntriesForEachCompany,
        reportDuration,
        onChartImageURI
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const allIndicatorMap : Map<IndicatorNumber, IndicatorResponse> = new Map<IndicatorNumber, IndicatorResponse>();

    Array.from(indicatorDataEntriesForEachCompany.values()).forEach(
        indicatorDataEntriesForCompany => {
            if (indicatorDataEntriesForCompany) {
                Array.from(indicatorDataEntriesForCompany.keys()).forEach(
                    indicatorResponse => {
                        if (!allIndicatorMap.has(indicatorResponse.indicatorNumber)) {
                            allIndicatorMap.set(indicatorResponse.indicatorNumber, indicatorResponse);
                        }
                    }
                )
            }
        }
    )

    const allIndicators : IndicatorResponse[] = Array.from(allIndicatorMap.values());

    const tableData : ColumnDataType[] = Array.from(indicatorDataEntriesForEachCompany.entries()).map(
        ([companyDetails, indicatorDataEntriesForCompany]) => {
            if(indicatorDataEntriesForCompany) {
                return {
                    header: companyDetails.companyName,
                    singleValues: undefined,
                    stars: Array.from(indicatorDataEntriesForCompany.entries()).map(
                        ([indicatorResponse, indicatorDataEntryResponse]) => {
                            const lastPhaseData = indicatorDataEntryResponse ? getLastPhaseForIndicatorEntry(indicatorDataEntryResponse) : null;
                            return lastPhaseData ? parseInt(lastPhaseData['reliability'].value || '0') : 0;
                        }
                    ),
                    values: Array.from(indicatorDataEntriesForCompany.entries()).map(
                        ([indicatorResponse, indicatorDataEntryResponse]) => {
                            const lastPhaseData = indicatorDataEntryResponse ? getLastPhaseForIndicatorEntry(indicatorDataEntryResponse) : null;
                            return lastPhaseData
                                ? computeFinalScore(lastPhaseData, indicatorResponse, IndicatorDataType.AURA_REPORTING).score
                                : null;
                        }
                    ),
                    notApplicable : Array.from(indicatorDataEntriesForCompany.entries()).map(
                        ([indicatorResponse, indicatorDataEntryResponse]) =>
                            null
                    ),
                    notReported : Array.from(indicatorDataEntriesForCompany.entries()).map(
                        ([indicatorResponse, indicatorDataEntryResponse]) =>
                            null
                    ),
                    targets: Array.from(indicatorDataEntriesForCompany.entries()).map(
                        ([indicatorResponse, indicatorDataEntryResponse]) => {
                            const lastPhaseData = indicatorDataEntryResponse ? getLastPhaseForIndicatorEntry(indicatorDataEntryResponse) : null;
                            return lastPhaseData ? parseInt(lastPhaseData['target'].value || '100') : 100;
                        }
                    ),
                    coloringFunctions: Array.from(indicatorDataEntriesForCompany.entries()).map(
                        ([indicatorResponse, indicatorDataEntryResponse]) => {
                            return (performanceScore : number) =>
                                getPerformanceColour(performanceScore, indicatorResponse.indicatorNumber);
                        }
                    ),
                    iconFunction: null
                }
            }
            return {
                header: companyDetails.companyName,
                singleValues: undefined,
                stars: Object.values(IndicatorNumber).map(indicatorNumber => 0),
                values: Object.values(IndicatorNumber).map(indicatorNumber => null),
                notApplicable : Object.values(IndicatorNumber).map(indicatorNumber => null),
                notReported : Object.values(IndicatorNumber).map(indicatorNumber => null),
                targets: Object.values(IndicatorNumber).map(indicatorNumber => 100),
                coloringFunctions: [],
                iconFunction: null
            }
        }
    )

    const getTableColumnsFromSeriesData = (): ColumnDataType[] => {
        return [
            {
                header: t('Indicators'),
                singleValues: allIndicators.map(indicatorDetails => indicatorDetails.name[selectedLanguage]),
                stars: [],
                values: [],
                notApplicable : [],
                notReported : [],
                targets: [],
                coloringFunctions: [],
                iconFunction: null
            },
            ...tableData
        ];
    }

    return (
        <>
            <Flex flexDirection={"column"}>
                <Heading size={'md'} mb={5}>{chartName}</Heading>
                <Table
                    name={chartName}
                    tableId={chartName}
                    columns={getTableColumnsFromSeriesData()}
                    noOfRows={allIndicators.length}
                />
            </Flex>
        </>
    )
}
