export const getIconFunctionForTable = () => {
    return (value: number, target: number): string => {
        if (value / target <= 0.5) {
            return '❌';
        } else if (value / target < 1 && value / target > 0.5) {
            return '📈';
        }
        return '✅';
    }
};
