export enum Report {
    REPORT_1_REPORTING = 'REPORT_1_REPORTING',
    REPORT_2_REPORTING = 'REPORT_2_REPORTING',
    REPORT_3_REPORTING = 'REPORT_3_REPORTING',
    REPORT_4_REPORTING = 'REPORT_4_REPORTING',
    REPORT_5_REPORTING = 'REPORT_5_REPORTING',
    REPORT_6_REPORTING = 'REPORT_6_REPORTING',

    REPORT_1_COMPLEMENTARY = 'REPORT_1_COMPLEMENTARY',
    REPORT_2_COMPLEMENTARY = 'REPORT_2_COMPLEMENTARY',

    REPORT_1_USP = 'REPORT_1_USP',
    REPORT_2_USP = 'REPORT_2_USP',
}
