import React, {ErrorInfo, Suspense} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {Alert, Box, Center, ChakraProvider, Divider, Flex, Heading, Image, Text} from '@chakra-ui/react'
import { Login } from './routes/Login';
import {CompaniesList} from "./routes/CompaniesList";
import {Indicator, IndicatorDetailsOuter} from "./routes/Indicator";
import 'bootstrap/dist/css/bootstrap.min.css';
import {SignUp} from "./routes/SignUp";
import {ManageLocalUsersAndUserGroups} from "./routes/ManageLocalUsersAndUserGroups";
import {refreshToken} from "./services/GlobalUserService";
import {ManageGlobalUserGroups} from "./components/ManageGlobalUserGroups";
import {LanguageSelector} from "./LanguageSelector";
import fetchIntercept from 'fetch-intercept';
import {checkUserLoggedIn} from "./utilities/AuthUtil";
import {
    getCompanyIdSelectedFromLocalStorage,
    getIdTokenFromLocalStorage,
    getLocalUserIdIdSelectedFromLocalStorage
} from "./utilities/LocalStorageUtil";
import {ForgotPassword} from "./routes/ForgotPassword";
import {ViewReportsOuter} from "./components/ViewReportsOuter";
import {ViewMunicipalityReports} from "./components/reports/ViewMunicipalityReports";
import {CrossMunicipalityReports} from "./components/reports/CrossMunicipalityReports";
import {ManageGlobalUsers} from "./components/ManageGlobalUsers";

interface IProps {
}

interface IState {
    hasError: boolean;
    error?: Error;
}

class ErrorBoundary extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        this.setState({ error })
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Flex alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                <Heading>Something went wrong</Heading>
                <Image borderRadius={4}
                       width={'30vw'}
                       src={"/HTML-Error-Page.png"}
                       alt='Something went wrong' />
                <Alert colorScheme={'red'}>
                    <Flex justifyContent={'center'} flexDirection={'column'}>
                        <Text>{this.state.error?.toString()}</Text>
                        <Divider />
                        <Text>{this.state.error?.stack?.toString()}</Text>
                    </Flex>
                </Alert>
            </Flex>;
        }

        return <>{this.props.children}</>;
    }
}
// <script type="text/javascript" src="https://unpkg.com/xlsx@0.15.1/dist/xlsx.full.min.js"></script>

export const MunicipalityReportPath = '/municipality_report';
export const CrossMunicipalityReportPath = '/cross_municipality_report';

const BaseApp = () => {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="login" element={<Login />}/>
                    <Route path="forgotPassword" element={<ForgotPassword />}/>
                    <Route path="companies" element={<CompaniesList />}/>
                    <Route path="indicators" element={<Indicator />}/>
                    <Route path="indicators/details" element={<IndicatorDetailsOuter />}/>
                    <Route path="indicators/details/reports" element={<ViewReportsOuter />}/>
                    <Route path="signup" element={<SignUp />}/>
                    <Route path="local-users-and-user-groups" element={<ManageLocalUsersAndUserGroups />}/>
                    <Route path="global-users" element={<ManageGlobalUsers />}/>
                    <Route path="manageGlobalUserGroups" element={<ManageGlobalUserGroups isActive={true} />}/>
                    <Route path="manageGlobalUserGroups" element={<ManageGlobalUserGroups isActive={true} />}/>
                    <Route path={MunicipalityReportPath} element={<ViewMunicipalityReports />}/>
                    <Route path={CrossMunicipalityReportPath} element={<CrossMunicipalityReports />}/>
                    <Route
                        path="*"
                        element={<Navigate to={"indicators"} />}
                    />
                </Routes>
            </Suspense>
        </Router>
    );
}

fetchIntercept.register({
    request: async function (url, config) {
        // Modify the url or config here
        if (
            !(
                url.search('refreshToken') !== -1 ||
                url.search('signIn') !== -1 ||
                url.search('signup') !== -1 ||
                url.search('confirmSignup') !== -1 ||
                url.search('forgotPassword') !== -1 ||
                url.search('setNewPassword') !== -1
            ) && !checkUserLoggedIn()
        ) {
            await refreshToken();
        }

        const idToken = getIdTokenFromLocalStorage();
        const companyIdSelectedFromLocalStorage = getCompanyIdSelectedFromLocalStorage();
        const localUserIdSelectedFromLocalStorage = getLocalUserIdIdSelectedFromLocalStorage();

        if(!config?.headers) {
            config.headers = {
                "Authorization": `companyId=${companyIdSelectedFromLocalStorage},localUserId=${localUserIdSelectedFromLocalStorage},idToken=${idToken},accessToken=dummy2`,
                "Content-Type": "application/json"
            };
        }
        else if(config?.headers['Authorization']){
            const headers = Object.fromEntries(
                config?.headers['Authorization']
                    .split(',')
                    // @ts-ignore
                    .map(pair => pair.split('='))
                    // @ts-ignore
                    .map(([key, value]) => [key, isNaN(value) ? value : +value])
            );
            config.headers = {
                "Authorization": `companyId=${headers['companyId']},localUserId=${headers['localUserId']},idToken=${idToken},accessToken=dummy2`,
                ...(config.headers['Content-Type'] !== null && {"Content-Type": config.headers['Content-Type'] ? config.headers['Content-Type'] : "application/json"}),
            }
        }
        else {
            config.headers = {
                "Authorization": `companyId=${companyIdSelectedFromLocalStorage},localUserId=${localUserIdSelectedFromLocalStorage},idToken=${idToken},accessToken=dummy2`,
                ...(config.headers['Content-Type'] !== null && {"Content-Type": config.headers['Content-Type'] ? config.headers['Content-Type'] : "application/json"}),
            }
        }

        return [url, config];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the reponse object
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});

export const App = () => {
    return (
        <ChakraProvider>
            <ErrorBoundary>
                <BaseApp />
                <LanguageSelector />
            </ErrorBoundary>
        </ChakraProvider>
    );
}
