import {checkGlobalUserGroupMembership, getAllGlobalUserGroups} from "../services/GlobalUserGroupService";
import {GlobalUserGroupResponse} from "../entities/response/globalUserGroup/GlobalUserGroupResponse";
import {showApplicationAdminsManagementFeatureFlag} from "../constants/ApplicationConstants";
import {setGlobalUserPermissionsInLocalStorage} from "./LocalStorageUtil";

export interface MembershipStatus {
    status: boolean;
    globalUserGroupName: string;
}

export const getPermissions = async (): Promise<MembershipStatus[]> => {
    const globalUserGroups: GlobalUserGroupResponse[] = await getAllGlobalUserGroups()
    const membershipStatus : MembershipStatus[] = (await Promise.all(globalUserGroups.map(async globalUserGroup => {
        return {
            status: await checkGlobalUserGroupMembership(globalUserGroup.name),
            globalUserGroupName: globalUserGroup.name
        };
    }))).filter(membership => membership.status);

    const applicationAdminMembershipStatus = membershipStatus.find(m => m.globalUserGroupName === 'Application Admins');
    const auraUserManagerMembershipStatus = membershipStatus.find(m => m.globalUserGroupName === 'AURA - User Managers');
    const auraManagementMembershipStatus = membershipStatus.find(m => m.globalUserGroupName === 'AURA - Management');
    const auraMemberMembershipStatus = membershipStatus.find(m => m.globalUserGroupName === 'AURA - Member');
    const auraReadOnlyMembershipStatus = membershipStatus.find(m => m.globalUserGroupName === 'AURA - Read Only');
    const dnaasUserManagerMembershipStatus = membershipStatus.find(m => m.globalUserGroupName === 'DNAAS - User Manager');
    const dnaasManagementMembershipStatus = membershipStatus.find(m => m.globalUserGroupName === 'DNAAS - Management');
    const dnaasMemberMembershipStatus = membershipStatus.find(m => m.globalUserGroupName === 'DNAAS - Member');
    const dnaasReadOnlyMembershipStatus = membershipStatus.find(m => m.globalUserGroupName === 'DNAAS - Read Only');

    const manageGlobalUserGroupPermission : boolean =
        showApplicationAdminsManagementFeatureFlag ||
        applicationAdminMembershipStatus?.status ||
        auraUserManagerMembershipStatus?.status ||
        auraManagementMembershipStatus?.status ||
        dnaasUserManagerMembershipStatus?.status ||
        dnaasManagementMembershipStatus?.status ||
        false;

    const viewCrossMunicipalityPermission =
        auraMemberMembershipStatus?.status ||
        auraReadOnlyMembershipStatus?.status ||
        dnaasReadOnlyMembershipStatus?.status ||
        dnaasMemberMembershipStatus?.status ||
        false;

    const manageGlobalUsersPermission = applicationAdminMembershipStatus?.status || false;

    setGlobalUserPermissionsInLocalStorage(manageGlobalUserGroupPermission, viewCrossMunicipalityPermission, manageGlobalUsersPermission);

    return membershipStatus;
}
