import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    Heading,
    Input,
    InputGroup,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    OrderedList,
    SimpleGrid,
    Spinner, Text,
    useDisclosure,
    useToast, VStack
} from "@chakra-ui/react";
import React from "react";
import {PopoverForm} from "./ManageUserGroupPopover";
import {useNavigate} from "react-router-dom";
import {checkUserLoggedIn} from "../utilities/AuthUtil";
import {getAllGlobalUsers} from "../services/GlobalUserService";
import {GlobalUserResponse} from "../entities/response/user/GlobalUserResponse";
import {GlobalUserGroupResponse} from "../entities/response/globalUserGroup/GlobalUserGroupResponse";
import {
    addGlobalUserToGlobalUserGroup,
    createGlobalUserGroup,
    getAllGlobalUserGroups
} from "../services/GlobalUserGroupService";
import {SidebarWithHeader} from "./SidebarWithHeader";
import {getPermissions} from "../utilities/PermissionModel";
import {showApplicationAdminsManagementFeatureFlag, toastDuration} from "../constants/ApplicationConstants";
import {navigateToCompaniesPage, navigateToLoginPage} from "../helpers/NavigationHelper";
import {checkIfGlobalUserHasManageGlobalUserGroupPermission} from "../utilities/LocalStorageUtil";
import {useTranslation} from "react-i18next";

const CustomOverlay = () => (
    <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px)'
    />
);

export const ManageGlobalUserGroups = (props: { isActive: boolean }) => {
    const { isActive } = props;
    const [refresh, setRefresh] = React.useState<boolean>(true);
    const [newGlobalUserGroupName, setNewGlobalUserGroupName] = React.useState<string>();
    const [globalUserGroups, setGlobalUserGroups] = React.useState<GlobalUserGroupResponse[] | null>();
    const [globalUserGroupSelected, selectGlobalUserGroup] = React.useState<GlobalUserGroupResponse>();
    const toast = useToast();
    const [globalUsers, setGlobalUsers] = React.useState<GlobalUserResponse[]>();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
    const [globalUserIdToBeAdded, setGlobalUserIdToBeAdded] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        if(!checkIfGlobalUserHasManageGlobalUserGroupPermission()) {
            navigateToCompaniesPage(navigate);
        }
    }, [refresh])

    const { t } = useTranslation();

    React.useEffect(() => {
        if (!checkUserLoggedIn()) {
            navigateToLoginPage(navigate)
        }
    }, []);

    React.useEffect(() => {
        setGlobalUserIdToBeAdded(globalUsers?.length ? globalUsers[0]._id : undefined)
    }, [globalUsers]);

    React.useEffect(() => {
        if (isActive && refresh) {

            getAllGlobalUsers()
                .then((globalUsersResponse : GlobalUserResponse[]) => {
                    setGlobalUsers(globalUsersResponse);
                })
                .catch(err => {
                    toast({
                        title: t('Failed to load all users'),
                        description: t(err?.message || ''),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                })

            getAllGlobalUserGroups()
                .then((globalUserGroupsResponse : GlobalUserGroupResponse[]) => {
                    getPermissions()
                        .then(memberships => {

                            if(!checkIfGlobalUserHasManageGlobalUserGroupPermission()) {
                                navigateToCompaniesPage(navigate);
                            }

                            const applicationAdminMembershipStatus = memberships.find(m => m.globalUserGroupName === 'Application Admins');
                            const auraUserManagerMembershipStatus = memberships.find(m => m.globalUserGroupName === 'AURA - User Managers');
                            const auraManagementMembershipStatus = memberships.find(m => m.globalUserGroupName === 'AURA - Management');
                            const dnaasUserManagerMembershipStatus = memberships.find(m => m.globalUserGroupName === 'DNAAS - User Manager');
                            const dnaasManagementMembershipStatus = memberships.find(m => m.globalUserGroupName === 'DNAAS - Management');

                            setGlobalUserGroups(globalUserGroupsResponse.filter(gug => {
                                switch (gug.name) {
                                    case 'Application Admins':
                                        return showApplicationAdminsManagementFeatureFlag || applicationAdminMembershipStatus;
                                    case 'AURA - Management':
                                        return applicationAdminMembershipStatus?.status || auraManagementMembershipStatus?.status || false;
                                    case 'DNAAS - Management':
                                        return applicationAdminMembershipStatus?.status || dnaasManagementMembershipStatus?.status || false;
                                    case 'AURA - User Managers':
                                        return auraManagementMembershipStatus?.status || false;
                                    case 'DNAAS - User Manager':
                                        return dnaasManagementMembershipStatus?.status || false;
                                    case 'AURA - Member':
                                    case 'AURA - Read Only':
                                        return auraUserManagerMembershipStatus?.status || auraManagementMembershipStatus?.status || false;
                                    case 'DNAAS - Member':
                                    case 'DNAAS - Read Only':
                                        return dnaasUserManagerMembershipStatus?.status || dnaasManagementMembershipStatus?.status || false;
                                    default:
                                        return false;
                                }
                            }));
                        });
                    setRefresh(false);
                })
                .catch(err => {
                    toast({
                        title: t('Failed to load multi company accesses'),
                        description: t(err?.message || ''),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    setRefresh(false);
                })
        }
    }, [isActive, refresh]);

    const addGlobalUser = () => {
        if(globalUserIdToBeAdded && globalUserGroupSelected?._id) {
            setIsLoading(true)
            addGlobalUserToGlobalUserGroup(globalUserIdToBeAdded, globalUserGroupSelected?._id)
                .then((globalUserGroupResponse : GlobalUserGroupResponse) => {
                    toast({
                        title: t('Added User'),
                        description: `${t('Success')}!`,
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    onClose();
                    setRefresh(true);
                    setIsLoading(false)
                }).catch(err => {
                    toast({
                        title: t('Failed to add user to user group'),
                        description: t(err?.message || ''),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    onClose();
                    setRefresh(true);
                    setIsLoading(false)
                })
        }
    };

    const handleOptionChange = (event: { target: HTMLSelectElement }) => {
        setGlobalUserIdToBeAdded(event.target.value);
    };

    return (
        <SidebarWithHeader
            isNonCompanySpecificPage={true}
        >
            <Container maxW='container.xl'>
                <Flex w={"100%"} mt={5} mb={5} justify={"start"}>
                    <Heading>{t('Manage Multi Company Accesses')}</Heading>
                </Flex>
                {
                    globalUserGroups ? (
                        <Box w={"100%"}>
                            <Flex direction={'column'} w={"100%"}>
                                <SimpleGrid h={"90%"} columns={2} spacing={10}>
                                    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"} isCentered>
                                        <CustomOverlay />
                                        <ModalContent>
                                            <ModalHeader>{t('Add User')}</ModalHeader>
                                            <ModalCloseButton />
                                            <ModalBody m={5}>
                                                <Flex flexDirection={'column'}>
                                                    <select
                                                        defaultValue={globalUsers?.length ? globalUsers[0]._id : undefined}
                                                        onChange={handleOptionChange} name="emailAddresses" id="emailAddress">
                                                        {
                                                            globalUsers?.map((user: GlobalUserResponse, idx) => {
                                                                return (
                                                                    <option value={user._id} key={'globalUsers-'+idx}>{user.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                    <Button
                                                        mt={8}
                                                        colorScheme={"gray"}
                                                        isLoading={isLoading}
                                                        onClick={addGlobalUser}>
                                                        {t('Add')}
                                                    </Button>
                                                </Flex>
                                            </ModalBody>
                                        </ModalContent>
                                    </Modal>
                                    <Box h='100%' w={'100%'}>
                                        <OrderedList>
                                            {
                                                !globalUserGroups ? <Center w={'100%'}><Spinner /></Center> : null
                                            }
                                            {globalUserGroups?.map((globalUserGroup: GlobalUserGroupResponse, idx) => {
                                                return (
                                                    <ListItem mt={5} onClick={() => selectGlobalUserGroup(globalUserGroup)} key={`user-group-${idx}`} style={{cursor: "pointer"}}>
                                                        <PopoverForm
                                                            setRefresh={setRefresh}
                                                            onUserAddModalOpen={onOpen}
                                                            name={globalUserGroup.name}
                                                            globalUsers={globalUsers}
                                                            globalUserGroupSelected={globalUserGroupSelected}
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                        </OrderedList>
                                    </Box>
                                </SimpleGrid>
                            </Flex>
                        </Box>
                    ) : (
                        <VStack h={"80vh"} justify={'center'}>
                            <Spinner size='xl' />
                            <Text>{t("Loading multi company accesses, please wait")}!</Text>
                        </VStack>
                    )
                }
            </Container>
        </SidebarWithHeader>
    );
}
