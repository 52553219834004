import React from "react";
import {
    Box, Button, Center,
    Container,
    Flex,
    Heading,
    Spinner, Text, Tooltip,
    useToast, VStack
} from "@chakra-ui/react";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {checkUserLoggedIn} from "../utilities/AuthUtil";
import {GlobalUserResponse} from "../entities/response/user/GlobalUserResponse";
import {getAllGlobalUsers, patchGlobalUserById} from "../services/GlobalUserService";
import {
    checkIfGlobalUserHasManageGlobalUsersPermissionPermission, getGlobalUserFromLocalStorage,
} from "../utilities/LocalStorageUtil";
import {toastDuration} from "../constants/ApplicationConstants";
import {navigateToCompaniesPage, navigateToLoginPage} from "../helpers/NavigationHelper";
import {SidebarWithHeader} from "./SidebarWithHeader";

export const ManageGlobalUsers = (props: {

}) => {
    const {

    } = props;

    const [refresh, setRefresh] = React.useState<boolean>(true);

    const toast = useToast();

    const [globalUsers, setGlobalUsers] = React.useState<GlobalUserResponse[] | undefined >();
    const [isGlobalUserStatusUpdateLoading, setIsGlobalUserStatusUpdateLoading] = React.useState<boolean>();
    const [globalUserIndexSelected, setGlobalUserIndexSelected] = React.useState<number | undefined>();

    const { t } = useTranslation();

    const navigate = useNavigate();

    React.useEffect(() => {
        if (!checkUserLoggedIn()) {
            navigateToLoginPage(navigate)
        }
    }, []);

    React.useEffect(() => {
        if(!checkIfGlobalUserHasManageGlobalUsersPermissionPermission()) {
            navigateToCompaniesPage(navigate);
        }
    }, [refresh])

    React.useEffect(() => {

    }, []);

    React.useEffect(() => {
        if (refresh) {
            getAllGlobalUsers()
                .then((globalUsers : GlobalUserResponse[]) => {
                    setGlobalUsers(globalUsers);
                    setRefresh(false);
                })
                .catch(err => {
                    toast({
                        title: t('Failed to load all users'),
                        description: t(err?.message?.toString()),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    return;
                })
        }
    }, [refresh]);

    const enableDisableGlobalUser = (indexSelected : number, globalUserResponse : GlobalUserResponse, newStatus : boolean) => {
        if(globalUserResponse !== null && newStatus !== null) {
            setGlobalUserIndexSelected(indexSelected);
            setIsGlobalUserStatusUpdateLoading(true);

            patchGlobalUserById(
                globalUserResponse._id,
                {enabled : newStatus}
            ).then( async (updatedGlobalUserResponse : GlobalUserResponse) => {
                setGlobalUsers(
                    globalUsers?.map(
                        globalUser =>
                            globalUser._id === globalUserResponse._id ? globalUserResponse : globalUser
                    )
                )
                toast({
                    title: `${updatedGlobalUserResponse?.name} ${newStatus ? t('Enabled') : t('Disabled')}`,
                    description: '',
                    status: 'success',
                    duration: toastDuration,
                    isClosable: true
                });
                setRefresh(true);
            }).catch(err => {
                toast({
                    title: `${t('Failed to')} ${newStatus ? t('Enable') : t('Disable')} ` + globalUserResponse.name,
                    description: err.message ? err.message.toString() : "",
                    status: 'error',
                    duration: toastDuration,
                    isClosable: true
                });
            }).finally(() => {
                setIsGlobalUserStatusUpdateLoading(false);
                setGlobalUserIndexSelected(undefined);
            })
        }

    }

    return (

        <SidebarWithHeader
            isNonCompanySpecificPage={true}
        >
            <Container maxW='container.xl'>
                <Flex w={"100%"} mt={5} mb={10} justify={"start"}>
                    <Heading>{t('Manage All Users')}</Heading>
                </Flex>
                {
                    globalUsers ? (
                        <Box w={"100%"}>
                            <MDBTable bordered striped>
                                <MDBTableHead>
                                    <tr className={'table-dark'}>
                                        <th>{t("Name")}</th>
                                        <th>{t("Email Address")}</th>
                                        <th></th>
                                    </tr>
                                </MDBTableHead>

                                <MDBTableBody>
                                    {globalUsers?.map((globalUser: GlobalUserResponse, idx : number) => {
                                        return (
                                            <tr key={`user-details-list-${globalUser.email}`}>
                                                <td>{globalUser.name}</td>
                                                <td>{globalUser.email}</td>
                                                <td>
                                                    {
                                                        globalUser.email !== getGlobalUserFromLocalStorage()?.email && (
                                                            <Center h={"100%"} w={"100%"}>
                                                                <Tooltip
                                                                    label={
                                                                        `${globalUser.enabled ? t("Disable") : t("Enable")} ${globalUser.name} (${globalUser.email})`
                                                                    }
                                                                    ml={5}
                                                                    mr={5}
                                                                >
                                                                    <Button
                                                                        alignSelf={"center"}
                                                                        aria-label={globalUser.enabled ? t("Disable") : t("Enable")}
                                                                        isLoading={
                                                                            isGlobalUserStatusUpdateLoading
                                                                            && globalUserIndexSelected === idx
                                                                        }
                                                                        isDisabled={isGlobalUserStatusUpdateLoading}
                                                                        colorScheme={globalUser.enabled ? 'red' : 'green'}
                                                                        onClick={() => enableDisableGlobalUser(idx, globalUser, !globalUser.enabled)}
                                                                    >
                                                                        {
                                                                            globalUser.enabled
                                                                                ? t("Disable")
                                                                                : t("Enable")
                                                                        }
                                                                    </Button>
                                                                </Tooltip>
                                                            </Center>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </MDBTableBody>
                            </MDBTable>
                        </Box>
                    ) : (
                        <VStack h={"80vh"} justify={'center'}>
                            <Spinner size='xl' />
                            <Text>{t("Loading all users, please wait")}!</Text>
                        </VStack>
                    )
                }
            </Container>
        </SidebarWithHeader>
    );
}
