import * as React from 'react';
import ApexCharts from "apexcharts";
import {Alert, Box, Flex, Grid, Heading, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {getHighColour, getLowColour, getMediumColour} from "../ReportsHelper";

export interface BarChartDataType {
    value: number;
    stars: number;
    colouringFunction: ((value: number) => string) | null;
    barName: string;
    legendName: string;
    target: number;
}

export interface IBarChartProps {
    chartName: string;
    chartId: string;
    yaxis: string;
    data: BarChartDataType[];
    getChartImageURI?: (chartId: string, charName: string, data: any) => void;
}

export const BarChart = (props: IBarChartProps): JSX.Element => {
    const { chartName, data, chartId, yaxis } = props;

    const {t, i18n} = useTranslation();

    const convertDataForRender = (data: BarChartDataType[]) => {
        return data.map(dataSegment => {
            return {
                x: dataSegment.barName,
                y: dataSegment.value,
                fillColor: dataSegment.colouringFunction ? dataSegment.colouringFunction(dataSegment.value) : '#EB8C87',
                goals: dataSegment.target === -1 ? [] : [
                    {
                        name: 'Target',
                        value: dataSegment.target,
                        strokeHeight: 5
                    }
                ]
            }
        });
    }

    const options = {
        plotOptions: {
            bar: {
                columnWidth: 40,
                dataLabels: {
                    orientation: 'vertical',
                    position: 'top'
                }
            }
        },
        chart: {
            type: 'bar',
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'end',
            formatter: (val: number, config: any) => "⭐".repeat(data[config.dataPointIndex]?.stars ?? 0),
        },
        yaxis: {
            max: 100,
            title: {
                text: yaxis
            },
        },
        xaxis: {
            labels: {
                formatter: (val: string, config: any) => data[config.dataPointIndex]?.legendName ?? val
            },
        },
        series: [{
            name: t('Achieved'),
            data: convertDataForRender(data)
        }]
    }

    const renderApexChart = React.useCallback(() => {
        const chartElement = document.querySelector("#" + chartId);

        if (chartElement) {
            chartElement.innerHTML = "";
            const chart = new ApexCharts(chartElement, options);
            chart.render().then(() => {
                window.setTimeout(function() {
                    chart.dataURI({ scale: 2 }).then((uri) => {
                        props.getChartImageURI && props.getChartImageURI(props.chartId, props.chartName, uri);
                    })
                }, 1000)
            });
        }
    }, [chartId]);

    React.useEffect(() => {
        document.addEventListener("DOMContentLoaded", function(event) {
            renderApexChart();
        });
    });

    React.useEffect(() => {
        renderApexChart();
    }, [data]);

    return (
        <Grid mb={10}>
            <Heading size={"md"} mb={5}>{chartName}</Heading>
            <div id={chartId}></div>
            <Alert status={'warning'}>
                <Box>
                    <Flex>
                        <Text mr={10}>{t("Reliability")} : </Text>
                        <Text mr={5}>⭐ {t("Low")}</Text>
                        <Text mr={5}>⭐⭐ {t("Medium")}</Text>
                        <Text mr={5}>⭐⭐⭐ {t("High")}</Text>
                        <Flex mr={5} alignItems={"center"}><Box w={6} mr={1} h={1} bg={'#a8a8a8'}></Box><Text>{t("Target")}</Text></Flex>
                    </Flex>
                    <Flex>
                        <Box>
                            <Text mr={10}>{t("Performance")} : </Text>
                        </Box>
                        <Flex alignItems={"center"} mr={5}>
                            <Box w={3} mr={1} h={3} bg={getLowColour()}></Box>
                            <Text >{t("Low Performance")}</Text>
                        </Flex>
                        <Flex alignItems={"center"} mr={5}>
                            <Box w={3} mr={1} h={3} bg={getMediumColour()}></Box>
                            <Text >{t("Medium Performance")}</Text>
                        </Flex>
                        <Flex alignItems={"center"} mr={5}>
                            <Box w={3} mr={1} h={3} bg={getHighColour()}></Box>
                            <Text >{t("High Performance")}</Text>
                        </Flex>
                    </Flex>
                </Box>
            </Alert>
        </Grid>
    );
}
