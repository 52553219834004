import {IndicatorDataType} from "../../enums/IndicatorDataType";

const getValueLabel = (currentPhaseIndex: number, indicatorDataType: IndicatorDataType, t : any) => {
    if(currentPhaseIndex === 0) {
        return t('Reported Data')
    }
    else if(currentPhaseIndex === 1) {
        return 'abcd'
    }
    else if(currentPhaseIndex === 2) {
        return t('Post Verification Data')
    }
    else if(currentPhaseIndex === 3) {
        return t('Validated Data')
    }
    else if(currentPhaseIndex === 4) {
        return 'abcd'
    }
    else if(currentPhaseIndex === 5) {
        return t('Final Data')
    }
    return 'abcd'
}

const getNoteLabel = (currentPhaseIndex: number, indicatorDataType: IndicatorDataType, t : any) => {
    const nameOfEntity = indicatorDataType === IndicatorDataType.DNAAS_USP ? 'DNAAS' : 'AURA';

    if(currentPhaseIndex === 0) {
        return t('Utility Notes')
    }
    else if(currentPhaseIndex === 1) {
        return `${nameOfEntity}'s ${t('Comments')}`
    }
    else if(currentPhaseIndex === 2) {
        return t('Utility Notes')
    }
    else if(currentPhaseIndex === 3) {
        return `${nameOfEntity}'s ${t('Comments on Data Values')}`
    }
    else if(currentPhaseIndex === 4) {
        return t('Utility Notes')
    }
    else if(currentPhaseIndex === 5) {
        return `${nameOfEntity}'s ${t('Response')}`
    }
    return 'abcd'
}

const getAdditionalNoteLabel = (currentPhaseIndex: number, indicatorDataType: IndicatorDataType, t : any) => {
    const nameOfEntity = indicatorDataType === IndicatorDataType.DNAAS_USP ? 'DNAAS' : 'AURA';

    if(currentPhaseIndex === 0) {
        return 'abcd'
    }
    else if(currentPhaseIndex === 1) {
        return `abcd`
    }
    else if(currentPhaseIndex === 2) {
        return 'abcd'
    }
    else if(currentPhaseIndex === 3) {
        return `${nameOfEntity}'s ${t('Comments on Data Variation')}`
    }
    else if(currentPhaseIndex === 4) {
        return 'abcd'
    }
    else if(currentPhaseIndex === 5) {
        return `abcd`
    }
    return 'abcd'
}

export const displayValue = (recordedData : any, currentPhaseIndex : number, indicatorDataType: IndicatorDataType, t : any) => {
    const res = [];
    if(recordedData.notApplicable) {
        res.push(t('Not Applicable'))
    }
    if(recordedData.notReported) {
        res.push(t('Not Reported'))
    }
    if(recordedData.value !== undefined && recordedData.value !== null) {
        res.push(`${getValueLabel(currentPhaseIndex, indicatorDataType, t)} : ${t(recordedData.value)}`)
    }

    if(recordedData.notes !== undefined && recordedData.notes !== null) {
        res.push(`${getNoteLabel(currentPhaseIndex, indicatorDataType, t)} : ${recordedData.notes}`)
    }

    if(recordedData.additionalNotes !== undefined && recordedData.additionalNotes !== null) {
        res.push(`${getAdditionalNoteLabel(currentPhaseIndex, indicatorDataType, t)} : ${recordedData.additionalNotes}`)
    }

    return res;
}
