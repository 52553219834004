import jwt_decode from "jwt-decode";
import {
    getIdTokenFromLocalStorage,
    setAccessTokenInLocalStorage,
    setIdTokenInLocalStorage,
    setRefreshTokenInLocalStorage
} from "./LocalStorageUtil";

export const checkUserLoggedIn = (): boolean => {
    const idToken: string | null = getIdTokenFromLocalStorage();

    // @ts-ignore
    if (idToken && jwt_decode(idToken).exp) {
        // @ts-ignore
        const expiry = jwt_decode(idToken).exp;
        const now = new Date();

        return now.getTime() < expiry * 1000;
    }

    return false;
}
export const setAuthInLocalStorage = (authResponse: any) => {
    if (Object.keys(authResponse).length) {
        setIdTokenInLocalStorage(authResponse?.idToken);
        setAccessTokenInLocalStorage(authResponse?.accessToken)
        setRefreshTokenInLocalStorage(authResponse?.refreshToken)
    }
}
