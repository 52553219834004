import {
    IndicatorDataColumnEntryResponse
} from "../../entities/response/indicatorDataColumnEntry/IndicatorDataColumnEntryResponse";
import {
    IndicatorDataEntryPhaseStructureResponse,
    IndicatorDataEntryPhaseSupportedDataInputsResponse,
    IndicatorDataTypeEntryColumnDataType,
    IndicatorDataTypeEntryColumnResponse,
    IndicatorResponse
} from "../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataType} from "../../enums/IndicatorDataType";

export const validatePhaseRecord = (
    phaseRecord : {[key : string]: IndicatorDataColumnEntryResponse},
    indicatorDataType : IndicatorDataType,
    indicatorResponse : IndicatorResponse,
    phaseBeingSaved : string
) : boolean => {

    let validRes = true;

    const supportedDataInputs : IndicatorDataEntryPhaseSupportedDataInputsResponse | undefined =
        indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].phaseSequence.find(
            (indicatorDataEntryPhaseStructure : IndicatorDataEntryPhaseStructureResponse) =>
                indicatorDataEntryPhaseStructure.name === phaseBeingSaved
        )?.supportedDataInputs

    if(!supportedDataInputs) return false;

    Object.entries(phaseRecord).forEach(
        ([columnKey, phaseColumnEntryValue]) => {
            if(
                columnKey !== 'reliability' &&
                !validateDataColumnEntryValid(
                    phaseColumnEntryValue,
                    supportedDataInputs,
                    indicatorResponse,
                    columnKey,
                    indicatorDataType,
                    phaseBeingSaved
                )
            ) {
                validRes = false;
            }
        }
    )

    return validRes;
}

const validateDataColumnEntryValid = (
    indicatorDataColumnEntry: IndicatorDataColumnEntryResponse,
    supportedDataInputs: IndicatorDataEntryPhaseSupportedDataInputsResponse,
    indicatorResponse : IndicatorResponse,
    dataColumnName: string,
    indicatorDataType : IndicatorDataType,
    phaseBeingSaved : string
): boolean => {

    const indicatorDataTypeEntryColumnResponse : IndicatorDataTypeEntryColumnResponse | undefined = indicatorResponse.indicatorDataTypeStructureList[indicatorDataType].indicatorDataTypeEntryColumns.find(
        (indicatorDataTypeEntryColumnResponse : IndicatorDataTypeEntryColumnResponse) =>
            indicatorDataTypeEntryColumnResponse.columnName === dataColumnName
    );

    const indicatorDataTypeEntryColumnDataType : IndicatorDataTypeEntryColumnDataType | undefined =
        indicatorDataTypeEntryColumnResponse?.dataValidation.columnDataType

    if(
        (
            (indicatorDataTypeEntryColumnResponse?.notApplicableSupported || []).includes(phaseBeingSaved)
            || (indicatorDataTypeEntryColumnResponse?.notReportedSupported || []).includes(phaseBeingSaved)
        )
        &&
        (
            indicatorDataColumnEntry.notApplicable || indicatorDataColumnEntry.notReported
        )
    ) {
        indicatorDataColumnEntry.value = null;
        indicatorDataColumnEntry.notes = null;
        indicatorDataColumnEntry.additionalNotes = null;
    }

    const notApplicableSkipCheck = !(indicatorDataTypeEntryColumnResponse?.notApplicableSupported || []).includes(phaseBeingSaved)
        || (
            (indicatorDataTypeEntryColumnResponse?.notApplicableSupported || []).includes(phaseBeingSaved)
            && !indicatorDataColumnEntry.notApplicable
        );

    const notReportedSkipCheck = !(indicatorDataTypeEntryColumnResponse?.notReportedSupported || []).includes(phaseBeingSaved)
        || (
            (indicatorDataTypeEntryColumnResponse?.notReportedSupported || []).includes(phaseBeingSaved)
            && !indicatorDataColumnEntry.notReported
        );

    if(
        supportedDataInputs.value
        && notApplicableSkipCheck
        && notReportedSkipCheck
    ) {
        if(
            indicatorDataColumnEntry.value === undefined
            || indicatorDataColumnEntry.value === null
            || indicatorDataColumnEntry.value === ''
        ) {
            if(indicatorDataTypeEntryColumnDataType === IndicatorDataTypeEntryColumnDataType.NUMBER) {
                indicatorDataColumnEntry.value = '0'
            }
            else return false;
        }
    }

    if(
        supportedDataInputs.notes
        && notApplicableSkipCheck
        && notReportedSkipCheck
    ) {
        if(
            indicatorDataColumnEntry.notes === undefined
            || indicatorDataColumnEntry.notes === null
            || indicatorDataColumnEntry.notes === ''
        ) {
            return false;
        }
    }

    if(
        supportedDataInputs.additionalNotes
        && notApplicableSkipCheck
        && notReportedSkipCheck
    ) {
        if(
            indicatorDataColumnEntry.additionalNotes === undefined
            || indicatorDataColumnEntry.additionalNotes === null
            || indicatorDataColumnEntry.additionalNotes === ''
        ) {
            return false;
        }
    }

    return true;
}
