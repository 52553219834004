import {IndicatorDataType} from "../enums/IndicatorDataType";
import moment from "moment";
import {
    getEndOfQuarter,
    getEndOfSemester,
    getEndOfYear,
    getStartOfQuarter,
    getStartOfSemester,
    getStartOfYear
} from "./DateHelper";
import {IndicatorDataEntryResponse} from "../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {
    IndicatorDataColumnEntryResponse
} from "../entities/response/indicatorDataColumnEntry/IndicatorDataColumnEntryResponse";
import {lastPhaseKey} from "../constants/ApplicationConstants";
import {IndicatorNumber} from "../enums/IndicatorNumber";

export const getStartRecordingDateForDate = (date : Date, indicatorDataType : IndicatorDataType) => {
    switch (indicatorDataType) {
        case IndicatorDataType.AURA_REPORTING:
            return moment(getStartOfQuarter(date));
        case IndicatorDataType.AURA_COMPLEMENTARY:
            return moment(getStartOfYear(date));
        case IndicatorDataType.DNAAS_USP:
            return moment(getStartOfSemester(date));
    }
}

export const getEndRecordingDateForDate = (date : Date, indicatorDataType : IndicatorDataType) => {
    switch (indicatorDataType) {
        case IndicatorDataType.AURA_REPORTING:
            return moment(getEndOfQuarter(date));
        case IndicatorDataType.AURA_COMPLEMENTARY:
            return moment(getEndOfYear(date));
        case IndicatorDataType.DNAAS_USP:
            return moment(getEndOfSemester(date));
    }
}

export const getLastPhaseForIndicatorEntry = (
    indicatorDataEntryRes : IndicatorDataEntryResponse
) : ({[key : string]: IndicatorDataColumnEntryResponse} | undefined) => {
    return indicatorDataEntryRes.approvalProcessDataMap[lastPhaseKey];
}


export const getDisplayNameOfIndicatorNumber = (indicatorNumber : IndicatorNumber) : string => {
    switch (indicatorNumber) {
        case IndicatorNumber.INDICATOR_1:
            return 'Indicator 1';
        case IndicatorNumber.INDICATOR_2:
            return 'Indicator 2';
        case IndicatorNumber.INDICATOR_3:
            return 'Indicator 3';
        case IndicatorNumber.INDICATOR_4:
            return 'Indicator 4';
        case IndicatorNumber.INDICATOR_5:
            return 'Indicator 5';
        case IndicatorNumber.INDICATOR_6:
            return 'Indicator 6';
        case IndicatorNumber.INDICATOR_7:
            return 'Indicator 7';
        case IndicatorNumber.INDICATOR_8:
            return 'Indicator 8';
    }
}

