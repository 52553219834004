import * as React from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {IndicatorResponse} from "../../../entities/response/indicator/IndicatorResponse";
import {IndicatorDataEntryResponse} from "../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {getAllIndicators} from "../../../services/IndicatorService";
import {filterIndicatorDataEntriesForIndicator} from "../../../services/IndicatorDataEntryService";
import {EntitySearch} from "../../../entities/request/EntitySearch";
import {FieldSearch} from "../../../entities/request/FieldSearch";
import {SearchTypes} from "../../../enums/SearchTypes";
import {IndicatorDataType} from "../../../enums/IndicatorDataType";
import {getEndRecordingDateForDate, getStartRecordingDateForDate} from "../../../helpers/ApplicationHelper";
import {getEndOfYear} from "../../../helpers/DateHelper";
import {FileType} from "../../../enums/FileType";
import {exportZip} from "../../../utilities/Export";
import {Alert, Box, Button, Center, Flex, Spinner, Text, VStack} from "@chakra-ui/react";
import {TablesAllIndicators3YearAchievementData} from "../reporting/report5/TablesAllIndicators3YearAchievementData";

export const Report2_USP = (
    props: {
        reportDuration : moment.Moment
    }
): JSX.Element => {

    const { reportDuration} = props;

    const {t, i18n} = useTranslation();

    const [indicatorDataEntries, setIndicatorDataEntries] = React.useState<Map<IndicatorResponse, IndicatorDataEntryResponse[]> | null>(null);

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    React.useEffect(() => {

        setIndicatorDataEntries(null);

        if(!reportDuration.isValid()) return;

        setIsLoading(true);

        getAllIndicators()
            .then(
                (indicatorResponses: IndicatorResponse[]) => Promise.all(
                    indicatorResponses.map( async (indicatorResponse) => {

                        const indicatorDataEntriesForIndicator: IndicatorDataEntryResponse[] = await filterIndicatorDataEntriesForIndicator(
                            indicatorResponse.indicatorNumber,
                            new EntitySearch([
                                new FieldSearch(
                                    SearchTypes.VALUE_EQUAL,
                                    'indicatorNumber',
                                    indicatorResponse.indicatorNumber
                                ),
                                new FieldSearch(
                                    SearchTypes.VALUE_EQUAL,
                                    'indicatorDataType',
                                    IndicatorDataType.DNAAS_USP
                                ),
                                new FieldSearch(
                                    SearchTypes.VALUE_GREATER_THAN_EQUAL_TO,
                                    'recordingDate',
                                    getStartRecordingDateForDate(
                                        moment(reportDuration).subtract(9, 'quarter').toDate(), IndicatorDataType.DNAAS_USP
                                    )
                                ),
                                new FieldSearch(
                                    SearchTypes.VALUE_LESS_THAN_EQUAL_TO,
                                    'recordingDate',
                                    getEndRecordingDateForDate(
                                        moment(reportDuration).toDate(), IndicatorDataType.DNAAS_USP
                                    )
                                )
                            ])
                        );

                        return {
                            indicator : indicatorResponse,
                            indicatorDataEntries: indicatorDataEntriesForIndicator
                        }
                    })
                )
            )
            .then((indicatorDataEntriesForIndicators : {
                indicator : IndicatorResponse,
                indicatorDataEntries : IndicatorDataEntryResponse[]
            }[]) => {
                if(
                    indicatorDataEntriesForIndicators.some(
                        indicatorDataEntriesForIndicator =>
                            indicatorDataEntriesForIndicator.indicatorDataEntries.length > 0 && indicatorDataEntriesForIndicator.indicatorDataEntries.some(
                                indicatorDataEntryForIndicator => moment(indicatorDataEntryForIndicator.recordingDate).year() === reportDuration.year()
                            )
                    )
                ) {
                    setIndicatorDataEntries(
                        indicatorDataEntriesForIndicators.reduce(
                            (prev, curr) => prev.set(curr.indicator, curr.indicatorDataEntries),
                            new Map()
                        )
                    )
                }

                setIsLoading(false);
            })
    }, [reportDuration]);


    const onChartImageURI = (chartId: string, chartName: string, data: any) => {
        imageZipConfigs.current = imageZipConfigs.current.filter(elem => elem.id !== chartId);
        imageZipConfigs.current.push({
            id: chartId,
            name: chartName,
            data: data.imgURI,
            type: FileType.image
        });
    };

    const imageZipConfigs = React.useRef<{id: string; name: string; type: FileType, data: any}[]>([]);

    const _exportZip = React.useCallback(() => {
        const tablesZipConfig = [
            {
                name: t("Evolution Of Reporting Data"),
                id: 'report5',
                type: FileType.excel
            },
            ...imageZipConfigs.current
        ];
        exportZip(tablesZipConfig);
    }, [imageZipConfigs, indicatorDataEntries]);

    return (
        <>
            {
                isLoading ? (
                    <>
                        <VStack h={"80vh"} justify={'center'}>
                            <Spinner size='xl' />
                            <Text>{t("Loading report, please wait")}!</Text>
                        </VStack>
                    </>
                ) : (
                    <>
                        {
                            reportDuration.isValid() ? (
                                <>
                                    {
                                        indicatorDataEntries ? (
                                            <>
                                                <Flex flexDirection={"column"}>
                                                    <Button mt="2" width={"fit-content"} alignSelf={"end"} colorScheme={'green'} onClick={_exportZip}>{t("Export ZIP")}</Button>
                                                </Flex>

                                                <Center>
                                                    <Box m={10} width={"80%"}>
                                                        <TablesAllIndicators3YearAchievementData
                                                            chartName={t("USP Data for past 3 years")}
                                                            indicatorDataEntries={indicatorDataEntries}
                                                            indicatorDataType={IndicatorDataType.DNAAS_USP}
                                                            reportDuration={reportDuration}
                                                            onChartImageURI={onChartImageURI}/>
                                                    </Box>
                                                </Center>
                                            </>
                                        ) : (
                                            <VStack h={"80vh"} justify={'center'}>
                                                <Alert w={"40%"}>{t("No data found for this duration")}!</Alert>
                                            </VStack>
                                        )
                                    }
                                </>
                            ) : (
                                <VStack h={"80vh"} justify={'center'}>
                                    <Alert w={"40%"}>{t("Please select report duration")}!</Alert>
                                </VStack>
                            )
                        }
                    </>
                )
            }
        </>
    );
};
