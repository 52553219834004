// import  FocusLock from "react-focus-lock"

// 1. Create a text input component
import React from "react";
import {
    Badge,
    Box,
    Divider,
    FormControl,
    FormLabel, HStack,
    IconButton,
    Input, Popover, PopoverArrow, PopoverCloseButton, PopoverContent,
    PopoverTrigger,
    Stack, Text,
    useDisclosure, useToast
} from "@chakra-ui/react";
import {DeleteIcon, EditIcon, PlusSquareIcon} from "@chakra-ui/icons";
import {ILocalGlobalUser} from "./ManageUserGroups";
import {removeLocalUserToUserGroup} from "../services/UserGroupService";
import {UserGroupResponse} from "../entities/response/userGroup/UserGroupResponse";
import {removeGlobalUserFromGlobalUserGroup} from "../services/GlobalUserGroupService";
import {GlobalUserGroupResponse} from "../entities/response/globalUserGroup/GlobalUserGroupResponse";
import {GlobalUserResponse} from "../entities/response/user/GlobalUserResponse";
import {toastDuration} from "../constants/ApplicationConstants";
import {useTranslation} from "react-i18next";

const TextInput = React.forwardRef((props: { id: string, label: string}, ref) => {
    return (
        <FormControl>
            <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
            <Input {...props} />
        </FormControl>
    )
})

// 2. Create the form
const AuditorList = (props: {
    onUserAddModalOpen: () => void;
    isGlobalUserGroup: boolean | undefined,
    setRefresh: (value: boolean) => void,
    users: ILocalGlobalUser[] | undefined,
    globalUsers: GlobalUserResponse[] | undefined,
    userGroupSelected: UserGroupResponse | undefined,
    globalUserGroupSelected: GlobalUserGroupResponse | undefined,
    onCancel: () => void }) => {
    const {
        setRefresh,
        isGlobalUserGroup,
        onCancel,
        userGroupSelected,
        users,
        onUserAddModalOpen,
        globalUsers,
        globalUserGroupSelected
    } = props;

    const [userGroup, setUserGroup] = React.useState<UserGroupResponse | undefined>(userGroupSelected);
    const [globalUserGroup, setGlobalUserGroup] = React.useState<GlobalUserGroupResponse | undefined>(globalUserGroupSelected);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const toast = useToast();

    const { t } = useTranslation();

    React.useEffect(() => {
        setUserGroup(userGroupSelected)
    }, [userGroupSelected]);

    React.useEffect(() => {
        setGlobalUserGroup(globalUserGroupSelected)
    }, [globalUserGroupSelected]);

    const removeUserFromUserGroupHelper = (localUserId: string) => {
        const userGroupId = userGroup?._id;

        if (localUserId && userGroupId) {
            setIsLoading(true)
            removeLocalUserToUserGroup(localUserId, userGroupId)
                .then((userGroupResponse : UserGroupResponse) => {
                    toast({
                        title: `${t('Removed user from')} ${t(userGroupResponse.name)}`,
                        description: '',
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    setRefresh(true);
                    setUserGroup(userGroupResponse)
                    setIsLoading(false)
                }).catch(err => {
                    toast({
                        title: `${t('Failed to remove user from')} ${t(userGroup?.name)}`,
                        description: err?.message?.toString(),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    setIsLoading(false)
                })
        }
    }

    const removeGlobalUserFromGlobalUserGroupHelper = (globalUserId: string) => {
        const globalUserGroupId = globalUserGroup?._id;

        if (globalUserId && globalUserGroupId) {
            setIsLoading(true)
            removeGlobalUserFromGlobalUserGroup(globalUserId, globalUserGroupId)
                .then((globalUserGroupResponse : GlobalUserGroupResponse) => {
                    toast({
                        title: `${t('Removed user from')} ${t(globalUserGroupResponse.name)}`,
                        description: '',
                        status: 'success',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    setRefresh(true);
                    setGlobalUserGroup(globalUserGroupResponse)
                    setIsLoading(false)
                })
                .catch(err => {
                    toast({
                        title: `${t('Failed to remove user')}`,
                        description: t(err?.message?.toString()),
                        status: 'error',
                        duration: toastDuration,
                        isClosable: true,
                    });
                    setIsLoading(false)
                })
        }
    }

    return (
        <Stack mt={2}>
            <HStack justifyContent={'space-between'}>
                <Text>{t(userGroup?.name || '')}{t(globalUserGroup?.name || '')}</Text>
                { !isGlobalUserGroup ? <IconButton aria-label={''} onClick={onUserAddModalOpen} icon={<PlusSquareIcon />} /> : null }
            </HStack>
            <Divider />
            {
                userGroup?.localUserIds.map((id: string, idx) => {
                    let userName = '';
                    users?.forEach(user => {
                       if (id === user.localUser._id) {
                           userName = user.localUser.name;
                       }
                   });
                    return <HStack>
                        <Text>{userName}</Text>
                        <IconButton
                            aria-label='Search database'
                            onClick={() => removeUserFromUserGroupHelper(id)}
                            isLoading={isLoading}
                            icon={
                                <DeleteIcon color='red'/>
                            }/>

                    </HStack>
                })
            }
            {
                globalUserGroup?.globalUsers.map((id: string, idx) => {
                    let userName = '';
                    globalUsers?.forEach(user => {
                        if (id === user._id) {
                            userName = user.name;
                        }
                    });
                    return <HStack>
                        <Text>{userName}</Text>
                        <IconButton
                            aria-label='Search database'
                            isLoading={isLoading}
                            onClick={() => removeGlobalUserFromGlobalUserGroupHelper(id)}
                            icon={
                                <DeleteIcon color='red'/>
                            }/>
                    </HStack>
                })
            }
        </Stack>
    )
}

// 3. Create the Popover
// Ensure you set `closeOnBlur` prop to false so it doesn't close on outside click
export const PopoverForm = (props: {
    onUserAddModalOpen: () => void;
    setRefresh: (value: boolean) => void;
    users?: ILocalGlobalUser[] | undefined,
    isGlobalUserGroup?: boolean,
    globalUsers?: GlobalUserResponse[] | undefined,
    globalUserGroupSelected?: GlobalUserGroupResponse | undefined,
    name: string,
    userGroupSelected?: UserGroupResponse | undefined
}) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const { setRefresh, name, isGlobalUserGroup, userGroupSelected, users, onUserAddModalOpen, globalUsers, globalUserGroupSelected } = props;

    const { t } = useTranslation();

    return (
        <>
            <Box d='inline-block' mr={3}>
                <HStack>
                    <Text>{t(name)}</Text>
                    { isGlobalUserGroup ? <Badge colorScheme={'green'}>Global</Badge> : null }
                </HStack>
            </Box>
            <Popover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                placement='right'
                closeOnBlur={true}
            >
                <PopoverTrigger>
                    <IconButton aria-label='' size='sm' icon={<EditIcon />} />
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <AuditorList
                        onUserAddModalOpen={onUserAddModalOpen}
                        users={users}
                        setRefresh={setRefresh}
                        isGlobalUserGroup={isGlobalUserGroup}
                        globalUsers={globalUsers}
                        globalUserGroupSelected={globalUserGroupSelected}
                        userGroupSelected={userGroupSelected}
                        onCancel={onClose} />
                </PopoverContent>
            </Popover>
        </>
    )
}
