import {Select, useToast} from "@chakra-ui/react";
import './LangugageSelector.css';
import {useTranslation} from "react-i18next";
import {toastDuration} from "./constants/ApplicationConstants";
import {SelectedLanguage} from "./i18n/SelectedLanguage";



export const LanguageSelector = () => {
    const { t, i18n } = useTranslation();
    const toast = useToast();

    const handleLanguageSelectionChange = (event: any) => {
        i18n.changeLanguage(event.target.value).then(() => {
            toast({
                title: t('Language changed to'),
                description: event.target.value === 'pt' ? t('Portuguese') : t('English'),
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });
        });
    }

    return (
        <div className={'language-selector'}>
            <Select bg='#ffed52' borderWidth={2} borderColor='black' onChange={handleLanguageSelectionChange}>
                <option value='pt'>{'Português'}</option>
                <option value='en'>{'English'}</option>
            </Select>
        </div>
    )
}
