import {ICompany} from "../routes/CompaniesList";
import {GlobalUserCompanyMembershipResponse, GlobalUserResponse} from "../entities/response/user/GlobalUserResponse";
import {
    getAllPermissionsWithLocalUser
} from "../services/SecurityGroupService";
import {SecurityGroupFeatures} from "../enums/SecurityGroupFeatures";
import {IndicatorNumber} from "../enums/IndicatorNumber";

const refreshTokenKey = 'refreshToken'

export const setRefreshTokenInLocalStorage = (refreshToken : string) : void => {
    localStorage.setItem(refreshTokenKey, refreshToken)
}

export const getRefreshTokenFromLocalStorage = () : string | null => {
    const refreshToken = localStorage.getItem(refreshTokenKey);
    if(refreshToken === "undefined") {
        return null;
    }
    return refreshToken;
}

const accessTokenKey = 'accessToken'

export const setAccessTokenInLocalStorage = (accessToken : string) : void => {
    localStorage.setItem(accessTokenKey, accessToken)
}

export const getAccessTokenFromLocalStorage = () : string | null => {
    return localStorage.getItem(accessTokenKey);
}

const idTokenKey = 'idToken'

export const setIdTokenInLocalStorage = (idToken : string) : void => {
    localStorage.setItem(idTokenKey, idToken)
}

export const getIdTokenFromLocalStorage = () : string | null => {
    const idToken = localStorage.getItem(idTokenKey);
    if(idToken === "undefined") {
        return null;
    }
    return idToken;
}

export const removeIdTokenFromLocalStorage = () => {
    localStorage.removeItem(idTokenKey);
}

const manageGlobalUserGroupPermissionKey = 'MANAGE_GUG_PERMISSION'
const viewCrossMunicipalityPermissionKey = 'VIEW_CROSS_MUNICIPALITY_PERMISSION'
const manageGlobalUsersPermissionKey = 'MANAGE_GLOBAL_USERS_PERMISSION'

export const setGlobalUserPermissionsInLocalStorage = (
    manageGlobalUserGroupPermission : boolean,
    viewCrossMunicipalityPermission : boolean,
    manageGlobalUsersPermission : boolean,
) => {
    localStorage.setItem(manageGlobalUserGroupPermissionKey, manageGlobalUserGroupPermission ? "true" : "false");
    localStorage.setItem(viewCrossMunicipalityPermissionKey, viewCrossMunicipalityPermission ? "true" : "false");
    localStorage.setItem(manageGlobalUsersPermissionKey, manageGlobalUsersPermission ? "true" : "false");
}

export const checkIfGlobalUserHasManageGlobalUserGroupPermission = () : boolean => {
    return localStorage.getItem(manageGlobalUserGroupPermissionKey) === "true";
}

export const checkIfGlobalUserHasViewCrossMunicipalityPermission = () : boolean => {
    return localStorage.getItem(viewCrossMunicipalityPermissionKey) === "true";
}

export const checkIfGlobalUserHasManageGlobalUsersPermissionPermission = () : boolean => {
    return localStorage.getItem(manageGlobalUsersPermissionKey) === "true";
}

export const clearGlobalUserPermissionsInLocalStorage = () => {
    localStorage.removeItem(manageGlobalUserGroupPermissionKey)
    localStorage.removeItem(viewCrossMunicipalityPermissionKey)
    localStorage.removeItem(manageGlobalUsersPermissionKey)
}

const indicatorPermissionsKey = 'INDICATOR_PERMISSIONS'
const companyPermissionsKey = 'COMPANY_PERMISSIONS'

export const setUserPermissionsForCompanyInLocalStorage = async () => {
    const allPermissions : any = await getAllPermissionsWithLocalUser();

    localStorage.setItem(indicatorPermissionsKey, JSON.stringify(allPermissions[SecurityGroupFeatures.INDICATOR]));
    localStorage.setItem(companyPermissionsKey, JSON.stringify(allPermissions[SecurityGroupFeatures.COMPANY_LEVEL]));

}
export const getCompanyLevelPermissionsOfUserForCompanyInLocalStorage = () : string[] => {
    const companyPermissions = localStorage.getItem(companyPermissionsKey);
    if(!companyPermissions) {
        return [];
    }
    return JSON.parse(companyPermissions) as string[];

}

export const getIndicatorLevelPermissionsOfUserForCompanyInLocalStorage = () : Map<IndicatorNumber, string[]> => {
    const indicatorLevelPermissions = localStorage.getItem(indicatorPermissionsKey);
    if(!indicatorLevelPermissions) {
        return Object.values(IndicatorNumber).reduce(
            (prev, curr) => prev.set(curr, []),
            new Map<IndicatorNumber, string[]>()
        )
    }
    return new Map<IndicatorNumber, string[]>(
        // @ts-ignore
        Object.entries(JSON.parse(indicatorLevelPermissions))
    );
}

export const removeLocalUserPermissionsFromLocalStorage = () => {
    localStorage.removeItem(indicatorPermissionsKey);
    localStorage.removeItem(companyPermissionsKey);
}

const companySelectedKey = 'companySelected';

export const setCompanySelectedInLocalStorage = (companySelected : ICompany) : void => {
    localStorage.setItem(companySelectedKey, JSON.stringify(companySelected))
}

export const getCompanySelectedFromLocalStorage = () : ICompany | null => {
    const companySelected = localStorage.getItem(companySelectedKey);
    return companySelected ? JSON.parse(companySelected) : null;
}

export const removeCompanySelectedFromLocalStorage = () => {
    return localStorage.removeItem(companySelectedKey);
}

const companyIdSelectedKey = 'companyIdSelected';

export const setCompanyIdSelectedInLocalStorage = (companyIdSelected : string) : void => {
    localStorage.setItem(companyIdSelectedKey, companyIdSelected)
}

export const getCompanyIdSelectedFromLocalStorage = () : string | null => {
    return localStorage.getItem(companyIdSelectedKey) || null;
}

export const removeCompanyIdSelectedFromLocalStorage = () => {
    return localStorage.removeItem(companyIdSelectedKey);
}

const localUserIdSelectedKey = 'localUserIdSelected';

export const setLocalUserIdIdSelectedInLocalStorage = (companyIdSelected : string) : void => {
    localStorage.setItem(localUserIdSelectedKey, companyIdSelected)
}

export const getLocalUserIdIdSelectedFromLocalStorage = () : string | null => {
    return localStorage.getItem(localUserIdSelectedKey) || null;
}

export const removeLocalUserIdIdSelectedFromLocalStorage = () => {
    return localStorage.removeItem(localUserIdSelectedKey);
}

const globalUserKey = 'globalUser';

export const setGlobalUserInLocalStorage = (globalUserResponse : GlobalUserResponse) : void => {
    localStorage.setItem(globalUserKey, JSON.stringify(globalUserResponse))
}

export const getGlobalUserFromLocalStorage = () : GlobalUserResponse | null => {
    const globalUserResponse = localStorage.getItem(globalUserKey);
    return globalUserResponse ? JSON.parse(globalUserResponse) : null;
}

export const removeGlobalUserFromLocalStorage = () => {
    return localStorage.removeItem(globalUserKey);
}

export const getUserCompanyMembershipsFromLocalStorage = () : GlobalUserCompanyMembershipResponse[] => {
    return getGlobalUserFromLocalStorage()?.companyMemberships || [];
}

export const getUserEmailAddressFromLocalStorage = () : string | null => {
    return getGlobalUserFromLocalStorage()?.email || null;
}

export const setInLocalStorage = (key: string, value: object) : void => {
    localStorage.setItem(key, JSON.stringify(value))
}

export const getFromLocalStorage = (key: string) : any => {
    const val = localStorage.getItem(key);
    return val ? JSON.parse(val) : val;
}
