import { FieldSearch } from './FieldSearch';
import { SortQuery } from './SortQuery';
import {EntitySearchOperands} from '../../enums/EntitySearchOperands';

export class EntitySearch {
  searchFilters: FieldSearch[] | EntitySearch[];

  operator: EntitySearchOperands = EntitySearchOperands.AND;

  sortQuery?: SortQuery[];

  constructor(
    searchFilters: FieldSearch[] | EntitySearch[],
    operator?: EntitySearchOperands,
    sortQuery?: SortQuery[]
  ) {
    this.searchFilters = searchFilters;
    if (operator) {
      this.operator = operator;
    }
    if (sortQuery) {
      this.sortQuery = sortQuery;
    }
  }
}
