import {
    Box,
    Button, Checkbox, Container, Flex,
    FormControl, FormLabel,
    Heading,
    HStack, Input,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import * as React from "react";
import {useTranslation} from "react-i18next";
import useDigitInput from "react-digit-input";
import {DigitInputElement} from "./DigitInputElement";
import {
    getOTPForForgotPassword,
    resetPasswordForUser,
} from "../services/GlobalUserService";
import {useNavigate} from "react-router-dom";
import {toastDuration} from "../constants/ApplicationConstants";
import {navigateToLoginPage} from "../helpers/NavigationHelper";

export const ForgotPassword = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const toast = useToast();
    const [emailAddress, setEmailAddress] = React.useState<string>("");
    const [otpSent, setOTPSentState] = React.useState<boolean>(false);
    const [value, onChange] = React.useState('');
    const [newPassword, setNewPassword] = React.useState<string>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const digits = useDigitInput({
        acceptedCharacters: /^[0-9]$/,
        length: 6,
        value,
        onChange
    });

    const sendOTP = () => {
        setLoading(true);
        getOTPForForgotPassword(emailAddress).then(() => {
            setOTPSentState(true);
            setLoading(false);
            toast({
                title: t("code sent to email"),
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            })
        });
    }

    const resetPassword = () => {
        setLoading(true);
        resetPasswordForUser(emailAddress, newPassword ?? "", value).then(() => {
            setLoading(false);
            toast({
                title: t("Password has been set!!"),
                status: 'success',
                duration: toastDuration,
                isClosable: true,
            });
            navigateToLoginPage(navigate)
        }).catch((e) => {
            toast({
                title: e.toString(),
                status: 'error',
                duration: toastDuration,
                isClosable: true,
            });
        })
    }

    return (
        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
            <Stack spacing="8">
                <Stack spacing="6">
                    <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                        <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                            {t("Forgot Password")}
                        </Heading>
                    </Stack>
                </Stack>
                <Box
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg="white"
                    boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                >
                    <Stack spacing="6">
                        <Stack spacing="5">
                            { otpSent ?
                                null :
                                <FormControl>
                                    <FormLabel htmlFor="name">{t("Email address:")}</FormLabel>
                                    <Input id="name" type="name" onChange={(e) => setEmailAddress(e.target.value)}/>
                                </FormControl>
                            }
                        </Stack>
                        <Stack spacing="5">
                            { otpSent ?
                                <>
                                    <FormControl>
                                    <FormLabel htmlFor="email">{t("Enter code:")}</FormLabel>
                                    <Flex justifyContent={"center"} id="signUpPasscode" className="input-group">
                                        <DigitInputElement autoFocus {...digits[0]} />
                                        <DigitInputElement {...digits[1]} />
                                        <DigitInputElement {...digits[2]} />
                                        <DigitInputElement {...digits[3]} />
                                        <DigitInputElement {...digits[4]} />
                                        <DigitInputElement {...digits[5]} />
                                    </Flex>
                                </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor="email">{t("Enter new password:")}</FormLabel>
                                        <Input type={"password"} onChange={(e) => setNewPassword(e.target.value)} />
                                    </FormControl>
                                </>
                                : null
                            }
                        </Stack>
                        <Stack spacing="6">
                            { otpSent ? <Button isLoading={loading} colorScheme="blue" onClick={resetPassword}>{t("Submit")}</Button> : <Button isLoading={loading} colorScheme="blue" onClick={sendOTP}>{t("GET OTP")}</Button> }
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    );
}
