import {IndicatorDataEntryResponse} from "../../../../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import moment from "moment";
import {IndicatorResponse} from "../../../../entities/response/indicator/IndicatorResponse";
import {Flex, Heading} from "@chakra-ui/react";
import * as React from "react";
import {BarChart, BarChartDataType} from "../../GenericCharts/BarChart";
import {useTranslation} from "react-i18next";
import {SelectedLanguage} from "../../../../i18n/SelectedLanguage";
import {computeFinalScore} from "../../utilities/ProcessData";
import {getDisplayNameOfIndicatorNumber, getLastPhaseForIndicatorEntry} from "../../../../helpers/ApplicationHelper";
import {IndicatorDataType} from "../../../../enums/IndicatorDataType";
import {getPerformanceColour} from "../../ReportsHelper";

export const QuarterAchievementColumnChartForAllIndicators = (
    props: {
        chartName : string,
        indicatorDataEntries : Map<IndicatorResponse, IndicatorDataEntryResponse | null>,
        reportDuration : moment.Moment,
        onChartImageURI: any,
    }
): JSX.Element => {

    const {
        chartName,
        indicatorDataEntries,
        reportDuration,
        onChartImageURI
    } = props;

    const {t, i18n} = useTranslation();

    const selectedLanguage = i18n.language as SelectedLanguage;

    const barChartDataType : BarChartDataType[] = Array.from(indicatorDataEntries.entries()).map(([indicatorResponse, indicatorDataEntry]) => {

        const lastPhaseData = indicatorDataEntry ? getLastPhaseForIndicatorEntry(indicatorDataEntry) : null;

        return {
            value : lastPhaseData ? computeFinalScore(lastPhaseData, indicatorResponse, IndicatorDataType.AURA_REPORTING).score : 0,
            target : lastPhaseData ? parseInt(lastPhaseData['target'].value || '100') : 0,
            stars : lastPhaseData ? parseInt(lastPhaseData['reliability'].value || '0') : 0,
            legendName: indicatorResponse.name[selectedLanguage],
            colouringFunction: (performanceScore : number) => getPerformanceColour(performanceScore, indicatorResponse.indicatorNumber),
            barName : getDisplayNameOfIndicatorNumber(indicatorResponse.indicatorNumber),
        };
    })

    return (
        <>
            <Flex flexDirection={"column"}>

                <BarChart
                    getChartImageURI={onChartImageURI}
                    chartId={'quarterAchievementColumnChartForAllIndicators'}
                    chartName={chartName}
                    yaxis={t('Points / Percentage')}
                    data={barChartDataType} />
            </Flex>
        </>
    )
}
