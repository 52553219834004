import React, { useState } from "react";
import "./ExamBasedFormEntry.css";
import {
    ChakraProvider,
    Container,
    Heading,
    Button,
    VStack,
    Text,
    Flex,
    Divider,
    ButtonGroup,
    Alert
} from "@chakra-ui/react";

import ChakraCarousel from "./ChakraCarousel";
import {FormCell} from "./accordionTable/AccordionTable";
import {useTranslation} from "react-i18next";
import Rating from "./Reliability";
import {ColumnNameTooltip} from "../utilities/getColumnNameTooltip";
import {displayValue} from "./accordionTable/DisplayValueHelper";
import {PhaseNames} from "./accordionTable/PhaseNames";

export function ExamBasedFormEntry(props) {
    const {referenceColumns, phaseKeyToSave, viewPhases, indicatorDataType, setPhaseRecord, currentPhase, company, indicator, phaseRecord, columns} = props;
    const {t,i18n} = useTranslation();
    const [activeItem, setActiveItem] = useState(0);
    const phaseSequence = indicator.indicatorDataTypeStructureList[indicatorDataType]?.phaseSequence;
    return (
        <ChakraProvider>
            <ButtonGroup justifyContent={"center"} flexWrap={'wrap'}>
                {columns?.map((column, idx) => {
                    return (
                        <Button
                            m={1}
                            fontSize={10}
                            onClick={() => setActiveItem(idx)}
                            size={'xs'}
                            key={'page-idx' + column.name}
                            colorScheme='teal' variant={activeItem === idx ? "solid" : 'outline'}>
                            {column.displayName[i18n.language ?? 'en']}
                        </Button>
                    );
                })}
            </ButtonGroup>
            <Container
                py={1}
                px={0}
                maxW={{
                    base: "100%",
                    sm: "35rem",
                    md: "43.75rem",
                    lg: "57.5rem",
                    xl: "75rem",
                    xxl: "87.5rem"
                }}
            >
                <ChakraCarousel gap={32} activeItem={activeItem} setActiveItem={(val) => setActiveItem(val)}>
                    { currentPhase? Object.keys(currentPhase)?.map((columnKey, index) => {
                        let found = false;
                        referenceColumns.forEach(referenceColumn => {
                            if (referenceColumn.name === columnKey) {
                                found = true;
                            }
                        });

                        const columnValue = currentPhase[columnKey];
                        const column = columns.find(c => c.name === columnKey);
                        return (
                            <Flex
                                key={index}
                                justifyContent="space-between"
                                flexDirection="column"
                                overflow="hidden"
                                rounded={5}
                                flex={1}
                                p={5}
                            >
                                <VStack mb={6}>
                                    <Heading
                                        fontSize={{ base: "xl", md: "2xl" }}
                                        textAlign="center"
                                        w="full"
                                        color={"black"}
                                        mb={2}
                                    >
                                        <ColumnNameTooltip columnName={column?.displayName} columnDescription={column?.description} />
                                    </Heading>
                                    <Alert>
                                        {column?.description ? column?.description[i18n.language ?? 'en'] : ""}
                                    </Alert>
                                    <Alert>
                                        {column?.additionalInformation ? column?.additionalInformation[i18n.language ?? 'en'] : ""}
                                    </Alert>
                                    <Divider />
                                    <table width={"100%"}>
                                        <tbody>
                                            {viewPhases?.map((phase, idx) => {
                                                const phaseDescription = PhaseNames[('phase'+(idx+1)).toUpperCase()][i18n.language];
                                                return (
                                                    <tr className={'previous-stage-val'}>
                                                        <td>{'Phase'} {idx+1} - {phaseDescription}</td>
                                                        {
                                                            Object.keys(phase).filter(key => columnKey === key).map(key => {
                                                                if (key === 'reliability') {
                                                                    return <td>
                                                                        <Rating
                                                                            initialValue={phase[key].value}
                                                                            viewOnly={true}
                                                                            size={28}
                                                                            icon="star"
                                                                            scale={3}
                                                                            fillColor="gold"
                                                                            strokeColor="grey"
                                                                        />
                                                                    </td>;
                                                                }
                                                                return <td>{displayValue(phase[key], idx, indicatorDataType, t).map(value => <span>{t(value)} <br/></span>)}</td>
                                                            })
                                                        }
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    {
                                        found ?
                                            <td><Text color={'red'}>{t("This field is being referenced.")}</Text></td>
                                            : <FormCell
                                                indicatorDataType={indicatorDataType}
                                                columns={columns}
                                                company={company}
                                                indicator={indicator}
                                                phaseRecord={phaseRecord}
                                                columnValue={columnValue}
                                                columnKey={columnKey}
                                                phaseKeyToSave={phaseKeyToSave}
                                                setPhaseRecord={setPhaseRecord}
                                            />
                                    }
                                </VStack>
                            </Flex>
                        )
                    }) : columns?.map((columnKey, index) => {
                        let found = false;
                        referenceColumns.forEach(referenceColumn => {
                            if (referenceColumn.name === columnKey.name) {
                                found = true;
                            }
                        });

                        return (
                            <Flex
                                key={index}
                                justifyContent="space-between"
                                flexDirection="column"
                                overflow="hidden"
                                rounded={5}
                                flex={1}
                                p={5}
                            >
                                <VStack mb={6}>
                                    <Heading
                                        fontSize={{ base: "xl", md: "2xl" }}
                                        textAlign="center"
                                        w="full"
                                        color={"black"}
                                        mb={2}
                                    >
                                        <ColumnNameTooltip columnName={columnKey?.displayName} columnDescription={columnKey?.description} />
                                    </Heading>
                                    <Alert>
                                        {columnKey?.description ? columnKey?.description[i18n.language ?? 'en'] : ""}
                                    </Alert>
                                    <Alert colorScheme={'red'}>
                                        {columnKey?.additionalInformation ? columnKey?.additionalInformation[i18n.language ?? 'en'] : ""}
                                    </Alert>
                                    <Divider />
                                    <table width={"100%"}>
                                        <tbody>
                                        {viewPhases?.map((phase, idx) => {
                                            const phaseDescription = PhaseNames[('phase'+(idx+1)).toUpperCase()][i18n.language];
                                            return (
                                                <tr className={'previous-stage-val'}>
                                                    <td>{'Phase'} {idx+1} - {phaseDescription}</td>
                                                    {
                                                        Object.keys(phase).filter(key => columnKey?.name === key).map(key => {
                                                            if (key === 'reliability') {
                                                                return <td>
                                                                    <Rating
                                                                        initialValue={phase[key].value}
                                                                        viewOnly={true}
                                                                        size={28}
                                                                        icon="star"
                                                                        scale={3}
                                                                        fillColor="gold"
                                                                        strokeColor="grey"
                                                                    />
                                                                </td>;
                                                            }
                                                            return <td>{displayValue(phase[key], idx, indicatorDataType, t).map(value => <span>{t(value)} <br/></span>)}</td>
                                                        })
                                                    }
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                    {
                                        found
                                            ? <td><Text color={'red'}>{t("This field is being referenced.")}</Text></td>
                                            : <Flex width={'100%'}>
                                                <FormCell
                                                    indicatorDataType={indicatorDataType}
                                                    columns={columns}
                                                    company={company}
                                                    indicator={indicator}
                                                    phaseRecord={phaseRecord}
                                                    columnValue={undefined}
                                                    columnKey={columnKey.name}
                                                    phaseKeyToSave={phaseKeyToSave}
                                                    setPhaseRecord={setPhaseRecord}/>
                                            </Flex>
                                    }
                                </VStack>
                            </Flex>
                        )
                    })}
                </ChakraCarousel>
            </Container>
        </ChakraProvider>
    );
}

