import React from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import {Box} from "@chakra-ui/react";
import {IndicatorList} from "../components/IndicatorList";
import {IndicatorDetails} from "../components/IndicatorDetails";
import {SidebarWithHeader} from "../components/SidebarWithHeader";

export const Indicator = () => {
    return (
        <SidebarWithHeader isNonCompanySpecificPage={false}>
            <Box>
                <IndicatorList />
            </Box>
        </SidebarWithHeader>
    );
}

export const IndicatorDetailsOuter = () => {
    return (
        <SidebarWithHeader isNonCompanySpecificPage={false}>
            <Box>
                <IndicatorDetails />
            </Box>
        </SidebarWithHeader>
    );
}
