import {
    Alert,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Flex,
    Heading,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack
} from "@chakra-ui/react";
import {Container} from "react-bootstrap";
import React from "react";
import {AccordionTable} from "./accordionTable/AccordionTable";
import {ViewIndicatorSpecificReports} from "./ViewIndicatorSpecificReports";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ChevronDownIcon, ChevronRightIcon} from "@chakra-ui/icons";
import {checkUserLoggedIn} from "../utilities/AuthUtil";
import {getIndicatorById} from "../services/IndicatorService";
import {IndicatorResponse} from "../entities/response/indicator/IndicatorResponse";
import {getAllIndicatorDataEntriesForIndicator} from "../services/IndicatorDataEntryService";
import {IndicatorDataEntryResponse} from "../entities/response/indicatorDataEntry/IndicatorDataEntryResponse";
import {IndicatorDataType} from "../enums/IndicatorDataType";
import {SelectedLanguage} from "../i18n/SelectedLanguage";
import {checkCompanyStorageVariablesElseNavigate, navigateToLoginPage} from "../helpers/NavigationHelper";
import {checkIfUserHasIndicatorLevelPermissions} from "../helpers/PermissionsHelper";
import {IndicatorLevelActions} from "../enums/featureActions/IndicatorLevelActions";

export const IndicatorDetails = () => {
    const [indicator, setIndicator] = React.useState<IndicatorResponse>();
    const [indicatorEntries, setIndicatorEntries] = React.useState<IndicatorDataEntryResponse[]>();
    const [refreshEntries, setRefreshEntries] = React.useState<boolean>(true);

    const [isViewReports, setViewReports] = React.useState<boolean>(false);

    const [indicatorDataTypeSelected, setIndicatorDataTypeSelected] = React.useState<IndicatorDataType>(IndicatorDataType.AURA_REPORTING);

    const { t, i18n } = useTranslation();
    const selectedLanguage = i18n.language as SelectedLanguage;

    const navigate = useNavigate();

    React.useEffect(() => checkCompanyStorageVariablesElseNavigate(navigate), []);

    const [indicatorId, setIndicatorId] = React.useState<string>();

    React.useEffect(() => {
        if (
            refreshEntries && indicator
            && (
                checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_AURA_REPORTING)
                || checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_AURA_COMPLEMENTARY)
                || checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_DNAAS_USP)
            )
        ) {
            getAllIndicatorDataEntriesForIndicator(indicator.indicatorNumber)
                .then((indicatorDataEntriesResponse : IndicatorDataEntryResponse[]) => {
                    setIndicatorEntries(indicatorDataEntriesResponse);
                    setRefreshEntries(false);
                })
        }
    }, [refreshEntries, indicator]);

    React.useEffect(() => {
        const url = new URL(window.location.href);
        if (!checkUserLoggedIn()) {
            navigateToLoginPage(navigate)
        }

        const id = url.searchParams.get('id');
        setIndicatorId(id ?? '');

        if (id) {
            getIndicatorById(id)
                .then((indicatorResponse : IndicatorResponse) => {
                    setIndicator(indicatorResponse);
                }).catch(err => {
                    navigate('/indicators');
                })
        } else {
            navigate('/indicators');
        }
    }, []);

    const viewReports = () => {
        setViewReports(true);
    };

    const handleTabsChange = (index: number) => {
        switch(index) {
            case 0: setIndicatorDataTypeSelected(IndicatorDataType.AURA_REPORTING); return;
            case 1: setIndicatorDataTypeSelected(IndicatorDataType.AURA_COMPLEMENTARY); return;
            case 2: setIndicatorDataTypeSelected(IndicatorDataType.DNAAS_USP); return;
        }
    }

    if(!indicator) {
        return (
            <VStack h={"80vh"} justify={'center'}>
                <Spinner size='xl' />
                <Text>{t("Loading data")}...!</Text>
            </VStack>
        );
    }

    if(
        !checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_AURA_REPORTING)
        && !checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_AURA_COMPLEMENTARY)
        && !checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_DNAAS_USP)
        && !checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_REPORTS_AURA_REPORTING)
        && !checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_REPORTS_AURA_COMPLEMENTARY)
        && !checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_REPORTS_DNAAS_USP)
    ) {
        return (
            <VStack h={"80vh"} justify={'center'}>
                <Alert w={"40%"}>{t("Insufficient permissions")}!</Alert>
            </VStack>
        );
    }

    const checkIfIndicatorReportPermissionsPresent = (indicatorDataType : IndicatorDataType) : boolean => {
        switch (indicatorDataType) {
            case IndicatorDataType.AURA_COMPLEMENTARY:
                return checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_REPORTS_AURA_COMPLEMENTARY)
            case IndicatorDataType.AURA_REPORTING:
                return checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_REPORTS_AURA_REPORTING)
            case IndicatorDataType.DNAAS_USP:
                return checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_REPORTS_DNAAS_USP)
        }
        return false;
    }

    return(
        <Container>

            <Breadcrumb display={'inline-block'} borderRadius={5} p={2} fontWeight='medium' fontSize='sm' separator={<ChevronRightIcon color='gray.500' />}>
                <BreadcrumbItem>
                    <BreadcrumbLink href='/indicators'>{t('Indicators')}</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage={!isViewReports}>
                    <BreadcrumbLink href={`/indicators/details/?id=${indicatorId}`}>{t('Details')}</BreadcrumbLink>
                </BreadcrumbItem>
                {
                    isViewReports ? <BreadcrumbItem isCurrentPage={isViewReports}>
                        <BreadcrumbLink href='#'>{t('View Reporting Data Report')}</BreadcrumbLink>
                    </BreadcrumbItem> : null
                }
            </Breadcrumb>

            {
                isViewReports
                    ? <ViewIndicatorSpecificReports indicator={indicator} indicatorDataType={indicatorDataTypeSelected as IndicatorDataType}/>
                    : (
                        <>
                            <Flex wrap={'wrap'} m={5} justifyContent={"space-between"}>
                                <HStack>
                                    <Heading as='h5' size='l'>{indicator?.name[selectedLanguage]}</Heading>
                                </HStack>

                                <Menu colorScheme={'blue'}>
                                    <MenuButton as={Button} colorScheme='blue' rightIcon={<ChevronDownIcon />}>
                                        {t("Actions")}
                                    </MenuButton>
                                    <MenuList>
                                        {
                                            checkIfIndicatorReportPermissionsPresent(indicatorDataTypeSelected)
                                                ? <MenuItem onClick={viewReports}>{t("View Reporting Data Report")}</MenuItem>
                                                : null
                                        }
                                    </MenuList>
                                </Menu>

                            </Flex>
                            {
                                indicatorEntries
                                    ? <Tabs onChange={handleTabsChange} variant='soft-rounded' colorScheme='blue' mt={10}>
                                        <TabList flexWrap={'wrap'}>
                                            {
                                                checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_AURA_REPORTING)
                                                && indicator.indicatorDataTypeStructureList[IndicatorDataType.AURA_REPORTING]
                                                    ? <Tab>{t('AURA Reporting')}</Tab>
                                                    : null
                                            }
                                            {
                                                checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_AURA_COMPLEMENTARY)
                                                && indicator.indicatorDataTypeStructureList[IndicatorDataType.AURA_COMPLEMENTARY]
                                                    ? <Tab>{t('AURA Complementary')}</Tab>
                                                    : null
                                            }
                                            {
                                                checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_DNAAS_USP)
                                                && indicator.indicatorDataTypeStructureList[IndicatorDataType.DNAAS_USP]
                                                    ? <Tab>{t('DNAAS USP')}</Tab>
                                                    : null
                                            }
                                        </TabList>

                                        <TabPanels>
                                            {
                                                checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_AURA_REPORTING)
                                                && indicator.indicatorDataTypeStructureList[IndicatorDataType.AURA_REPORTING]
                                                    ? <TabPanel>
                                                        <AccordionTable
                                                            allEntries={indicatorEntries}
                                                            indicator={indicator}
                                                            setIndicator={setIndicator}
                                                            setRefreshEntries={setRefreshEntries}
                                                            indicatorDataType={IndicatorDataType.AURA_REPORTING}
                                                            entries={indicatorEntries.filter(indicator => indicator.indicatorDataType === IndicatorDataType.AURA_REPORTING)}
                                                            currentPhaseIndex={indicator?.indicatorDataTypeStructureList[IndicatorDataType.AURA_REPORTING]?.currentPhaseIndex}
                                                            columns={indicator?.indicatorDataTypeStructureList[IndicatorDataType.AURA_REPORTING]?.indicatorDataTypeEntryColumns}
                                                        /></TabPanel>
                                                    : null
                                            }
                                            {
                                                checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_AURA_COMPLEMENTARY)
                                                && indicator.indicatorDataTypeStructureList[IndicatorDataType.AURA_COMPLEMENTARY]
                                                    ? <TabPanel>
                                                        <AccordionTable
                                                            allEntries={indicatorEntries}
                                                            indicator={indicator}
                                                            setIndicator={setIndicator}
                                                            setRefreshEntries={setRefreshEntries}
                                                            indicatorDataType={IndicatorDataType.AURA_COMPLEMENTARY}
                                                            entries={indicatorEntries.filter(indicator => indicator.indicatorDataType === IndicatorDataType.AURA_COMPLEMENTARY)}
                                                            currentPhaseIndex={indicator?.indicatorDataTypeStructureList[IndicatorDataType.AURA_COMPLEMENTARY]?.currentPhaseIndex}
                                                            columns={indicator?.indicatorDataTypeStructureList[IndicatorDataType.AURA_COMPLEMENTARY]?.indicatorDataTypeEntryColumns}
                                                        /></TabPanel>
                                                    : null
                                            }
                                            {
                                                checkIfUserHasIndicatorLevelPermissions(indicator.indicatorNumber, IndicatorLevelActions.VIEW_DATA_DNAAS_USP)
                                                && indicator.indicatorDataTypeStructureList[IndicatorDataType.DNAAS_USP]
                                                    ? <TabPanel>
                                                        <AccordionTable
                                                            allEntries={indicatorEntries}
                                                            indicator={indicator}
                                                            setIndicator={setIndicator}
                                                            setRefreshEntries={setRefreshEntries}
                                                            indicatorDataType={IndicatorDataType.DNAAS_USP}
                                                            entries={indicatorEntries.filter(indicator => indicator.indicatorDataType === IndicatorDataType.DNAAS_USP)}
                                                            currentPhaseIndex={indicator?.indicatorDataTypeStructureList[IndicatorDataType.DNAAS_USP]?.currentPhaseIndex}
                                                            columns={indicator?.indicatorDataTypeStructureList[IndicatorDataType.DNAAS_USP]?.indicatorDataTypeEntryColumns}
                                                        /></TabPanel>
                                                    : null
                                            }
                                        </TabPanels>
                                    </Tabs>
                                    : <VStack h={"80vh"} justify={'center'}>
                                        <Spinner size='xl' />
                                        <Text>{t("Loading indicator entries, please wait")}</Text>
                                    </VStack>
                            }
                        </>
                    )
            }
        </Container>
    );
};
