import {SearchTypes} from '../../enums/SearchTypes';

export class FieldSearch {
  type: SearchTypes;

  field: string;

  value: any;

  constructor(type: SearchTypes, field: string, value: any) {
    this.type = type;
    this.field = field;
    this.value = value;
  }
}
